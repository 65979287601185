
:root {
    --theme-default: #6432b8;
    --theme-default2: #9516d7;
    --theme-gradient1: linear-gradient(to right, var(--theme-default) 0%, var(--theme-default2) 51%, var(--theme-default) 100%);
    --theme-gradient2: linear-gradient(to right, var(--theme-default) 0%, var(--theme-default2) 100%);
    --theme-gradient3: linear-gradient(48deg, var(--theme-default) 0%, var(--theme-default2) 100%);
    --theme-gradient4: linear-gradient(to right, var(--theme-default) 25%, var(--theme-default2) 100%);
  
    --theme-default3: #ff5c41;
    --theme-default4: #ff8c41;
    --theme-gradient5: linear-gradient(to right, var(--theme-default3) 0%, var(--theme-default4) 51%, var(--theme-default3) 100%);
    --theme-gradient6: linear-gradient(to right, var(--theme-default3) 0%, var(--theme-default4) 100%);
    --theme-gradient7: linear-gradient(90deg, var(--theme-default3) 25%, var(--theme-default4) 100%);
  
    --theme-default5: #5eac12;
    
    --theme-default6: #f35d43;
    --theme-default7: #f34451;
    --theme-gradient8: linear-gradient(to right, var(--theme-default6) 0%, var(--theme-default7) 51%, var(--theme-default6) 100%);
    --theme-gradient9: linear-gradient(90deg, var(--theme-default6) 0%, var(--theme-default7) 100%);
  
    --theme-default8: #2c2e97;
    --theme-default9: #4b55c4;
    --theme-gradient10: linear-gradient(to right, var(--theme-default8) 0%, var(--theme-default9) 51%, var(--theme-default8) 100%);
    --theme-gradient11: linear-gradient(to right, var(--theme-default8) 0%, var(--theme-default9) 100%);
    --theme-gradient12: linear-gradient(48deg, var(--theme-default8) 0%, var(--theme-default9) 100%);
    --theme-gradient13: linear-gradient(to right, var(--theme-default8) 25%, var(--theme-default9) 100%);
  
    --theme-default10: #00968a;
}

// fonts
$font_0: Montserrat;
$font_1: Roboto;
$font_2: Rubik;
$font_3: sans-serif;
$font-awesome: "Font Awesome 5 free";

//colors
$theme-font-color: #1c2d3a;
$theme-main-font-color: rgba(59, 66, 73, 0.7);
$theme-sub-font-color:#3b4249;
$black: #000000;
$white: #ffffff;
$light-bg: #f8f8f8;
$light-color: #959595;
$light-border: #eee;
$light-gray: #efefef;
$light-blue: #f7f7fe;
$blue-font: #647589;
$gray-30: #d2d2d2;
$gray-60: #878787;
$dark-color: #202020;
$dark-20: #141616;
$darken-color: #151515;
$common-box-shadow: 3.346px 3.716px 22.5px rgba($black, 0.07);

// layouts main color
$theme-color1: #6432b8;
$theme-color2: #9516d7;
$theme-color3: #ff5c41;
$theme-color4: #ff8c41;
$theme-color5: #5eac12;
$theme-color6: #f35d43;
$theme-color7: #f34451;
$theme-color8: #2c2e97;
$theme-color9: #4b55c4;
$theme-color10: #00968a;


$success-color: #89c826;
$danger-color: #f13439;
$label-red: #fb0118;
$title-color: #586167;
$brown-color: #232323;
$sub-footer-dark: #1e1e1e;
$footer-dark: #1b2c39;
$warning-color: #ffcc33;

// social icon color
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb6;
$google: #dd4b39;

// payment card color
$master: #1f4771;
$visa: #6c6f69;
$american: #73a3b9;


// dark layout color
$dark-bg: darken($brown-color, 3%);
$dark-bg2: $brown-color;
$dark-bg3: #282727;
$dark-border-color: #383434;
