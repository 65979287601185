/**=====================
     Responsive css start
==========================**/
@media (max-width: 1883px) {
  // property slider
  .property-grid-slider {
    .property-grid {
      &.list-view {
        .bg-size {
          height: 45vh;
        }
      }
    }
  }
}
@media (max-width: 1800px) {
  // home section css
  .layout-home2 {
    .arrow-light {
      .slick-prev {
        left: 100px;
      }
      .slick-next {
        right: 100px;
      }
    }
  }
}
@media (max-width: 1740px) {
  // home section css
  .layout-home2 {
    .arrow-light {
      .slick-prev {
        left: 60px;
      }
      .slick-next {
        right: 60px;
      }
    }
  }
  .layout-9 {
    .layout-right-img {
      img {
        height: 750px;
      }
    }
  }
}
@media (max-width: 1720px) {
  // testimonial css
  .arrow-white {
    .slick-prev {
      left: -100px;
    }
    .slick-next {
      right: -100px;
    }
  }
}
@media (max-width: 1660px) {
  .layout-home4{
    .slider-image{
      .home-content{
        .square-bg{
          width: 88%;
        }
      }
    }
  }
  .blog-1 {
    .blog-box {
      .blog-content {
        padding: 25px 40px;
      }
    }
  }
  .layout-home2 {
    .feature-section {
      .feature-content {
        margin-left: 100px;
      }
    }
  }
  .layout-home4 .home-slider-4 .slick-list .slick-current .slider-image .home-content > h1 {
    padding-left: 5px!important;
  }
  .feature-section .feature-4 .owl-carousel {
    .owl-prev {
      right: 5%;
      left: unset !important;
      top: -65px !important;
    }
    .owl-next {
      left: unset !important;
      right: 0;
      top: -65px !important;
    }
  }
  .about-section .about-1 .owl-carousel .owl-next {
    right: 45%;
    top: unset;
    bottom: -89px;
  }
  .about-section .about-1 .owl-carousel .owl-prev {
    left: 45%;
    top: unset;
    bottom: -89px;
  }
  .testimonial-bg .testimonial-2 .owl-carousel .owl-prev {
    left: 1px;
  }
  .testimonial-bg .testimonial-2 .owl-carousel .owl-next {
    right: 1px;
  }
  .feature-section .feature-1 .owl-carousel .owl-prev {
    left: 1px;
  }
  .feature-section .feature-1 .owl-carousel .owl-next {
    right: 1px;
  } 
  // testimonial css
  .arrow-white {  
    margin-bottom: 85px;
    .slick-arrow {
      top: unset;
      bottom: -110px;
    }
    .slick-prev {
      left: 46%;
    }
    .slick-next {
      right: 46%;
    }
  }
  .arrow-light {
    .slick-prev {
      left: 1px;
    }
    .slick-next {
      right: 1px;
    }
  }
  .testimonial-3 {
    .slick-list {
      margin: -53px -12px;
      .slick-track {
        .slick-slide {
          > div {
            margin: 53px 12px;
          }
        }
      }
    }
  }
  .our-client {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  }
  // about us css
  .user-about {
    .about-image {
      margin-left: 15px;
      margin-top: 70px;
      .img-box {
        img {
          max-width: 350px;
        }
      }
    }
  }
  // single property css
  .single-property-images {
    height: auto;
  }
  // service css
  .service-slider {
    &.arrow-gradient {
      &.arrow-right {
        .slick-prev {
          right: 10%;
        }
        .slick-next {
          right: 6.5%;
        }
      }
    }
  }
  // blog css
  .blog-2 {
    .slick-list {
      margin: 0 -15px;
    }
  }
  // property css
  .slick-shadow {
    .arrow-white {
      .slick-arrow {
        bottom: -80px;
      }
    }
  }
  // feature css
  .feature-section {
    .feature-3 {
      .slick-list {
        margin: -50px 0;
        .slick-track {
          .slick-slide {
            > div {
              margin: 50px;
            }
          }
        }
      }
      .slick-prev,
      .slick-next {
        right: 28px;
      }
    }
    .feature-4 {
      .slick-list {
        margin: -30px -40px;
        .slick-track {
          .slick-slide {
            > div {
              margin: 30px 40px;
            }
          }
        }
      }
      &.arrow-gradient {
        .slick-arrow {
          left: unset !important;
          right: 0;
          top: 0;
        }
        .slick-prev {
          right: 4%;
        }
      }
    }
    .feature-image,
    .feature-content {
      box-shadow: 0 0 23px rgba($black, 0.08);
      .bg-size {
        &:before {
          padding-top: 72.5%;
        }
      }
    }
  }
  .modern-feature {
    margin-top: 50px;
  }
  // other css
  .new-property {
    .feature-wrap {
      margin: 0 51px;
    }
  }
  // home section css
  .feature-3 {
    .next-image {
      right: 0px;
      top: 225px;
    }
  }
  .layout-home3 {
    .banner-3 {
      width: 53%;
    }
  }
  .layout-9 {
    .layout-right-img {
      img {
        height: 700px;
      }
    }
  }
  .home-section {
    &.layout-5 {
      .home-main {
        width: 100%;
        padding: 60px 0;
      }
    }
    &.layout-9 {
      min-height: 776px;
      .home-main {
        .home-content {
          h1 {
            height: 170px;
          }
        }
      }
    }
  }
}
@media (max-width: 1544px) and (min-width: 1461px) {
  // slider css
  .feature-section {
    .feature-4 {
      .slick-list {
        margin: -30px -15px;
        .slick-track {
          .slick-slide {
            > div {
              margin: 30px 15px;
            }
          }
        }
      }
    }
  }
  .slick-between {
    .slick-slider {
      .slick-list {
        margin: 0 -12px;
        .slick-track {
          .slick-slide {
            > div {
              margin: 0 12px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1544px) {
  .property-section {
    .list-property {
      .property-box {
        box-shadow: 0 0 13px 0px rgba($black, 0.06);
      }
    }
  }
  .pricing-section  {
    .pricing-slider {
      .owl-stage-outer {
        .owl-item {
          > div {
            margin: 30px 7px !important;
          }
        }
      }
    }
  }
  // other css
  .pricing-section {
    .pricing-box {
      box-shadow: 0 0 14px rgba($black, 0.05);
    }
    &.slick-between {
      .slick-slider {
        .slick-list {
          margin: -30px -12px;
          .slick-track {
            .slick-slide {
              > div {
                margin: 30px 12px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1461px) {
  .container {
    max-width: 1400px;
  }
  .property-grid-slider {
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            .slick-track,
            .slick-list {
              height: 338px;
              .bg-size {
                min-height: 338px;
              }
            }
          }
        }
      }
    }
  }
  .property-list-thumbnail {
    .property-grid-slider {
      .property-grid {
        &.list-view {
          .property-box {
            .property-image {
              .slick-track,
              .slick-list {
                height: unset;
                .bg-size {
                  min-height: 338px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1460px) and (min-width: 1200px) {
  .xl-40 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .xl-60 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  header.header-4 {
    .main-navbar {
      .nav-menu {
        > li {
          padding: 20px 8px;
        }
      }
    }
  }
  // footer css
  .footer-custom-col {
    .row {
      > div {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        &:nth-child(4),
        &:last-child {
          margin-top: 30px;
          .footer-left-space {
            padding-left: 0;
          }
        }
      }
    }
    .order-xl {
      order: -1;
    }
  }
  // about us css
  .about-main {
    .user-about {
      .row {
        > div {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
  // property css
  .property-section {
    .list-property {
      .property-box {
        .property-image {
          .overlay-property {
            .overlay-box {
              padding: 30px;
              top: 11%;
              p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1460px) {
  .about-section {
    .about-wrap {
      .about-content {
        .about-image {
          .overlay-content {
            ul {
              margin-left: unset;
            }
          }
        }
      }
    }
  }
  .testimonial-client .owl-carousel .owl-nav {
    [class*=owl-] {
      bottom: 30px;
    }
  }
  .property-section {
    .property-wrapper-grid {
      .property-grid {
        > [class*="col-lg-4"] , > .col-xl-4{
          .property-box {
            .property-details {
              ul {
                li {
                  padding-right: 5px;
                  padding-left: 5px;
                  img {
                    height: 14px;
                    margin-right: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .filter-panel {
      .grid-list-filter {
        .collection-grid-view {
          li {
            &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
  .property-details {
    .property-box {
      .property-details {
        > ul {
          > li {
            > img {
              height: 15px;
              display: inline-block;
              margin-right: 7px;
            }
          }
        }
      }
    }
    ul {
      li {
        padding-left: 6px;
        padding-right: 6px;
        font-size: 13px;
      }
    }
  }

  h2 {
    line-height: 1.08;
  }
  .contact-3 {
    .title-3 {
      margin-top: -3px;
    }
  }
  .title-3 {
    &.text-start {
      h2 {
        margin-top: -4px;
      }
    }
  }
  .login-wrap {
    .log-in {
      .title-3 {
        &.text-start {
          h2 {
            margin-top: -3px;
          }
        }
      }
    }
  }
  .sign-up {
    .title-3 {
      &.text-start {
        h2 {
          margin-top: 2px;
        }
      }
    }
  }
  .modern-dot {
    &.slick-dotted {
      margin-bottom: 2px;
    }
    &.property-3 {
      &.slick-dotted {
        margin-bottom: 3px;
      }
    }
  }
  .layout-map {
    .withmap-horizontal {
      .search-panel {
        .width-fit {
          > div {
            &:first-child {
              max-width: 28.7%;
            }
            &:nth-child(2) {
              max-width: 26.2%;
            }
          }
        }
        .filter {
          .media {
            .media-body {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  .title-2 {
    h2 {
      margin-top: 0;
    }
  }
  .testimonial-style-1 {
    .title-2 {
      h2 {
        margin-top: -6px;
        line-height: 1.35;
      }
    }
  }
  .theme-title {
    .title-2 {
      h2 {
        margin-top: -1px;
      }
    }
  }
  .blog-inner {
    .title-2 {
      h2 {
        margin-top: -2px;
      }
    }
  }
  .grid-box {
    .property-text {
      h3 {
        margin-top: -2px;
      }
    }
  }
  .my-profile {
    .profile-info {
      .information {
        ul {
          li {
            span {
              &:first-child {
                min-width: 140px;
              }
            }
          }
        }
      }
    }
  }
  .about-main,
  .why-choose {
    .title-2 {
      h2 {
        margin-top: -2px;
      }
    }
  }
  .about-testimonial {
    .title-2 {
      h2 {
        margin-top: -1px;
      }
    }
  }
  .about-section {
    .title-3 {
      margin-top: -11px;
    }
    &.about-inner {
      .title-2 {
        h2 {
          margin-top: -4px;
        }
      }
    }
  }
  .about-people {
    .title-2 {
      h2 {
        margin-top: -2px;
      }
    }
  }
  .coming-simple {
    .title-3 {
      h6 {
        margin-bottom: 10px;
      }
    }
  }
  .faq-questions {
    .title-3 {
      margin-top: 0;
      h2 {
        margin-top: -3px;
      }
    }
  }
  .terms-section {
    .terms-wrap {
      margin-bottom: -7px;
    }
  }
  .layout-9 {
    .layout-right-img {
      img {
        height: 650px;
      }
    }
  }
  // blog css
  .blog-2 {
    .slick-list {
      .slick-track {
        .slick-slide {
          > div {
            margin: 0 15px;
          }
        }
      }
    }
  }
  .blog-section {
    .title-2 {
      h2 {
        margin-top: -2px;
      }
    }
  }
  // single property css
  .single-property-section {
    .left-single {
      h2 {
        margin-top: -2px;
      }
    }
  }
  // other css
  .new-property {
    .title-2 {
      h2 {
        margin-top: -3px;
      }
    }
    &.pricing-property {
      .title-2 {
        h2 {
          margin-top: -3px;
        }
      }
    }
  }
  .bg-comman-2 {
    &.feature-section {
      .title-2 {
        h2 {
          margin-top: -1px;
        }
      }
    }
  }
  // user dashboard css
  .user-dashboard {
    .dashboard-content {
      .user-wrapper {
        .overview {
          .overview-content {
            li {
              .d-flex {
                > span {
                  margin-left: -10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .tab-listing {
    .property-details {
      .my-listing {
        top: 27px;
        right: 30px;
      }
    }
  }
  .card-payment {
    .payment-card {
      padding: 15px 10px;
      min-height: auto;
    }
  }
  // footer css
  .footer-bg {
    .footer-details {
      padding: 30px 15px;
      .icon-list {
        li {
          + li {
            margin-left: 2px;
          }
        }
      }
    }
  }
  footer {
    .footer-links {
      .footer-content {
        margin-top: 40px;
        .footer-map {
          iframe {
            width: 100%;
            height: 190px;
          }
        }
      }
    }
    &.footer-dark {
      .footer-details {
        p {
          margin-top: 34px;
        }
      }
    }
  }
  // signup wizard css
  .property-wizard {
    .wizard-box {
      .wizard-form-details {
        h2 {
          margin-top: -3px;
        }
      }
    }
    &.horizontal-wizard {
      .wizard-box {
        .wizard-step-container {
          .wizard-steps {
            li {
              .media {
                .step-icon {
                  svg {
                    width: 21px;
                    height: 21px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // about us css
  .user-about {
    .about-image {
      margin-top: 60px;
      .img-box {
        img {
          max-width: 295px;
        }
        &.img-abs {
          right: 3%;
        }
      }
    }
  }
  // contact us css
  .get-in-touch {
    .contact-img {
      img {
        width: 95%;
      }
    }
  }
  // blog css
  .blog-1 {
    .blog-box {
      .img-box {
        img {
          min-width: 245px;
          height: 100%;
        }
      }
      .blog-content {
        padding: 25px 30px;
        h3,
        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  // blog creative css
  .blog-list-section {
    .blog-list {
      .blog-wrap {
        .blog-image {
          width: 100%;
        }
        .blog-details {
          padding: 20px;
        }
      }
    }
  }
  // testimonial css
  .our-client {
    .client-right {
      padding: 30px 60px;
    }
    .quote-img {
      right: -30px;
      bottom: -15px;
      img {
        width: 65%;
      }
    }
  }
  .client-slider {
    width: 75%;
  }
  .modern-client {
    .comment-right {
      padding: 40px;
      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .slick-arrow {
        top: 91.2%;
        left: -17%;
      }
      .slick-next {
        left: -7.4%;
      }
    }
    .right-align {
      right: -100px;
      top: 45px;
    }
  }
  .testimonial-1 {
    .pepole-comment {
      .client-msg {
        .quote {
          top: -16px;
          img {
            width: 70%;
          }
        }
        p {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .slick-list {
      .slick-track {
        .slick-slide {
          > div {
            margin-top: 16px !important;
          }
        }
      }
    }
  }

  .testimonial-client {
    .title-3 {
      &.text-start {
        margin-top: 3px;
      }
    }
  }
  // full banner css
  .banner-1,
  .banner-3 {
    width: 85%;
  }
  .banner-3 {
    h2,
    p {
      width: 82%;
    }
  }
  // about css
  .about-section {
    .about-wrap {
      &.about-1 {
        .about-content {
          .our-details {
            padding: 20px;
          }
        }
      }
      .about-content {
        .about-image {
          .overlay-content {
            ul {
              li {
                a {
                  width: 35px;
                  height: 35px;
                }
                + li {
                  margin-left: 5px;
                }
              }
            }
            span {
              letter-spacing: 5px;
              &::before {
                left: -27px;
              }
            }
          }
        }
      }
    }
    .title-3 {
      &.text-start {
        margin-top: -3px;
      }
    }
  }
  .arrow-white {
    .slick-prev {
      left: 44%;
    }
    .slick-next {
      right: 44%;
    }
  }
  .arrow-gradient {
    &.arrow-right {
      .slick-arrow {
        top: -88px;
      }
    }
  }
  .slick-between {
    .slick-slider {
      .slick-list {
        margin: 0 -12px;
        .slick-track {
          .slick-slide {
            > div {
              margin: 0 12px;
            }
          }
        }
      }
    }
  }
  .about-box {
    .agent-image {
      .overlay-agent {
        .agent-details {
          padding: 30px;
        }
      }
    }
  }
  // service css
  .service-slider {
    &.arrow-gradient {
      &.arrow-right {
        .slick-prev {
          right: 15%;
        }
        .slick-next {
          right: 11%;
        }
        .slick-arrow {
          top: -61px;
        }
      }
    }
  }
  .service-section {
    .title-2 {
      h2 {
        margin-top: -1px;
      }
    }
    .column-space {
      > div {
        padding: 0 15px;
      }
    }
    &.service-2 {
      .property-service {
        > div {
          .service-box {
            padding: 40px 30px;
            p {
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 3;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    &.service-bg {
      .title-3 {
        margin-top: 0px;
        &.text-start {
          h2 {
            margin-top: -1px;
          }
        }
      }
    }
  }
  // property css
  .property-details {
    padding: 25px !important;
    .property-btn {
      .btn {
        padding: 8px 25px 7px;
        &.btn-dashed {
          padding: 9px 25px 8px;
        }
        + .btn {
          margin-left: 10px;
        }
      }
    }
  }
  .property-section {
    .column-space {
      > div {
        padding: 0 12px;
      }
    }
    .title-2 {
      h2 {
        margin-top: -1px;
      }
    }
    .title-3 {
      &.text-start {
        margin-top: 1px;
        h2 {
          margin-top: -2px;
        }
      }
      .pagination-tab {
        margin-top: -2px;
      }
    }
    .property-3 {
      .slick-list {
        .slick-track {
          .slick-slide {
            > div {
              margin: 30px 15px;
            }
          }
        }
      }
    }
    &.slick-between {
      .title-3 {
        &.text-start {
          margin-top: -4px;
        }
      }
      .property-1 {
        &.slick-slider {
          .slick-list {
            margin: 0 -12px;
            .slick-track {
              .slick-slide {
                > div {
                  margin: 0 12px;
                }
              }
            }
          }
        }
      }
    }
    .list-property {
      .property-box {
        .property-image {
          .overlay-property {
            .overlay-box {
              height: calc(100% - 75px);
            }
          }
        }
      }
    }
  }
  .property-grid-2 {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: 0;
        }
      }
    }
  }
  .property-grid-3 {
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 35%;
          }
        }
        > div {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -1px;
        }
      }
    }
  }
  .property-map {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -1px;
        }
      }
    }
  }
  .property-grid-slider {
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 31%;
            .bg-size {
              height: 300px;
            }
          }
        }
      }
    }
  }
  // single property details
  .gallery-nav {
    .slick-slide {
      img {
        min-height: 78px;
      }
    }
  }
  .modal-form {
    top: 19px;
  }
  .single-property-images {
    &.ratio_40 {
      .bg-size {
        &:before {
          padding-top: 40%;
        }
      }
    }
  }
  // feature css
  .bg-comman-2 {
    &::before {
      height: 355px;
    }
  }
  .feature-section {
    .modern-feature {
      .feature-wrap {
        .feature-content {
          padding-left: 25px;
        }
        .feature-image {
          .label-gradient {
            top: 30px;
            left: 30px;
          }
        }
      }
    }
    .feature-image {
      .feature-overlay {
        transform: scale(0.9) translateY(-50%);
      }
      .bg-size {
        &:before {
          padding-top: 83.5%;
        }
      }
    }
    .feature-1 {
      .feature-wrapper {
        margin: 0 60px;
        .property-details {
          max-width: 500px;
        }
      }
    }
    .feature-3 {
      .slick-next {
        transform: scale(0.9);
        top: 43%;
      }
      .slick-prev {
        transform: scale(0.9);
        top: 62%;
      }
    }
    .feature-content {
      .details,
      .feature-price {
        padding: 30px;
      }
      .detail-list {
        padding: 25px;
        margin: 0;
        li {
          margin: 5px;
          .d-flex {
            h6 {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  // other page css
  .pricing-section {
    .pricing-box {
      padding: 40px;
    }
    &.pricing-inner {
      .title-2 {
        h2 {
          margin-top: -1px;
        }
      }
    }
  }
  .offer-section {
    &.offer-inner {
      .title-2 {
        h2 {
          margin-top: -3px;
        }
      }
    }
    .offer-wrapper {
      padding: 30px;
      .media {
        .offer-icon {
          transform: scale(0.9);
        }
        .media-body {
          margin-left: 20px;
        }
      }
    }
    &.slick-between {
      .slick-slider {
        .slick-list {
          margin: 0 -12px;
          .slick-track {
            .slick-slide {
              > div {
                margin: 0 12px;
              }
            }
          }
        }
      }
    }
  }
  .my-gallery {
    .find-cities {
      .citi-overlay {
        h6 {
          &::after {
            width: 150px;
            right: -24px;
          }
        }
      }
    }
  }
  .video-details {
    padding: 40px;
  }
  // home section css
  .animate-rdi {
    margin-left: 18px;
    label {
      margin-right: 17px;
      padding-left: 17px;
      padding-right: 17px;
    }
  }
  .layout-home2 {
    .feature-section {
      .feature-content {
        max-width: 500px;
        .label-cross {
          padding-top: 8px;
          padding-bottom: 5px;
          font-size: 14px;
          left: -34px;
        }
      }
    }
  }
  .feature-3 {
    .next-image {
      right: 5px;
      top: 213px;
      width: 89px;
      height: 89px;
    }
  }
  .layout-home3 {
    .banner-3 {
      width: 56%;
      h1 {
        line-height: 1.5;
      }
    }
  }
  .top-bar {
    .top-content {
      .top-bar-right {
        li {
          &:first-child {
            width: 100%;
          }
        }
      }
    }
  }
  .layout-home4 {
    .slider-image {
      .home-content {
        padding: 40px 50px 40px 0;
        h1 {
          line-height: 1.3;
        }
        p {
          width: 55%;
        }
      }
    }
  }
}
@media (max-width: 1399px) {
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  .title-3 {
    &.text-start {
      h2 {
        margin-top: -5px;
      }
    }
  }
  .our-agent {
    .agent-image {
      &.bg-size {
        &:before {
          padding-top: 82%;
        }
      }
    }
  }
  .testimonial-client .owl-carousel .owl-nav [class*=owl-] {
    bottom: 8px;
  }
  .contact_section {
    .contact-content {
      width: 100%;
    }
  }
  .agent-section {
    .about-agent {
      h3 {
        margin-top: -3px;
      }
    }
  }
  .modern-dot {
    &.slick-dotted {
      margin-bottom: 3px;
    }
  }
  .theme-label {
    .card-header {
      h5 {
        margin-top: -1px;
      }
    }
  }
  .theme-title {
    .theme-card {
      .card-header {
        h4 {
          margin-top: -3px;
          margin-bottom: -1px;
        }
      }
    }
    .title-1 {
      h2 {
        margin-bottom: -8px;
      }
    }
    .title-2 {
      h2 {
        margin-top: -3px;
      }
    }
  }
  .feature-section .feature-3 .feature-wrap .row {
    margin: 0 15px !important;
  }
  .feature-section .feature-3 .owl-carousel .owl-nav {
    .owl-prev {
      top: 21px;
      right: 86px;
    }
    .owl-next {
      top: 21px;
      right: 31px;
    }
  }
  .title-2 {
    h2 {
      margin-top: -2px;
    }
  }
  .footer-light {
    .footer-left-space {
      padding-left: 20px;
    }
  }
  footer {
    &.footer-light {
      .footer {
        margin-bottom: -6px;
      }
    }
  }
  .offer-section {
    .title-1 {
      h2 {
        margin-bottom: -8px;
      }
    }
    &.offer-inner {
      .title-2 {
        h2 {
          margin-top: -4px;
        }
      }
    }
  }
  .banner-section {
    &.layout3-bg {
      .banner-3 {
        h2,
        p {
          width: 100%;
        }
      }
    }
  }
  .layout-home2 {
    .feature-section {
      .feature-content {
        margin-left: 50px;
      }
    }
  }
  .ratio-card {
    .card-header {
      h5 {
        margin-top: -4px;
      }
    }
    .card-body {
      h5 {
        margin-top: -1px;
      }
    }
  }
  .new-property {
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
    &.pricing-property {
      .title-2 {
        h2 {
          margin-top: -4px;
        }
      }
    }
  }
  .feature-section {
    .feature-content {
      .details {
        h3 {
          margin-top: -3px;
        }
      }
    }
    .feature-1 {
      .feature-wrapper {
        .feature-image {
          height: 490px;
        }
      }
    }
    .feature-3 {
      .slick-next {
        top: 70px;
        right: 70px;
      }
      .slick-prev {
        top: 70px;
        right: 120px;
      }
    }
  }
  .property-grid-2 {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -2px;
        }
      }
    }
  }
  .property-list-view {
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 143%;
          }
        }
      }
    }
  }
  .testimonial-client {
    .title-3 {
      &.text-start {
        margin-top: 2px;
      }
    }
  }
  .about-main,
  .why-choose,
  .blog-inner {
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
  }
  .about-testimonial {
    .title-2 {
      h2 {
        margin-top: -3px;
      }
    }
  }
  .about-people {
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
  }
  .pricing-section {
    &.pricing-inner {
      .title-2 {
        h2 {
          margin-top: -3px;
        }
      }
    }
  }
  .faq-questions {
    .title-3 {
      h2 {
        margin-top: -3px;
      }
    }
  }
  .modal-form {
    padding: 20px;
    right: 16%;
  }
  .filter-cards {
    .advance-card {
      .advance-title {
        margin-top: -2px;
      }
    }
  }
  .terms-section {
    .terms-wrap {
      margin-bottom: -5px;
    }
  }
  .bg-comman-2 {
    &.feature-section {
      .title-2 {
        h2 {
          margin-top: -3px;
        }
      }
    }
  }
  // home section css
  .fnc-slide__heading-line {
    font-size: 80px;
  }
  .layout-9 {
    .layout-right-img {
      img {
        height: 560px;
      }
    }
  }
  .looking-icons {
    ul {
      li {
        .looking-icon {
          svg {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  .home-section {
    &.layout-9 {
      min-height: 762px;
    }
  }
  .layout-home8 {
    height: 750px;
    min-height: unset;
  }
  // blog css
  .blog-section {
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
  }

  // user dashboard css
  .user-dashboard {
    .sidebar-user {
      .user-profile {
        .media {
          .change-pic {
            img,
            .change-hover {
              height: 60px;
              width: 65px;
            }
          }
          .media-body {
            h6 {
              font-size: 14px;
            }
          }
        }
        .connected-social {
          h6 {
            &::before {
              bottom: -5px;
            }
          }
        }
      }
    }
    .dashboard-content {
      .user-wrapper {
        .widgets {
          .media {
            .small-bar {
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
  // single property css
  .single-property-section {
    .left-single {
      h2 {
        margin-top: -4px;
      }
    }
  }
  .single-property {
    .description-section {
      .desc-box {
        h4 {
          margin-top: -3px;
        }
        .feature-dec,
        .ratio3_2 {
          h4 {
            margin-top: -4px;
          }
        }
      }
    }
  }
  .gallery-nav {
    .slick-slide {
      img {
        min-height: 70px;
      }
    }
  }
  .inner-title {
    &.title-3 {
      h2 {
        margin-top: 1px;
      }
    }
  }
  // portfolio details css
  .details-right {
    h3 {
      margin-top: -2px;
    }
  }
  .portfolio-creative {
    .content-sec {
      h3 {
        margin-top: -2px;
      }
    }
  }
  // about css
  .about-section {
    .about-2 {
      &.about-wrap {
        .about-content {
          .our-details {
            padding: 30px;
          }
        }
      }
    }
    .title-3 {
      &.text-start {
        margin-top: -6px;
      }
    }
    &.about-inner {
      .title-2 {
        h2 {
          margin-top: -6px;
        }
      }
    }
  }
  // testimonial css
  .testimonial-style-1 {
    .title-2 {
      h2 {
        margin-top: -7px;
      }
    }
  }

  .modern-client {
    .comment-right {
      .slick-arrow {
        left: 15px;
        top: 98.2%;
      }
      .slick-next {
        left: 70px;
        top: 98.2%;
      }
    }
  }
  // service element css
  .service-section {
    &.service-bg {
      .title-3 {
        margin-top: -3px;
        &.text-start {
          h2 {
            margin-top: 0;
          }
        }
      }
    }
    .title-2 {
      h2 {
        margin-top: -3px;
      }
    }
  }
  .service-slider {
    &.arrow-gradient {
      &.arrow-right {
        .slick-arrow {
          top: -58px;
        }
      }
    }
  }
  // property element css
  .property-section {
    .title-1 {
      h2 {
        margin-bottom: -8px;
      }
    }
    .title-3 {
      &.text-start {
        margin-top: 0px;
        h2 {
          margin-top: -3px;
        }
      }
      .pagination-tab {
        margin-top: -1px;
      }
    }
    .title-2 {
      h2 {
        margin-top: -3px;
      }
    }
    .list-property {
      .property-box {
        .property-image {
          .overlay-property {
            .overlay-box {
              height: calc(100% - 62px);
            }
          }
        }
      }
    }
    &.slick-between {
      .title-3 {
        &.text-start {
          margin-top: -6px;
        }
      }
    }
  }
  .arrow-gradient {
    &.arrow-right {
      .slick-arrow {
        top: -84px;
      }
    }
  }
  .property-grid-3 {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -3px;
        }
      }
    }
  }
  .property-map {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -3px;
        }
      }
    }
  }
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .single-property .description-section .menu-top li.active a {
    border-bottom: none;
  }
  header.header-4 {
    .menu {
      .header-right {
        li {
          + li {
            margin-left: 8px;
          }
        }
      }
    }
    .main-navbar {
      .nav-menu {
        > li {
          padding: 20px 3px;
        }
      }
    }
  }
  .property-wrapper-grid {
    .property-box {
      .property-image {
        .overlay-property-box {
          bottom: 8px;
          .effect-round {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .filter-cards {
    .advance-card {
      .recent-property {
        margin-bottom: -2px;
        ul {
          li {
            .media {
              img {
                width: 62px;
                height: 42px;
              }
              .media-body {
                h5 {
                  margin-bottom: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .review {
    .review-box {
      .media {
        .rating {
          padding: 0;
        }
      }
    }
  }
  .details-right {
    padding: 28px;
  }
  .grid-box {
    .property-text {
      padding: 22px;
    }
  }
  .testimonial-client .owl-carousel .owl-nav {
    .owl-prev {
      right: 60%;
    }
    .owl-next {
      left: 40%;
    }
  }
  .service-section .service-slider .owl-carousel .owl-prev {
    right: 16.5% ;
  }
  .about-section .about-1 .owl-carousel {
    .owl-prev .fa{
      margin-top: 0 !important;
    }
    .owl-next .fa{
      margin-top: 0 !important;
    }
  }
  .ratio_63 {
    .property-wrapper-grid {
      &.list-view {
        .property-box {
          .bg-size {
            &::before {
              padding-top: 73%;
            }
          }
        }
      }
    }
  }
  .property-section {
    .property-wrapper-grid {
      .property-grid {
        > [class*="col-lg-4"], > .col-xl-4 {
          .property-box {
            .property-details {
              ul {
                li {
                  &:last-child {
                    padding-left: 0;
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .video-layout.parallax-home {
    height: 580px;
  }
  .property-section {
    .ratio_65 {
      .bg-size {
        &::before {
          padding-top: 70%;
          content: "";
          display: block;
        }
      }
    }
  }
  .property-grid.list-view {
    .property-box {
      .property-details {
        p {
          display: none;
        }
      }
    }
  }
  .property-list-thumbnail {
    .property-grid {
      &.list-view {
        .property-image {
          .slider-for {
            .slick-slide {
              margin-bottom: -15px;
            }
          }
        }
      }
    }
  }
  .property-grid-slider {
    .property-grid {
      .slider-nav {
        .slick-slide {
          img {
            min-height: 65px;
          }
        }
      }
    }
  }
  .tab-listing {
    .property-details {
      .my-listing {
        top: 22px;
      }
    }
  }
  .feature-section .feature-1 .owl-carousel .owl-nav [class*=owl-] {
    width: 40px !important;
    height: 40px !important;
    .fa {
      margin-top: 10px !important;
    }
  }
  .testimonial-bg .testimonial-2 .owl-carousel .owl-nav  [class*=owl-] {
    width: 40px !important;
    height: 40px !important;
    .fa {
      margin-top: 10px !important;
    }
  }
  // slick css
  .arrow-light {
    .slick-arrow {
      width: 40px;
      height: 40px;
    }
    .slick-prev {
      left: 0;
      &:before {
        margin-left: -1px;
      }
    }
    .slick-next {
      right: 0;
      &:before {
        margin-left: 2px;
      }
    }
  }
  // thumbnail list css
  .property-grid-slider {
    .property-grid {
      .slider-for {
        .slick-slide {
          img {
            min-height: 250px;
          }
        }
      }
    }
  }
  // home section css
  .layout-home3 {
    .banner-3 {
      padding: 35px;
    }
  }
  .layout-home2 {
    .arrow-light {
      .slick-prev {
        left: 2px;
      }
      .slick-next {
        right: 2px;
      }
    }
  }
  // testimonial css

  .modern-client {
    .comment-right {
      left: -130px;
    }
  }
  // service css
  .service-slider {
    &.arrow-gradient {
      &.arrow-right {
        .slick-prev {
          right: 12%;
        }
        .slick-next {
          right: 8%;
        }
      }
    }
  }
  // feature css
  .feature-section {
    .feature-4 {
      margin-top: 50px;
      &.arrow-gradient {
        .slick-arrow {
          left: unset !important;
          right: 0;
          top: 0;
        }
        .slick-prev {
          right: 5%;
        }
      }
      .slick-list {
        margin: -30px -12px -40px;
        .slick-track {
          .slick-slide {
            > div {
              margin: 40px 12px;
              .feature-wrap {
                .feature-content {
                  animation-delay: 1s;
                }
              }
            }
          }
        }
      }
    }
    .feature-3 {
      .slick-list {
        .slick-track {
          .slick-slide {
            > div {
              margin-left: 20px;
            }
          }
        }
      }
    }
    .feature-image {
      .label-solid {
        &::before {
          top: 35px;
        }
      }
      .bg-size {
        &:before {
          padding-top: 81%;
        }
      }
    }
    .modern-feature {
      .feature-wrap {
        .feature-image {
          box-shadow: 12px 0 26px rgba($black, 0.1);
        }
      }
    }
  }
  // other page css
  .video-section {
    padding-left: 70px;
    padding-right: 70px;
  }
  .new-property {
    .feature-wrap {
      margin: 0 35px;
    }
  }
  .subscribe-section {
    .video-details {
      .form-group {
        input,
        &::after {
          width: 65%;
        }
      }
    }
  }
}
@media (max-width: 1366px) and (min-width: 1200px) {
  .property-list-view{
    .property-grid{
      &.list-view{
        .property-box{
          .property-image{
            width: 48%;
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .home-section .home-slider-1 .owl-carousel .owl-next {
    left: 11%;
  }
  .single-property.mt-0.pt-0 {
    padding-bottom: 50px;
  }
  .property-details {
    padding: 16px !important;
  }
  header .main-navbar .nav-menu > li a{
    .nav-submenu a{
      padding: 0 16px 0;
    }
  }
  header .menu .nav-collapse {
    margin-left: auto;
    margin-right: 30px;
  }
  header .menu > app-menu {
    margin-left: auto;
    margin-right: 30px;
  }
  .about-section {
    .about-wrap {
      .about-content {
        .our-details {
          border-radius: 0 0 10px 10px;
        }
      }
    }
  }
  .single-property-section .left-single ul {
    display: block;
  }
  .testimonial-client .owl-carousel .owl-nav {
    .owl-prev {
      right: 48%;
    }
    .owl-next {
      left: 52%;
    }
    [class*=owl-] {
      bottom: -6px;
    }
  }
  .about-section .about-1 .owl-carousel {
    .owl-prev {
      left: 43%;
    }
    .owl-next {
      right: 43%;
    }
  }
  .user-about {
    .map-image  {
      width: 40% !important;
    }
  }
  .layout-home3 .home-slider-3 .owl-nav {
    .owl-prev {
      width: 50px !important;
      height: 50px !important;
    }
    .fa {
      margin-top: 13px !important;
    }
    .owl-next {
      width: 50px !important;
      height: 50px !important;
    }
  }
  .video-layout.parallax-home {
    height: 495px;
  }
  .fnc-slide__action-btn {
    line-height: 3;
    span {
      line-height: 3;
    }
  }
  .fnc-slide__heading {
    margin-bottom: 12px;
  }
  .layout-home4 {
    .slider-image {
      .home-content {
        .square-bg {
          width: 100%;
        }
      }
    }
  }
  .property-grid-2 {
    .property-wrapper-grid {
      .property-grid.list-view {
        .property-box {
          .property-image {
            width: 40%;
          }
          .property-details {
            padding: 20px !important;
          }
        }
      }
    }
  }
  .property-section {
    .filter-panel {
      .grid-list-filter {
        .collection-grid-view {
          ul {
            display: none;
          }
        }
      }
    }
  }
  .video-layout {
    .video-details {
      .left-sidebar {
        width: 440px;
      }
    }
  }
  .banner-inner {
    padding-top: 159px;
  }
  section {
    padding: 80px 0;
  }
  .small-section {
    padding: 50px 0;
  }
  header {
    .main-navbar {
      .nav-menu {
        > li {
          > a {
            padding: 7px 15px 5px;
          }
        }
      }
    }
    &.header-9 {
      .menu {
        .brand-logo {
          margin-left: -25px;
        }
      }
    }
  }
  .filter-cards {
    app-contact-info {
        .advance-card {
            margin-top: 30px !important;
        }
    }
    app-recently-added {
        .advance-card {
            margin-top: 30px !important;
        }
    }
    .advance-card {
        margin-top: 30px;
    }
  }
  .filter-panel {
    margin-bottom: 40px;
    .grid-list-filter {
      li {
        > a,
        .dropdown-toggle,
        .filter-bottom-title {
          padding: 8px 12px;
        }
        .filter-bottom-title {
          padding: 10px 12px;
        }
      }
    }
    .d-lg-none {
      .mobile-filter {
        padding: 8px 12px;
      }
    }
    &.tab-top-panel {
      .filters {
        ul {
          li {
            padding: 10px 15px;
            min-width: 110px;
          }
        }
      }
    }
  }
  .video-layout {
    .play-bg {
      height: 410px;
    }
  }
  .layout-map {
    .withmap-horizontal {
      .search-panel {
        .filter {
          padding: 40px 25px;
          .media {
            .icon-square {
              width: 40px;
              height: 40px;
              i {
                font-size: 18px;
              }
            }
            .media-body {
              margin-right: 15px;
            }
          }
        }
        .width-fit {
          > div {
            &:first-child {
              max-width: 29%;
            }
            &:nth-child(2) {
              max-width: 26.6%;
            }
          }
        }
      }
    }
  }
  .modern-dot {
    &.slick-dotted {
      margin-bottom: 2px;
    }
    &.property-3 {
      &.slick-dotted {
        margin-bottom: 2px;
      }
    }
  }
  .blog-section {
    &.bg-comman-2 {
      .blog-box {
        padding: 11px;
      }
    }
  }
  // coming soon
  .coming-soon {
    min-height: auto;
    .coming-soon-detail {
      min-height: auto;
    }
  }

  .portfolio-details {
    .portfolio-section {
      margin-top: 30px;
    }
  }
  // property css
  .property-section .property-2 .property-box .property-image .labels-left,
  .property-section .property-3 .property-box .property-image .labels-left {
    left: 15px;
  }
  // user dashboard css
  .user-dashboard {
    .dashboard-content {
      .user-wrapper {
        .sales-agent {
          #agent-sales {
            margin-top: -36px;
          }
        }
        .widgets {
          .media {
            .small-bar {
              margin-bottom: 3px;
            }
          }
        }
      }
    }
  }
  .card-payment {
    > div {
      &:nth-child(n + 3) {
        .payment-card {
          margin-top: 30px;
        }
      }
    }
    .payment-card {
      &.add-card {
        .card-details {
          min-height: 176px;
          height: auto;
        }
      }
      &.american-express {
        height: unset;
      }
    }
  }
  // signup wizard css
  .property-wizard {
    &.horizontal-wizard {
      .wizard-box {
        .wizard-step-container {
          .wizard-steps {
            flex-wrap: wrap;
            display: flex;
            margin: -5px;
            li {
              flex: 0 0 48%;
              max-width: 48%;
              margin: 5px;
            }
          }
        }
      }
    }
  }
  // submit property css
  .property-wizard {
    .feature-checkbox {
      margin: -5px -10px;
      label {
        margin: 5px 10px;
      }
    }
    .wizard-box {
      .wizard-form-details {
        .complete-details {
          width: 87%;
        }
      }
    }
  }
  // comingsoon css
  .coming-simple {
    height: auto;
  }
  // 404 page css
  .error-section {
    .not-found {
      p {
        max-width: 55%;
      }
    }
  }
  // faq page css
  .accordion {
    .card-header {
      padding: 15px 40px 15px 20px;
      .card-link {
        &::after {
          right: 20px;
          top: 16px;
        }
      }
    }
    .card-body {
      padding: 20px;
    }
  }
  .faq-section {
    .faq-image {
      img {
        width: 90%;
      }
    }
  }
  // about us css
  .user-about {
    .map-image {
      ul {
        li {
          img {
            width: 6%;
          }
        }
      }
    }
    .about-image {
      margin-top: 52px;
      .img-box {
        img {
          max-width: 260px;
        }
      }
      .side-left {
        .side-effect {
          &::before,
          &::after {
            width: calc(100% - 295px);
          }
        }
      }
    }
  }
  // contact us css
  .get-in-touch {
    .contact-img {
      img {
        width: 100%;
      }
    }
  }
  // agent profile css
  .agent-section {
    .our-agent {
      .ratio_landscape {
        .bg-size {
          &::before {
            padding-top: 112%;
          }
        }
      }
    }
  }
  // blog creative css
  .filter-cards {
    .advance-card {
      > h6 {
        margin-bottom: 25px;
      }
      + .advance-card {
        margin-top: 30px;
      }
    }
  }
  .blog-list-section {
    .blog-list {
      .blog-wrap {
        .blog-details {
          h3,
          p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
          }
        }
      }
    }
  }
  // google map css
  .map-section {
    iframe,
    .map {
      height: 350px;
      position: relative;
      margin-bottom: 40px;
    }
  }
  // map left page
  .map-leftside {
    iframe,
    .map {
      margin-bottom: 0;
    }
  }
  // map modal css
  .arrow-define {
    top: 75px;
    &::after {
      transform: scaleY(-1);
      bottom: 6px;
    }
  }
  .with-modal-btn {
    .top-panel {
      display: block;
      .grid-list-filter {
        margin-top: 15px;
      }
    }
  }
  .map-modal {
    .map-section {
      padding: 0;
    }
  }
  // home section css
  .fnc-slide__heading-line {
    font-size: 70px;
  }
  .home-section {
    height: auto;
    &.layout-5 {
      .decor-image {
        bottom: 0;
      }
    }
    &.layout-6 {
      padding-top: 0;
      .home-main {
        min-height: 880px;
        .container {
          > .row {
            min-height: 880px;
            padding-top: 0;
          }
        }
      }
    }
    &.layout-9 {
      padding: 80px 0;
      min-height: unset;
      .home-main {
        .home-content {
          h1 {
            height: 96px;
          }
        }
      }
    }
  }
  .layout-9 {
    .layout-right-img {
      img {
        height: 500px;
      }
    }
  }
  .parallax-home {
    .container {
      > .row {
        height: 320px;
      }
    }
  }
  .looking-icons {
    ul {
      li {
        .looking-icon {
          min-width: 130px;
        }
      }
    }
  }
  .layout-6,
  .layout-5 {
    .container {
      > .row {
        padding: 80px 0;
      }
    }
  }
  .vertical-search {
    width: auto;
  }
  .search-withmap {
    .vertical-search {
      width: 400px;
    }
  }
  .layout-map {
    height: auto;
  }
  .filter-bottom-content {
    top: 135px;
  }
  .layout-home2 {
    .home-main {
      padding-top: 160px;
      height: auto;
      min-height: auto;
      .feature-content {
        margin: 80px auto 80px 40px;
      }
    }
    .feature-section {
      .feature-content {
        max-width: 480px;
      }
    }
    .arrow-light {
      .slick-arrow {
        top: 66%;
      }
    }
  }
  .layout-home3 {
    .slick-slider {
      .slick-arrow {
        width: 80px;
        height: 80px;
        &::after {
          width: 50px;
          height: 50px;
        }
        &::before {
          font-size: 16px;
        }
      }
      .slick-prev {
        left: 95px;
      }
      .slick-next {
        right: 95px;
      }
    }
    .slick-arrow {
      .slick-thumb-nav {
        &::after {
          top: 9px;
          left: 10px;
          width: 60px;
          height: 60px;
        }
      }
    }
    .banner-3 {
      width: 57%;
      margin: 10px 0;
    }
    .bg-layout-3 {
      height: auto;
      padding: 80px 0;
    }
  }
  .layout-home4 {
    .sync-slider {
      .home-slider-4 {
        &.arrow-image {
          .owl-prev, .owl-next{
            width: 50px;
            height: 50px;
            line-height: 48px;
            &::after {
              width: 150px;
              height: 150px;
            }
          }
        }
      }
      .home-nav {
        left: 25px;
        .slider-image {
          width: 90px;
          height: 60px;
        }
        .slick-list {
          .slick-track {
            .slick-slide {
              > div {
                margin: 20px 5px;
              }
            }
          }
        }
      }
    }
    .slider-image {
      height: auto;
      .home-content {
        margin-bottom: 30px;
      }
    }
    .home-slider-4 {
      .slider-image {
        padding: 80px 0;
        min-height: unset;
      }
    }
  }
  .top-bar {
    .top-content {
      .top-bar-right {
        li {
          + li {
            margin-left: 15px;
          }
        }
      }
    }
    &.top-bar-7 {
      .top-bar-right {
        li {
          > a {
            padding: 12px;
          }
        }
      }
    }
  }
  header {
    &.header-2 {
      &::after {
        bottom: 80px;
      }
    }
    &.header-2,
    &.inner-page {
      .main-navbar {
        .nav-menu {
          > li {
            .nav-link {
              &.active,
              &:hover {
                color: var(--theme-default4) !important;
              }
            }
          }
        }
      }
    }
    &.header-3 {
      .toggle-nav {
        padding-top: 15px;
        padding-bottom: 15px;
      }
      .main-navbar {
        .nav-menu {
          > li {
            padding: 0;
            .nav-link {
              &.active,
              &:hover {
                color: var(--theme-default5) !important;
              }
            }
          }
        }
      }
    }
    &.header-4 {
      .main-navbar {
        .nav-menu {
          > li {
            .nav-link {
              &.active,
              &:hover {
                color: var(--theme-default7) !important;
              }
            }
          }
        }
      }
      .menu {
        .toggle-nav {
          i {
            color: $title-color;
          }
        }
      }
    }
    &.header-6 {
      .main-navbar {
        .nav-menu {
          > li {
            .nav-link {
              &.active,
              &:hover {
                color: var(--theme-default9) !important;
              }
            }
          }
        }
      }
    }
    &.header-7 {
      .main-navbar {
        .nav-menu {
          > li {
            .nav-link {
              &.active,
              &:hover {
                color: var(--theme-default10) !important;
              }
            }
          }
        }
      }
    }
    &.header-9 {
      .main-navbar {
        .nav-menu {
          > li {
            .nav-link {
              &.active,
              &:hover {
                color: var(--theme-default) !important;
              }
            }
          }
        }
      }
      .menu {
        .toggle-nav {
          i {
            color: $title-color;
          }
        }
      }
    }
    &.header-centered {
      nav {
        .nav-menu {
          width: 300px;
          display: block;
        }
        .brand-logo {
          position: relative;
          left: unset;
          top: unset;
          width: 150px;
          order: -1;
        }
      }
      #mainnav {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .animate-rdi {
    margin-left: 0;
    .col {
      justify-content: center;
    }
  }
  .animate-rdi {
    label {
      padding: 10px 15px;
    }
  }

  // title css
  .title-1 {
    padding-bottom: 40px;
  }
  .title-2 {
    p {
      margin-bottom: 40px;
    }
  }
  .title-3 {
    margin-bottom: 40px;
  }
  // breadcrumb css
  .breadcrumb-section {
    height: 400px;
    .breadcrumb-content {
      height: 400px;
      padding-top: 79px;
    }
    #block {
      height: 400px !important;
    }
    &.breadcrumb-sm {
      .breadcrumb-content {
        > div {
          nav {
            padding-top: 0;
          }
        }
      }
    }
  }
  // footer css
  footer {
    .footer {
      padding: 80px 0;
    }
    .footer-links {
      .footer-content {
        .footer-map,
        .footer-blog {
          padding-top: 0;
        }
      }
    }
    &.footer-light {
      .footer {
        margin-bottom: -1px;
        .row {
          > div {
            &:nth-child(4),
            &:last-child {
              margin-top: 30px;
            }
          }
        }
      }
      .footer-links {
        .footer-content {
          margin-top: 20px;
        }
      }
      .footer-details {
        .footer-contact {
          ul {
            li {
              + li {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
    &.footer-res {
      .footer {
        .row {
          > div {
            &:nth-child(4),
            &:last-child {
              margin-top: 30px;
            }
          }
        }
      }
      .footer-links {
        .footer-content {
          margin-top: 20px;
          margin-bottom: -5px;
        }
      }
    }
  }
  .footer-left-space {
    padding-left: 0;
  }
  .footer-bg {
    .footer-details {
      margin-bottom: 30px;
    }
    .bottom-blog {
      margin-left: 0;
    }
    .footer-links {
      .footer-content {
        margin-top: 20px;
      }
    }
  }
  .footer-custom-col {
    .row {
      > div {
        &:first-child,
        &.order-lg {
          order: -1;
        }
        &:nth-child(3),
        &:last-child {
          margin-top: 30px;
        }
      }
    }
  }
  .footer-light {
    .footer-left-space {
      padding-left: 0;
    }
    .row {
      margin-bottom: -5px;
    }
  }
  // brand page css
  .arrow-gradient {
    &.bg-light-blue {
      .slick-prev {
        margin-left: 48%;
      }
      .slick-next {
        margin-right: 48%;
      }
      .slick-arrow {
        top: -50px;
      }
    }
  }
  // blog css
  .blog-section {
    &.bg-comman-2 {
      &::before {
        height: 320px;
      }
    }
  }
  .blog-1 {
    .blog-box {
      .img-box {
        img {
          min-width: 221px;
          height: auto;
        }
      }
      .blog-content {
        padding: 20px;
        .btn {
          padding: 6px 18px;
          line-height: 1.3;
          font-size: 14px;
        }
      }
    }
  }
  .blog-2 {
    .slick-list {
      margin: 0 -15px;
      .slick-track {
        .slick-slide {
          > div {
            margin: 0 15px;
          }
        }
      }
    }
  }
  .blog-wrap {
    box-shadow: 0px 10px 20px rgba($black, 0.05);
    .blog-image {
      .blog-label {
        left: 20px;
        bottom: 20px;
      }
    }
    .blog-details {
      padding: 20px;
    }
  }
  // testimonial css
  .testimonial-1 {
    .pepole-comment {
      .client-msg {
        padding: 30px;
        .quote {
          top: -10px;
          left: 30px;
          img {
            width: 50%;
          }
        }
      }
      .media {
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
    .slick-list {
      .slick-track {
        .slick-slide {
          > div {
            margin-top: 10px !important;
          }
        }
      }
    }
  }
  .our-client {
    box-shadow: 0 0 20px rgba($black, 0.08);
    .client-right {
      padding: 30px 40px;
      p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .quote-img {
      right: -80px;
      bottom: -10px;
      img {
        width: 50%;
      }
    }
  }
  .client-slider {
    width: 80%;
    padding: 40px;
  }
  .modern-client {
    .comment-right {
      padding: 30px;
      left: 0px;
      .slick-arrow {
        top: 90.2%;
      }
      .slick-arrow {
        left: 30px;
        top: 98.2%;
      }
      .slick-next {
        left: 82px;
        top: 98.2%;
      }
    }
    .right-align {
      top: 30px;
    }
  }

  .testimonial-3 {
    .slick-list {
      margin: -40px -20px;
      .slick-track {
        .slick-slide {
          > div {
            margin: 40px 20px;
          }
        }
      }
    }
  }
  // full banner css
  .banner-1 {
    padding: 40px;
    h2 {
      margin: 36px 0 34px;
      line-height: 1.55;
      letter-spacing: 1px;
    }
  }
  .banner-3 {
    padding: 40px;
    h2,
    p {
      width: 100%;
    }
  }
  .banner-2 {
    .banner-right {
      h2 {
        line-height: 1.35;
      }
      h6 {
        margin-bottom: 10px;
      }
      p {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
    }
  }
  // about css
  .about-section {
    .title-3 {
      margin-top: -12px;
    }
    .about-wrap {
      &.about-1 {
        .slick-list {
          margin-bottom: -25px;
          .slick-track {
            .slick-slide {
              margin-bottom: 0;
              > div {
                margin-bottom: 25px;
              }
            }
          }
        }
        .about-content {
          .our-details {
            padding: 30px;
            width: 80%;
            margin: -70px auto 0;
          }
        }
      }
    }
  }
  .about-box {
    .agent-image {
      .overlay-agent {
        .agent-details {
          padding: 25px;
        }
      }
    }
  }
  .arrow-white {
    .slick-arrow {
      bottom: -85px;
    }
  }
  .arrow-gradient {
    &.arrow-right {
      .slick-arrow {
        top: -74px;
      }
    }
  }
  // service css
  .service-section {
    &.service-bg {
      &::after {
        height: 190px;
      }
    }
  }
  .service-slider {
    &.arrow-gradient {
      &.arrow-right {
        .slick-prev {
          right: 15%;
        }
        .slick-next {
          right: 10%;
        }
        .slick-arrow {
          top: -47px;
        }
      }
    }
    .service-wrapper {
      .service-details {
        padding: 40px 30px 30px;
      }
      .top-img-box {
        margin-left: 30px;
      }
    }
    .slick-list {
      margin-left: -12px;
      margin-right: -12px;
      .slick-track {
        .slick-slide {
          > div {
            margin-left: 12px;
            margin-right: 12px;
          }
        }
      }
    }
  }
  // property css
  .property-section {
    .list-property {
      .property-box {
        box-shadow: 0 0 20px rgba($black, 0.05);
        .property-details {
          .icon-property {
            li {
              + li {
                &::before {
                  left: -22px;
                }
              }
            }
          }
        }
        .property-image {
          .overlay-property {
            .overlay-box {
              height: calc(100% - 95px);
            }
          }
        }
      }
    }
    .property-box {
      .property-details {
        .icon-property {
          li {
            + li {
              &::before {
                left: -24px;
              }
            }
          }
        }
      }
    }
  }
  .property-grid-2 {
    .property-details {
      h3 {
        font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
      }
      ul {
        li {
          padding-left: 9px;
          padding-right: 5px;
          &:first-child {
            padding-left: 0;
          }
        }
      }
      .property-btn {
        .btn {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 100%;
          }
        }
      }
    }
  }
  .property-grid-3 {
    .property-grid {
      &.list-view {
        .property-box {
          .agent-image {
            width: 85%;
          }
        }
      }
    }
  }
  .property-map {
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 40%;
          }
        }
      }
    }
  }
  .property-list-view {
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 70%;
          }
        }
      }
    }
  }
  // single property details
  .gallery-nav {
    .slick-slide {
      img {
        min-height: 53px;
      }
    }
  }
  .single-property {
    .description-section {
      .menu-top {
        li {
          a {
            padding: 15px 7px;
          }
        }
      }
    }
  }
  .modal-form {
    position: relative;
    top: unset;
    right: unset;
    transform: unset;
    margin-bottom: 30px;
  }
  .single-property-images {
    &.ratio_40 {
      .bg-size {
        &:before {
          padding-top: 50%;
        }
      }
    }
  }
  // 360 view
  .line-tab {
    &.nav-tabs {
      .nav-link {
        padding: 15px;
      }
    }
  }
  // feature css
  .bg-comman-2 {
    &::before {
      height: 320px;
    }
  }
  .feature-section {
    .feature-content {
      .detail-list {
        li {
          .d-flex {
            h6 {
              margin-left: 8px;
              font-size: 15px;
            }
          }
        }
      }
    }
    .feature-1 {
      .feature-wrapper {
        .feature-image {
          .label-white {
            top: 30px;
            right: 30px;
          }
          .signature,
          .box-color {
            transform: scale(0.8);
            right: -25px;
          }
          .box-color {
            right: -10px;
          }
        }
        .property-details {
          margin-right: -250px;
          p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .feature-image {
      .bg-size {
        &:before {
          padding-top: 117%;
        }
      }
    }
    .feature-2 {
      .slick-dots {
        margin-top: 0;
      }
    }
    .modern-feature {
      .feature-wrap {
        .feature-image {
          .bg-size {
            &:before {
              padding-top: 85%;
            }
          }
        }
        .feature-content {
          padding-left: 10px;
          .details,
          .detail-list,
          .feature-price {
            padding: 30px 0;
          }
        }
      }
    }
  }
  // other page css
  .my-gallery {
    .row {
      > div {
        .row {
          > div {
            &:first-child,
            &:nth-child(3),
            &:nth-child(4) {
              order: -1;
            }
          }
        }
      }
    }
    .find-cities {
      height: 360px;
      .citi-overlay {
        h6 {
          &::after {
            right: -28px;
          }
        }
      }
    }
  }
  .icon-video {
    transform: scale(0.9);
  }
  .subscribe-section {
    .video-details {
      max-width: 730px;
    }
  }
  // portfolio creative 1
  .portfolio-creative {
    .content-sec {
      p {
        max-width: 100%;
      }
    }
    &.odd-even {
      .row {
        .content-sec {
          padding-left: 40px;
        }
        &:nth-child(even) {
          .content-sec {
            padding-right: 40px;
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .property-section {
    .property-wrapper-grid.list-view {
      .property-box {
        .property-details {
          p {
            display: none;
          }
        }
      }
    }
  }
  .property-section {
    .property-box {
      .property-details {
        > ul {
          > li {
            font-size: 12px;
            padding-left: 5px;
            img {
              height: 14px;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  .property-wrapper-grid.list-view {
    .property-box {
      .property-image {
        .bg-size {
          &::before {
            padding-top: 80%;
          }
        }
      }
    }
  }
  .blog-section {
    .blog-1 {
      .slick-prev {
        left: unset;
        top: 0;
        right: 25px;
      }
      .slick-next {
        right: 0;
        top: 0;
        left: unset;
      }
    }
  }
  .property-section {
    .property-wrapper-grid {
      .property-box {
        .property-image {
          .overlay-property-box {
            .effect-round {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
  // user dashboard css
  .user-dashboard {
    .sidebar-user {
      .user-profile {
        .media {
          display: block;
          text-align: center;
          .change-pic {
            .change-hover {
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
          .media-body {
            margin-top: 15px;
            margin-left: 0;
            h5 {
              margin-bottom: 6px;
            }
            h6 {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  .blog-section.bg-comman-2 .owl-theme .owl-nav {
    .owl-prev {
      left: unset;
      top: -23px;
      right: 52px;
    }
    .owl-next {
      right: 24px;
      top: -23px;
      left: unset;
    }
  }
}
@media (max-width: 1000px) {
  .about-section .about-1 .owl-carousel .owl-next {
    right: 44%;
  }
  .about-section .about-1 .owl-carousel .owl-prev {
    left: 44%;
  }
}
@media (max-width: 991px) {
  .home-section{
    .home-slider-1{
      .owl-carousel{
        .owl-prev, .owl-next{
          width: 40px;
          height: 40px;
          .fa{
            margin-top: 10px;
          }
        }
      }
    }
  }
  .agent-property{
    .theme-pagination{
      margin-bottom: 10px;
    }
  }
  .breadcrumb-section.single-property-images{
    height: auto;
  }
  .compare-page {
    .table-wrapper {
      .table {
        .property-name {
          min-width: 180px;
        }
      }
    }
  }
  .property-grid-2 .property-wrapper-grid .property-grid.list-view .property-box .property-image {
    width: 100%;
  }
  .blog-section.bg-comman-2 .blog-box {
    padding: 0;
    padding-left: 12px;
  }
  .user-about {
    .map-image  {
      width: 100% !important;
    }
  }
  .home-section {
    &.layout-1 {
      .owl-carousel {
        .owl-stage-outer .owl-stage .owl-item {
          .bg-size {
            height: 600px;
          }
        }
      }
    }
  }
  .about-section {
    .about-2 {
      .owl-theme  {
        .owl-item {
            .ng-star-inserted{
              margin: 0 12px !important;
            }
        }
      }
    }
  }
  .property-section .property-4 .owl-carousel .owl-nav {
    [class*=owl-] {
      width: 32px;
      height: 32px;
      top: -106px;
    }
    .owl-prev {
      right: 45px;
    }
    .fa {
      margin-top: 5px;
    }
  }
  .feature-section .feature-4 .owl-carousel .owl-nav {
    [class*=owl-] {
      width: 32px;
      height: 32px;
    }
    .owl-prev {
      right: 6%;
    }
    .fa {
      margin-top: 5px;
    }
  }
  .service-section .service-slider .owl-carousel .owl-nav {
    [class*=owl-] {
      width: 32px;
      height: 32px;
      top: -106px;
    }
    .fa {
      margin-top: 5px;
    }
  }
  .about-section .about-3 .owl-nav {
    [class*=owl-] {
      width: 32px;
      height: 32px;
      top: -106px;
    }
    .fa {
      margin-top: 5px;
      font-size: 15px;
    }
  }
  .testimonial-client .owl-carousel .owl-nav {
    [class*=owl-] {
      width: 32px;
      height: 32px;
      top: 35%;
    }
    .fa {
      margin-top: 5px;
    }
  }
  .small-section .slide-3 .owl-theme .owl-nav {
    [class*=owl-] {
      width: 32px;
      height: 32px;
      top: -64px;
    }
    .owl-prev {
      margin-left: 47%;
    }
    .owl-next {
      margin-right: 47%;
    }
    .fa {
      margin-top: 5px;
    }
  }
  .testimonial-style-2 .testimonial-3 .owl-carousel .owl-dots {
    margin-top: 30px !important;
  }
  .blog-section.bg-comman-2 .owl-theme .owl-nav {
    .owl-prev {
      top: 6px;
      right: 55px;
      left: unset;
    }
    .owl-next {
      top: 6px;
      right: 32px;
    }
  }
  .feature-section {
    .feature-3 {
      .slick-list {
        .slick-track {
          .slick-slide {
            > div {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  .home-section .home-slider-1 .owl-carousel {
    .owl-nav {
      display: block;
      .owl-prev  {
        top: -20px !important;
        right: 50px;
        left: unset;
      }
      .owl-next {
        top: -20px !important;
        right: 0;
        left: unset;
      }
    }
  }
  .layout-home3 .home-slider-3 .owl-nav {
    .owl-prev {
      left: 15px;
    }
    .owl-next {
      right: 15px;
    }
  }
  .about-section .about-1 .owl-carousel  {
    .owl-prev {
      left: 42%;
    }
    .owl-next {
      right: 42%;
    }
  }
  .layout-map.vertical-map {
    min-height: unset;
  }
  h2 {
    font-size: 26px;
    line-height: 1.2;
  }
  h3 {
    font-size: 21px;
  }
  h4 {
    font-size: 19px;
  }
  h6 {
    font-size: 15px;
  }
  section {
    padding: 70px 0;
  }
  .loader-text {
    h3 {
      margin-top: -20px;
    }
  }
  .banner-2 {
    .banner-right {
      .button-banner {
        .btn.btn-gradient {
          line-height: 2;
        }
      }
    }
  }
  .single-property.mt-0.pt-0 {
    padding-bottom: 40px;
  }
  .blog-section {
    .blog-1 {
      .slick-prev {
        top: 45px;
        right: 35px;
        left: unset;
      }
      .slick-next {
        top: 45px;
        right: 10px;
      }
    }
  }
  .video-section {
    .play-icon {
      background-color: rgba($white, 0.5);
    }
  }
  .user-dashboard {
    &.small-section {
      .sidebar-user {
        .dashboard-list {
          padding-top: 15px;
        }
      }
    }
  }
  .about-section {
    .about-wrap.about-1 {
      .slick-list {
        .slick-track {
          .slick-slide {
            > div {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .property-section {
    .property-box {
      .property-image {
        .property-slider {
          .slick-dots {
            li {
              button {
                &::before {
                  font-size: 10px;
                  width: 18px;
                  height: 18px;
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
  .video-layout.parallax-home {
    height: 475px;
  }
  .top-bar {
    .top-content {
      .top-bar-right {
        li {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
  .horizontal-blog {
    .blog-1 {
      .blog-box {
        .blog-content {
          a.btn-lg {
            padding: 10px 20px;
          }
        }
      }
    }
  }
  .blog-section {
    &.bg-comman-2 {
      .blog-1 {
        .blog-box {
          .blog-content {
            a.btn-lg {
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
  .banner-2 .banner-right .button-banner .btn {
    height: 50px;
    min-height: 40px;
  }
  .banner-section.parallax-image {
    .banner-2 {
      .banner-right {
        .button-banner {
          min-height: 40px;
          .btn:after {
            height: 50px;
          }
          .btn-light-bg {
            margin-left: 0;
          }
        }
      }
    }
  }
  .title-2 {
    h2 {
      margin-top: -4px;
      margin-bottom: 10px;
    }
  }
  .fnc-slide__action-btn {
    padding: 14px 20px;
    &::before {
      height: 48px;
      width: 48px;
    }
  }
  .property-section {
    .ratio_landscape {
      .title-3.text-start {
        &.d-flex {
          display: block !important;
          ul.nav-tabs {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .property-grid-2 {
    .property-wrapper-grid {
      .property-grid.list-view {
        .property-box {
          .property-details {
            ul {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .property-section {
    .property-box {
      .property-image {
        .overlay-property-box {
          .effect-round {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .video-layout {
    min-height: 110vh;
    .video-details {
      .left-sidebar {
        position: unset;
        height: auto;
      }
    }
  }
  .theme-title {
    .theme-card {
      .card-header {
        h4 {
          margin-top: -2px;
          margin-bottom: -1px;
        }
      }
    }
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
  }
  .small-section {
    padding: 40px 0;
  }
  .column-space {
    margin-bottom: -30px;
    > div {
      padding: 0 15px;
      margin-bottom: 30px;
    }
  }
  .order-class {
    order: 2;
  }
  .theme-card {
    padding: 20px;
    .card-header {
      h5 {
        margin-bottom: 0;
      }
    }
    + .theme-card {
      margin-top: 35px;
    }
  }
  .captcha {
    margin-bottom: 20px;
  }
  .simple-blog {
    .title-3 {
      margin-top: -11px;
    }
  }
  .contact-3 {
    .title-3 {
      margin-top: -4px;
    }
  }
  .login-wrap {
    .log-in {
      .title-3 {
        &.text-start {
          h2 {
            margin-top: -6px;
          }
        }
      }
    }
  }
  .layout-map {
    .withmap-horizontal {
      .search-panel {
        .filter {
          flex-direction: column;
          align-items: flex-start;
          padding: 20px 25px;
          position: relative;
          .media {
            .icon-square {
              display: none;
            }
            .media-body {
              margin-left: 0;
              max-width: unset;
              h4 {
                display: none;
              }
            }
            .dropdown-icon {
              margin-top: 0;
            }
          }
          + .filter {
            padding-top: 0;
          }
          .form-group {
            .form-control {
              border-radius: 0;
            }
          }
          .media {
            .icon-square {
              display: flex;
            }
            .media-body {
              margin-left: 15px;
              h4 {
                display: block;
              }
            }
          }
          .btn {
            margin-top: 20px;
            padding-top: 8px;
            padding-bottom: 7px;
          }
        }
        .width-fit {
          > div {
            max-width: 100% !important;
            flex: 0 0 100%;
            + div {
              border-left: none;
            }
          }
        }
      }
    }
  }
  .play-bg-image {
    .icon-video {
      a {
        &:after {
          top: -42px;
          left: -41px;
          width: 120px;
          height: 120px;
        }
      }
    }
  }
  .new-property {
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
    .feature-section {
      .feature-content {
        .feature-price {
          h6 {
            margin-bottom: -4px;
          }
        }
      }
    }
  }
  .blog-sidebar,
  .single-sidebar {
    .filter-cards {
      .advance-card {
        .recent-property {
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              width: 50%;
              &:nth-child(2) {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
  .inner-title {
    &.title-3 {
      h2 {
        margin-top: 1px;
      }
    }
  }
  .video-layout {
    .video-details {
      position: relative;
      z-index: 1;
      padding-top: 0;
      padding-right: 30px;
    }
    .play-bg {
      border-width: 10px;
      &::after {
        z-index: 0;
      }
    }
  }

  .logout-modal {
    .modal-body {
      p {
        font-size: 14px;
      }
    }
  }

  .my-profile {
    .profile-info {
      .information-detail {
        + .information-detail {
          padding-top: 20px;
        }
      }
      .information {
        ul {
          li {
            + li {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .card-payment {
    > div:nth-child(n + 3) {
      .payment-card {
        margin-top: 20px;
      }
    }
  }

  .about-testimonial {
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
  }

  .why-choose,
  .blog-inner {
    .title-2 {
      h2 {
        margin-top: -5px;
      }
    }
  }

  .blog-2 {
    margin-bottom: -35px;
    &.slick-initialized {
      .slick-slide {
        margin-bottom: -6px;
      }
    }
  }

  .about-people {
    .title-2 {
      h2 {
        margin-top: -5px;
      }
    }
  }

  .property-map {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -4px;
        }
      }
    }
    .filter-bottom-content {
      z-index: 3;
    }
  }

  .property-section {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -4px;
        }
      }
    }
  }

  .terms-section {
    h2 {
      margin-bottom: 20px;
      margin-top: -4px;
    }
    .terms-wrap {
      margin-bottom: -4px;
    }
    .terms-wrapper {
      p {
        font-size: 15px;
      }
      + .terms-wrapper {
        margin-top: 20px;
      }
      ul {
        li {
          font-size: 15px;
        }
      }
    }
  }
  .terms-bottom-content {
    display: none;
  }

  // portfolio details css
  .portfolio-creative {
    .content-sec {
      h3 {
        margin-top: -4px;
      }
    }
    &.odd-even {
      .row {
        .content-sec {
          padding-left: 20px;
        }
        &:nth-child(even) {
          .content-sec {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
    }
  }
  .details-right {
    margin-top: 30px;
    h3 {
      margin-top: -4px;
    }
  }
  .project-details {
    margin-top: 30px;
  }
  .portfolio-details {
    .portfolio-section {
      margin-top: 10px;
    }
  }
  // portfolio css
  .portfolio-grid {
    .filter-panel {
      padding-bottom: 30px;
    }
    &.creative-3 {
      .row {
        > div {
          &:nth-child(6) {
            .overlay {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .grid-box {
    .property-text {
      padding: 20px;
    }
  }
  // brand css
  .brand-slider {
    &.slide-2 {
      .logo-box {
        img {
          width: 70px;
        }
      }
    }
  }
  // login css
  .log-in {
    form {
      .form-group {
        margin-bottom: 20px;
      }
      .d-flex {
        margin-bottom: 20px;
      }
    }
  }
  .sign-up {
    .title-3 {
      &.text-start {
        h2 {
          margin-top: 0;
        }
      }
    }
  }
  // tab layout css
  .left-tablayout {
    &.single-sidebar{
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
  // user dashboard css
  .right-line-tab {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: -5px 0;
    .nav-item {
      display: inline-block;
      margin: 3px 0;
      .nav-link {
        border-right: none;
        border-bottom: 2px solid transparent;
        padding: 6px 15px;
        &.active {
          border-left: none;
          border-bottom-color: var(--theme-default3);
        }
      }
    }
  }
  .user-dashboard {
    .sidebar-user {
      margin-bottom: 25px;
      .user-profile {
        display: flex;
        .media {
          margin-bottom: 0;
        }
        .connected-social {
          margin-left: 30px;
        }
      }
    }
  }
  // services page css
  .provide-service {
    .provide-details {
      margin-top: 30px;
      text-align: center;
      p {
        margin-bottom: 20px;
      }
    }
  }
  // submit property css
  .property-wizard {
    .wizard-box {
      .wizard-step-container {
        .wizard-steps {
          flex-wrap: wrap;
          display: flex;
          margin: -5px;
          li {
            flex: 0 0 49%;
            max-width: 49%;
            padding: 5px;
            margin: 0;
          }
        }
      }
      .wizard-form-details {
        margin-top: 30px;
        &.col-xxl-9 {
          margin-top: 14px;
        }
        h2 {
          margin-top: -4px;
        }
        .complete-details {
          width: 100%;
        }
      }
    }
    .feature-checkbox {
      margin: -5px;
      label {
        margin: 5px;
      }
    }
    &.horizontal-wizard {
      .wizard-box {
        .wizard-step-container {
          .wizard-steps {
            li {
              padding: 0;
              &:nth-child(n + 3) {
                margin-top: 5px;
              }
            }
          }
          .log-in {
            margin-top: 40px;
          }
        }
      }
    }
  }
  .single-property-section {
    bottom: -180px;
    .single-title {
      display: block;
      .right-single {
        text-align: left;
        margin-top: 20px;
        .price {
          margin-top: 5px;
        }
      }
    }
    .left-single {
      h2 {
        margin-top: -5px;
      }
      ul {
        li {
          > div {
            img {
              width: 25px;
            }
          }
        }
      }
    }
  }
  .single-property {
    margin-top: 140px;
    .description-section {
      .desc-box {
        h4 {
          margin-top: -2px;
        }
        .feature-dec,
        .ratio3_2 {
          h4 {
            margin-top: -3px;
          }
        }
        p {
          font-size: 14px;
          line-height: 1.7;
        }
      }
      .about {
        p {
          &:last-child {
            margin-bottom: -3px;
          }
        }
      }
    }
  }
  // about us css
  .user-about {
    .about-content {
      max-width: 100%;
    }
    .map-image {
      margin-bottom: 30px;
      order: -1;
    }
    .about-image {
      margin-bottom: 30px;
      .side-left {
        .side-effect {
          &::before,
          &::after {
            width: calc(100% - 433px);
          }
        }
      }
      .img-box {
        &.img-abs {
          right: 28%;
        }
      }
    }
  }
  // faq page css
  .faq-section {
    .faq-image {
      margin-top: -36px;
      h3 {
        margin-top: 0;
      }
      img {
        width: 65%;
      }
    }
  }
  .faq-questions {
    margin-top: 30px;
  }
  // agent profile css
  .agent-section {
    .agent-wrap {
      padding-left: 20px;
    }
    .our-agent {
      .ratio_landscape {
        .bg-size {
          &::before {
            padding-top: 97%;
          }
        }
      }
    }
    .filter-panel {
      margin-top: -4px;
    }
    .about-agent {
      h3 {
        margin-top: -5px;
      }
    }
  }
  // contact us css
  .get-in-touch {
    .contact-img {
      margin-bottom: 20px;
      img {
        width: 60%;
      }
    }
  }
  .contact_bottom {
    .row {
      > div {
        &:last-child {
          margin-top: 30px;
        }
      }
    }
  }
  .contact_right {
    margin-top: 30px;
    .row {
      > div {
        + div {
          margin-top: 0;
          &:last-child {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .contact-bottom {
    height: auto;
  }
  .contact_section {
    .contact-content {
      h2 {
        margin-top: -3px;
      }
      margin-bottom: 20px;
    }
    .contact_wrap {
      padding: 20px;
    }
  }
  .contact-map {
    height: auto;
    margin-bottom: -5px;
  }
  .download-section {
    text-align: center;
    .download-wrap {
      margin-bottom: 20px;
      h3 {
        margin-top: -5px;
      }
    }
    .app-right {
      .form-group {
        width: calc(100% - 140px);
      }
    }
  }
  .contact-2 {
    .log-in {
      margin-bottom: 30px;
      h2 {
        margin-top: -5px;
      }
    }
  }
  // coming soon css
  .coming-soon {
    .coming-soon-detail {
      .timer {
        li {
          > div {
            width: 80px;
            height: 80px;
          }
          + li {
            margin-left: 15px;
            padding-left: 15px;
            &::before {
              top: 30%;
            }
          }
        }
      }
      form {
        input {
          padding: 10px 15px;
        }
      }
    }
    .cloud-img {
      height: 280px;
    }
    &.coming-left {
      .coming-soon-detail {
        .timer {
          li {
            padding: 15px 10px;
            margin: 0 15px;
          }
        }
      }
    }
  }
  .coming-simple {
    .title-3 {
      &.text-start {
        text-align: center !important;
        h2 {
          padding-bottom: 5px;
          &::before {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .row {
      text-align: center;
    }
    .ratio2_3 {
      > img {
        height: 480px;
      }
      &.order-lg-1 {
        margin-top: -56px;
      }
    }
  }
  // 404 page css
  .error-section {
    .not-found {
      p {
        max-width: 100%;
      }
      h2 {
        line-height: 1.3;
      }
    }
  }
  // login modal css
  .signup-modal {
    .ratio_asos {
      .bg-size {
        &::before {
          padding-top: 44%;
        }
      }
    }
    .signup-tab {
      padding: 30px;
    }
  }
  // map modal css
  .arrow-define {
    top: 63px;
  }
  .map-modal {
    .left-sidebar {
      display: none;
    }
    .modal-dialog{
      .modal-content{
        .map{
          height: 350px;
        }
      }
    }
    .map-section {
      padding-left: 12px;
      iframe,
      .map {
        margin-bottom: 0;
      }
    }
  }
  // home section css
  .left-sidebar {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    z-index: 9;
    height: 100vh;
    background-color: $white;
    overflow-y: auto;
    transition: all 0.5s ease;
    border-radius: 0;
  }
  .filter-bottom-content {
    display: block;
    > h6 {
      margin-bottom: 30px;
      &.text-end {
        font-weight: 500;
      }
    }
    &.left-sidebar {
      left: -300px ;
      &.open {
        left: 0 !important;
      }
    }
    &.open {
      left: -1px;
    }
  }
  .left-sidebar,
  .filter-bottom-content {
    .row {
      margin-bottom: 8px;
      > div {
        margin-bottom: 8px;
      }
    }
  }
  .filter-panel {
    margin-bottom: 30px;
    .grid-list-filter {
      li {
        > a,
        .dropdown-toggle {
          padding: 6px 10px;
        }
        .filter-bottom-title {
          padding: 8px 10px;
        }
      }
    }
    .d-lg-none {
      .mobile-filter {
        padding: 7px 10px;
      }
    }
  }
  .close-filter-bottom {
    color: var(--theme-default2) !important;
    &.color-7 {
      color: var(--theme-default10) !important;
    }
  }
  .fnc-slider {
    height: 600px;
    min-height: unset;
  }
  .fnc-slide__mask-inner {
    height: 740px;
    min-height: unset;
    margin-top: -400px;
  }
  .fnc-slide__mask {
    width: 300px;
    height: 400px;
    clip-path: polygon(
      0% 0%,
      0% 100%,
      16% 100%,
      16% 12%,
      85% 12%,
      85% 89%,
      16% 89%,
      16% 100%,
      100% 100%,
      100% 0%
    );
  }
  .fnc-slide__action-btn {
    font-size: 18px;
  }
  .fnc-slide__heading-line {
    font-size: 50px;
  }
  .fnc-slide__content {
    left: 30%;
  }
  .home-section {
    &.layout-6 {
      .container {
        > .row {
          padding: 70px 0;
        }
      }
      .home-main {
        padding-top: 0 !important;
      }
      .arrow-light {
        .slick-arrow {
          top: 0 !important;
          right: 0;
          left: unset;
          &.slick-prev {
            right: 50px !important;
          }
        }
      }
    }
    &.layout-6 {
      .home-slider-1 {
        margin-bottom: 70px;
      }
      .home-main {
        min-height: unset;
        height: auto;
        .home-content {
          > div {
            img {
              width: 150px;
            }
          }
        }
        .container {
          > .row {
            padding-top: 120px;
            min-height: unset;
            height: auto;
            .container {
              padding: 0;
            }
          }
        }
        .looking-icons {
          display: none;
        }
      }
    }
    &.layout-9 {
      padding: 70px 0;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-image: url(../../images/layout-9.png);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        background-color: rgba(248, 249, 250, 0.96);
        background-blend-mode: overlay;
      }
      .home-main {
        .home-content {
          > div {
            > img {
              width: 160px;
            }
          }
          h1 {
            height: auto;
          }
        }
      }
    }
  }
  .looking-icons {
    h5 {
      margin-bottom: 20px;
    }
  }
  .layout-home8 {
    &::after {
      height: calc(45px + (105 - 45) * ((100vw - 320px) / (991 - 320)));
    }
    .home-content {
      h1 {
        margin-top: -5px !important;
      }
    }
  }
  .layout-9 {
    .layout-right-img {
      display: none;
    }
  }
  .search-with-tab {
    .tab-content {
      .review-form {
        > div {
          &:nth-child(n + 5) {
            margin-top: 16px;
          }
        }
      }
    }
  }
  .top-bar {
    .top-content {
      .top-bar-right {
        text-align: right;
        li {
          + li {
            margin-left: 10px;
          }
          .flat-box {
            padding: 8px 15px;
          }
        }
        .input-group {
          input,
          .input-group-text {
            margin: 8px 0;
            padding: 0 15px;
            &.dropdown {
              display: none;
            }
          }
        }
      }
    }
    &.top-bar-7 {
      .top-content {
        .top-bar-right {
          li {
            &:last-child,
            + li {
              margin-left: 6px;
            }
            > a {
              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
          > li {
            > a {
              padding: 9px 10px 10px;
            }
          }
        }
      }
    }
  }
  .feature-3 {
    .next-image {
      top: 377px;
    }
    .feature-content {
      .detail-list {
        width: 100%;
      }
    }
  }
  .layout-home2 {
    .home-main {
      .feature-content {
        margin: 70px auto 70px 30px;
      }
    }
  }
  .layout-home3 {
    .slick-slider {
      .slick-prev {
        left: 15px;
      }
      .slick-next {
        right: 15px;
      }
    }
    .bg-layout-3 {
      padding: 70px 0;
    }
    .banner-3 {
      width: 67%;
    }
  }
  .layout-home4 {
    .sync-slider {
      .home-slider-4 {
        &.arrow-image {
          .owl-prev, .owl-next {
            width: 40px;
            height: 40px;
            line-height: 38px;
            i{
              &::before {
                font-size: 16px;
              }
            }
            &::after {
              width: 140px;
              height: 140px;
            }
          }
        }
      }
      .home-nav {
        .slider-image {
          width: 95px;
          height: 65px;
        }
        .slick-list {
          margin-left: -10px;
          margin-right: -10px;
          .slick-track {
            .slick-slide {
              > div {
                margin-left: 10px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .slider-image {
      .home-content {
        h1 {
          margin-top: 20px;
        }
        p {
          width: 65%;
        }
        .font-color4 {
          &::after {
            top: -4px;
          }
        }
      }
    }
    .home-slider-4 {
      .slider-image {
        padding: 70px 0;
      }
    }
  }
  .parallax-home {
    .parallax-content {
      z-index: 1;
    }
    .parallax-right {
      width: 65%;
    }
    &.video-layout {
      .video-search {
        width: 125%;
      }
    }
  }
  .toggle-right {
    svg {
      vertical-align: middle;
    }
  }
  .vertical-search {
    .left-sidebar {
      position: relative;
      left: unset !important;
      top: unset;
      height: auto;
      width: auto;
      z-index: unset;
      border-radius: 8px;
    }
  }
  header {
    &.light-header {
      .menu {
        .brand-logo {
          img {
            width: 130px;
          }
        }
      }
    }
    &.header-4 {
      .menu {
        .header-right {
          li {
            .search-box {
              position: relative;
              i {
                padding: 10px;
              }
              .form-control {
                left: 34px;
              }
              .form-group {
                &::after {
                  left: 34px;
                }
              }
              &.open {
                .form-control {
                  width: 151px;
                }
                .form-group {
                  &::after,
                  &::before {
                    width: 153px;
                  }
                }
              }
            }
            + li {
              margin-left: 10px;
            }
            > a {
              padding: 10px;
            }
          }
        }
        .brand-logo {
          img {
            width: 120px;
          }
        }
      }
    }
  }
  .animate-rdi {
    position: absolute;
    opacity: 0;
    transition: 0.5s;
    top: 0;
    right: 12px;
    border-radius: 5px;
    margin-right: 0;
    .col {
      display: block;
    }
    label {
      margin-right: 0;
      padding: 10px;
      border-radius: 0;
      text-align: left;
      display: block;
      width: 145px;
      background-color: rgba($black, 0.8);
      padding: 10px 15px;
      + label {
        padding-top: 0;
      }
    }
    &.show {
      top: 3px;
      opacity: 1;
      transition: 0.5s;
    }
  }
  .icon-video {
    a {
      width: 40px;
      height: 40px;
      i {
        margin-top: 0px;
        margin-left: 3px;
        font-size: 18px;
      }
    }
    .heart-animation {
      top: -20px;
      right: -20px;
      width: 80px;
      height: 80px;
    }
  }
  // layout 12 css
  .layout-map {
    .withmap-horizontal {
      .search-panel {
        margin-top: 0;
        .filter {
          flex-direction: column;
          align-items: flex-start;
          .media {
            .icon-square {
              display: flex;
            }
            .media-body {
              margin-left: 15px;
              h4 {
                display: block;
              }
            }
          }
          .btn {
            margin-top: 20px;
            padding-top: 8px;
            padding-bottom: 7px;
          }
        }
      }
    }
  }
  // single property
  .single-sidebar {
    position: relative;
    width: auto;
    left: unset;
    overflow: unset;
    height: auto;
    z-index: unset;
    margin-top: 30px;
  }
  .single-property-images {
    &.ratio_40 {
      .bg-size {
        &:before {
          padding-top: 55%;
        }
      }
    }
  }
  .arrow-image {
    &.main-property-slider {
      .slick-arrow {
        width: 30px;
        height: 30px;
        &::before {
          font-size: 14px;
        }
        &::after {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  // 360 view page
  .home-view {
    height: 60vh;
  }
  // button css
  .btn {
    &.btn-lg {
      padding: 10px 20px;
    }
  }
  // footer css
  footer {
    .footer {
      padding: 70px 0;
    }
  }
  .footer-bg {
    .footer-links {
      .footer-content {
        .input-group {
          margin-top: 30px;
        }
      }
    }
  }
  // title css
  .title-1 {
    padding-bottom: 30px;
  }
  .title-2 {
    p {
      margin-bottom: 30px;
    }
  }
  .title-3 {
    margin-bottom: 30px;
  }
  // breadcrumb css
  .breadcrumb-section {
    .breadcrumb-content {
      div {
        padding: 30px 35px;
        margin: 0 auto;
        text-align: center;
        h2 {
          margin-bottom: 10px;
        }
      }
    }
    &.breadcrumb-sm {
      .breadcrumb-content {
        > div {
          padding-top: 40px;
          padding-bottom: 40px;
          h2 {
            line-height: 1.1;
            margin-top: 0;
          }
        }
      }
    }
  }
  // brand page css
  .arrow-gradient {
    &.bg-light-blue {
      .slick-prev {
        margin-left: 47%;
      }
      .slick-next {
        margin-right: 47%;
      }
      .slick-arrow {
        top: -40px;
      }
    }
  }
  // blog css
  .blog-1 {
    .blog-box {
      .blog-content {
        padding: 30px;
      }
      .img-box {
        img {
          min-width: 240px;
        }
      }
    }
  }
  .blog-wrap {
    .blog-image {
      .blog-label {
        width: 50px;
        height: 50px;
      }
    }
    .blog-details {
      padding: 25px;
      h3 {
        margin-top: 10px;
        line-height: 1.5;
      }
      p {
        margin-bottom: 10px;
      }
    }
  }
  .blog-grid,
  .blog-list {
    margin-bottom: -30px;
    .blog-wrap {
      margin-bottom: 30px;
    }
  }

  // testimonial css
  .client-slider {
    margin-top: 54px;
    padding: 30px;
    .user-list {
      transform: scale(0.8);
    }
    h6 {
      margin-bottom: 16px;
    }
    .client-rating {
      padding-bottom: 20px;
    }
    p {
      margin-top: 60px;
      font-size: 15px;
    }
  }
  .our-client {
    .client-right {
      padding: 16px 40px;
      p {
        -webkit-line-clamp: 3;
        margin-bottom: 10px;
      }
      .client-rating {
        margin-bottom: 10px;
      }
      h2 {
        font-size: 20px;
      }
    }
    .quote-img {
      display: none;
    }
  }
  .modern-client {
    justify-content: center;
    .img-testimonial {
      width: 40%;
      margin: 0 auto;
      .img-left {
        img {
          filter: none;
        }
      }
    }
    .right-align,
    .bottom-align {
      display: none;
    }
    .comment-right {
      left: 0;
      bottom: -16px;
      margin: 0 auto;
      .slick-arrow {
        top: -36px;
        transform: scale(0.8);
      }
      .slick-prev {
        left: 46.8%;
      }
      .slick-next {
        left: 55%;
        border-radius: 4px;
      }
    }
  }
  .modern-dot {
    .slick-dots {
      margin-top: 30px;
    }
    &.slick-dotted {
      margin-bottom: 2px;
    }
  }
  .testimonial-client {
    .title-3 {
      &.text-start {
        margin-top: 1px;
      }
    }
  }
  // full banner css
  .banner-section {
    .button-banner {
      .btn {
        + .btn {
          margin-left: 10px;
        }
      }
    }
  }
  .banner-inner {
    padding-top: 149px;
  }
  .banner-1,
  .banner-3 {
    width: 100%;
    padding: 30px;
  }
  .banner-1 {
    h6,
    h2 {
      margin-top: 20px;
    }
  }
  .banner-2 {
    position: relative;
    &:after {
      background-image: url(../../images/mobile-bg.png);
      position: absolute;
      content: "";
      width: 42%;
      background-position: right;
      background-size: cover;
      height: 100%;
      top: 2px;
    }
    .banner-right {
      h6 {
        margin-top: -4px;
      }
      .button-banner {
        .btn {
          line-height: 1.8;
        }
        .btn-light-bg {
          height: 48px;
          &::after {
            height: 53px;
          }
          span {
            vertical-align: -3px;
          }
        }
      }
    }
  }
  // about css
  .arrow-gradient {
    .slick-arrow {
      width: 32px;
      height: 32px;
      &::before {
        font-size: 14px;
      }
    }
    &.arrow-right {
      .slick-arrow {
        top: -85px;
      }
      .slick-prev {
        right: 50px;
      }
    }
  }
  .arrow-white {
    .slick-prev {
      left: 41%;
    }
    .slick-next {
      right: 41%;
    }
  }
  .about-section {
    .about-wrap {
      .about-content {
        .our-details {
          padding: 30px;
          h5 {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &.about-1 {
        .about-content {
          .our-details {
            padding: 20px;
          }
        }
      }
    }
    .title-3 {
      &.text-start {
        margin-top: -6px;
      }
    }
  }
  // service css
  .service-section {
    &.service-bg {
      .title-3 {
        margin-top: -4px;
      }
    }
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
    &.service-2 {
      .property-service {
        > div {
          .service-box {
            .hover-line {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .service-slider {
    .service-wrapper {
      .top-img-box {
        > div {
          width: 56px;
          height: 56px;
          img {
            width: 50%;
          }
        }
      }
    }
    &.arrow-gradient {
      &.arrow-right {
        .slick-arrow {
          top: -60px;
        }
        .slick-prev {
          right: 20.5%;
        }
        .slick-next {
          right: 15%;
        }
      }
    }
  }
  // property css
  .property-section {
    .close-filter-bottom {
      color: var(--theme-default3) !important;
    }
    .property-box {
      .property-details {
        .icon-property {
          li {
            + li {
              &::before {
                left: -38px;
              }
            }
          }
        }
      }
    }
    .nav-tabs {
      margin-bottom: 15px;
      .nav-item {
        + .nav-item {
          margin-left: 20px;
        }
      }
    }
    .list-property {
      .property-box {
        .property-image {
          .bg-size {
            &:before {
              padding-top: 50%;
            }
          }
          .overlay-property {
            .overlay-box {
              height: 72%;
              p {
                width: 80%;
              }
            }
          }
        }
        .property-details {
          .icon-property {
            li {
              + li {
                &::before {
                  left: -82px;
                }
              }
            }
          }
        }
      }
      &.no-slider-property {
        .property-box {
          margin: 0;
        }
      }
    }
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
    .title-3 {
      &.text-start {
        h2 {
          margin-top: -4px;
        }
      }
    }
    &.bg-comman-2 {
      &::before {
        height: 355px;
      }
    }
  }
  .pagination-tab {
    &.nav-tabs {
      margin-bottom: 0;
      .nav-item {
        .nav-link {
          padding: 5px 13px 4px;
          display: block;
        }
        + .nav-item {
          margin-left: 15px;
        }
      }
    }
  }
  .no-slider-property {
    .property-box {
      margin: 0 50px;
      .property-details {
        .icon-property {
          li {
            + li {
              &::before {
                left: -52px;
              }
            }
          }
        }
      }
    }
  }
  .property-grid-3 {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -4px;
        }
      }
      .grid-list-filter {
        margin-top: 15px;
      }
    }
    .filter-bottom-content {
      .btn {
        width: 100%;
      }
    }
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 90%;
          }
        }
      }
    }
  }
  .property-grid-slider {
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 40%;
          }
        }
      }
      .slider-nav {
        .slick-slide {
          img {
            min-height: 55px;
          }
        }
      }
    }
  }
  .property-map {
    .filter-bottom-content {
      width: 300px;
      margin: 0;
      right: unset;
      .btn {
        width: 100%;
      }
    }
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 65%;
          }
        }
      }
    }
  }
  .filter-panel {
    .filters {
      ul {
        li {
          padding: 12px;
          min-width: 110px;
          i {
            font-size: 25px;
          }
        }
      }
    }
    &.tab-top-panel {
      .grid-list-filter {
        li {
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .property-list-view {
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 85%;
          }
        }
      }
    }
  }
  // feature css
  .bg-comman-2 {
    &::before {
      height: 285px;
    }
  }
  .feature-section {
    .feature-content {
      .feature-price {
        li {
          h3 {
            margin-top: -2px;
          }
        }
      }
    }
    .modern-feature {
      .feature-wrap {
        .feature-image {
          box-shadow: 0px 0 16px 5px rgba($black, 0.05);
          .bg-size {
            &:before {
              padding-top: 70%;
            }
          }
        }
        .feature-content {
          padding-left: 0;
          margin-top: 30px;
        }
      }
      &.arrow-gradient {
        .slick-prev {
          right: 7% !important;
        }
      }
      .slick-list {
        .slick-track {
          .slick-slide {
            > div {
              margin-top: 50px;
            }
          }
        }
      }
    }
    .feature-3 {
      &.slick-initialized {
        .slick-slide {
          margin-bottom: 0;
        }
      }
    }
    .classic-feature {
      .feature-content {
        .feature-price {
          li {
            h6 {
              margin-bottom: -4px;
            }
          }
        }
      }
    }
    &.bg-half {
      background: $light-blue;
      &::before {
        display: none;
      }
    }
    .feature-image {
      .bg-size {
        &:before {
          padding-top: 70%;
        }
      }
    }
    .feature-1 {
      .slick-list {
        margin-bottom: -15px;
        .slick-track {
          .slick-slide {
            margin-bottom: 0;
            > div {
              margin-bottom: 15px;
            }
          }
        }
      }
      .feature-wrapper {
        position: relative;
        .row {
          > div {
            &.order-md {
              order: -1;
            }
          }
        }
        .feature-image {
          height: 385px;
          justify-content: center;
          .signature,
          .box-color {
            transform: scale(0.6);
            right: -47px;
          }
          .box-color {
            right: -20px;
          }
        }
        .property-details {
          margin: -40px auto 0;
        }
      }
    }
    .feature-4 {
      margin-top: 46px;
      &.slick-initialized {
        .slick-slide {
          margin-bottom: -4px;
        }
      }
    }
  }
  .bg-comman-2 {
    &.feature-section {
      .title-2 {
        h2 {
          margin-top: -4px;
        }
      }
    }
  }
  // other page css
  .video-modal {
    iframe {
      height: 275px;
    }
  }
  .video-section {
    background-position: center right;
    .row {
      > div {
        &:last-child {
          order: -1;
        }
      }
    }
    .play-icon {
      height: 100px;
    }
  }
  .pricing-section {
    .pricing-box {
      padding: 20px;
      .pricing-details {
        img {
          transform: scale(0.9);
        }
        h3 {
          margin-top: 20px;
        }
        .pricing-svg {
          width: 40px;
          height: 40px;
        }
        .pricing-icon {
          &::after {
            width: 35px;
            height: 35px;
            transform: translateX(9px);
          }
        }
      }
      ul {
        margin: 30px 0 36px;
        li {
          + li {
            margin-top: 10px;
          }
        }
      }
    }
    &.pricing-inner {
      .title-2 {
        h2 {
          margin-top: -4px;
        }
      }
    }
  }
  .video-details {
    padding: 30px;
  }
  .my-gallery {
    .find-cities {
      height: 280px;
      .citi-overlay {
        h4 {
          margin-bottom: 10px;
        }
        h2 {
          margin-bottom: 15px;
        }
      }
    }
  }
  // portfolio creative
  .portfolio-creative {
    .content-sec {
      padding: 20px;
    }
    .row {
      display: block;
      + .row {
        padding-top: 30px;
      }
    }
    .order-section {
      div {
        &:nth-child(even) {
          margin-bottom: 30px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  // blog page
  .blog-grid,
  .blog-right,
  .blog-list {
    margin-top: 30px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .ratio_63 {
    .property-wrapper-grid.list-view {
      .property-box {
        .bg-size {
          &::before {
            padding-top: 100%;
          }
        }
      }
    }
  }
  .property-box-flat {
    .no-slider-property {
      .property-box {
        margin: 0 6px;
      }
    }
  }
  .property-section {
    .property-box {
      .property-details {
        .icon-property {
          li {
            + li {
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .banner-section.parallax-image .banner-2 .banner-right .button-banner .btn-light-bg {
    margin-left: 7px;
  }
  .property-section {
    .nav-tabs {
      .nav-item {
        .nav-link {
          background-color: rgba($white, 0.3);
          &.active {
            background-color: $white;
          }
        }
      }
    }
  }
  .filter-cards {
    app-contact-info {
        .advance-card {
            margin-top: 25px !important;
        }
    }
    app-recently-added {
        .advance-card {
            margin-top: 25px !important;
        }
    }
    .advance-card {
        margin-top: 25px;
    }
  }
  .owl-carousel {
    .owl-nav {
      display: none  ;
    }
  }
  .home-section {
    &.layout-1 {
      .owl-carousel {
        .owl-stage-outer .owl-stage .owl-item {
          .bg-size {
            height: 500px;
          }
        }
      }
    }
  }
  .about-section .about-1 .owl-carousel .owl-nav {
    display: block;
    .owl-prev {
      left: 39%;
      width: 40px;
      height: 40px;
      bottom: -79px;
    }
    .owl-next {
      right: 39%;
      width: 40px;
      height: 40px;
      bottom: -79px;
    }
  }
  .box.text-affect .role {
    margin-bottom: 20px;
  }

  .property-2 {
    &.list-view {
      &.no-sidebar {
        .property-box {
          .property-details {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .single-property.mt-0.pt-0 {
    padding-bottom: 30px;
  }
  .property-grid-2 {
    .property-wrapper-grid {
      .property-grid {
        &.list-view {
          .property-box {
            .property-image {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .blog-single-detail {
    .blog-title {
      h3 {
        margin-top: 10px;
      }
      .post-detail {
        li {
          line-height: 1.7;
        }
      }
    }
  }
  .footer-bg {
    .bottom-blog {
      .media {
        .media-body {
          h6 {
            line-height: 1.4;
          }
        }
      }
    }
  }
  footer {
    &.footer-brown {
      .footer-details {
        img {
          margin: 0 0 -16px;
        }
      }
    }
  }
  .horizontal-blog {
    .blog-1 {
      .slick-prev {
        top: 36px;
        right: 26px;
        left: unset;
      }
      .slick-next {
        top: 36px;
        right: 4px;
      }
    }
  }
  .service-section {
    .property-service {
      > div {
        .service-box {
          .icon-round {
            margin: 0 auto 20px 0;
          }
        }
      }
    }
  }
  .video-layout.parallax-home {
    height: 390px;
    .video-search {
      padding: 0;
    }
  }
  .banner-2 {
    .banner-right {
      .button-banner {
        .btn {
          + .btn {
            &:after {
              height: 55px;
            }
          }
        }
      }
    }
  }
  .blog-section {
    .blog-1 {
      .slick-prev {
        left: -3px;
      }
      .slick-next {
        right: -3px;
      }
    }
  }
  .property-section {
    .ratio_landscape {
      .title-3.text-start {
        &.d-flex {
          .pagination-tab.nav-tabs {
            justify-content: start;
          }
        }
      }
    }
  }
  .video-layout {
    .video-details {
      .left-sidebar {
        width: auto;
      }
    }
    .overlay-content {
      right: 0;
      left: 0;
      margin: 0 auto;
      top: 50%;
      transform: translate(0, -50%);
    }
    .video-search {
      width: 110%;
    }
  }
  .toggle-nav {
    i {
      font-size: 13px;
    }
  }
  .title-3 {
    margin-top: -11px;
    p {
      margin-bottom: 0;
    }
  }
  label {
    margin-bottom: 6px;
  }
  section {
    padding: 50px 0;
  }
  p {
    line-height: 1.4;
  }
  .small-section {
    padding: 30px 0;
  }
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 20px;
  }
  .modal-header {
    padding-bottom: 0;
    .btn-close {
      width: 25px;
      height: 25px;
      span {
        font-size: 26px;
      }
    }
  }
  .map-modal {
    .modal-dialog {
      .modal-content {
        .btn-close {
          width: 25px;
          height: 25px;
          span {
            font-size: 26px;
            line-height: 0.85;
          }
        }
      }
    }
  }
  .modern-dot {
    &.property-3 {
      &.slick-dotted {
        margin-bottom: 3px;
      }
    }
  }
  .btn {
    &.btn-gradient {
      &.color-4 {
        &::after {
          box-shadow: -5.15px 6.572px 13px var(--theme-default6);
        }
      }
    }
  }
  .provide-service {
    .provide-details {
      .btn {
        padding: 10px 20px 9px;
      }
    }
  }
  .simple-blog {
    .title-3 {
      margin-top: -10px;
    }
  }
  .testimonial-client {
    .title-3 {
      &.text-start {
        margin-bottom: 27px;
      }
    }
  }
  .video-layout {
    padding-bottom: 20px;
    .video-search {
      > div {
        padding: 10px;
      }
      .dropdown {
        .dropdown-menu {
          width: 130px;
        }
      }
      .input-group {
        .input-group-text {
          width: 35px;
          height: 35px;
          padding: 0;
          svg {
            width: 17px;
            height: 17px;
          }
        }
      }
    }
    .video-details {
      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        width: 92%;
      }
    }
    .play-icon {
      position: absolute;
      left: 67%;
      bottom: 70px;
    }
    .play-bg {
      width: 180px;
      height: 260px;
    }
  }
  .title-2 {
    margin-bottom: -2px;
  }
  .progress {
    height: 8px;
  }
  .property-section {
    &.section-sm {
      .property-2:not(.no-sidebar) {
        height: calc(100vh - 60px);
      }
    }
  }
  .get-in-touch {
    .btn {
      padding: 10px 18px 9px;
    }
  }
  .log-in {
    h6 {
      margin-bottom: 10px;
    }
    .social-connect {
      > div {
        &:nth-child(n + 3) {
          margin-top: 10px;
        }
      }
    }
  }
  .theme-card {
    .title-3 {
      p {
        margin-bottom: -5px;
      }
    }
  }
  .theme-title {
    .title-3 {
      p {
        margin-bottom: -6px;
      }
    }
  }
  .ratio-card {
    .card-header {
      p {
        margin-bottom: -2px !important;
      }
    }
    .card-body {
      h5 {
        margin-bottom: 10px;
      }
    }
  }
  .home-search-6 {
    .left-sidebar {
      .row {
        margin-bottom: 0;
        > div {
          margin-bottom: 0;
        }
      }
    }
  }
  .box {
    width: 270px;
    height: 130px;
    &.text-affect {
      .title {
        height: 86px;
      }
    }
  }
  .parallax-home {
    &.video-layout {
      .video-search {
        width: 100%;
      }
    }
  }
  .property-map {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -4px;
        }
      }
    }
  }
  .pricing-section {
    .title-3 {
      margin-bottom: 27px;
    }
    .pricing-box {
      .pricing-details {
        .pricing-icon {
          &::after {
            transform: translateX(8px);
          }
        }
      }
    }
  }
  .about-main {
    .title-2 {
      margin-bottom: -4px;
    }
  }
  .blog-inner {
    .title-2 {
      margin-bottom: -3px;
    }
  }
  .banner-2 {
    .banner-right {
      h6 {
        margin-top: -3px;
      }
      .button-banner {
        .btn-light-bg {
          &::after {
            height: 55px;
          }
          span {
            vertical-align: -2px;
          }
        }
      }
    }
  }
  // contact css
  .download-section {
    .app-right {
      margin-bottom: -2px;
      .form-group {
        width: calc(100% - 116px);
      }
    }
  }
  // filter sidebar
  .filter-cards {
    .advance-card {
      + .advance-card {
        margin-top: 25px;
      }
    }
  }
  // user dashboard css
  .user-dashboard {
    .sidebar-user {
      .user-profile {
        .media {
          .media-body {
            margin-left: 15px;
          }
        }
      }
    }
    .property-overview {
      .table {
        tr {
          th,
          td {
            &:first-child {
              min-width: 195px;
            }
          }
        }
      }
    }
  }
  .card-payment {
    .payment-card {
      &.add-card {
        .card-details {
          min-height: 167px;
        }
      }
    }
  }
  .privacy-setting {
    .privacy-content {
      .media {
        .media-body {
          h6 {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .switch {
    width: 50px;
    height: 26px;
    .switch-state {
      &:before {
        height: 20px;
        width: 20px;
        bottom: 3px;
      }
    }
    input {
      &:checked {
        + .switch-state {
          &:before {
            transform: translateX(23px);
          }
        }
      }
    }
  }
  .privacy-setting {
    .switch {
      width: 45px;
      height: 25px;
      .switch-state {
        &:before {
          bottom: 4px;
        }
      }
      input {
        &:checked {
          + .switch-state {
            &:before {
              transform: translateX(20px);
            }
          }
        }
      }
    }
  }
  // signup wizard css
  .property-wizard {
    &.horizontal-wizard {
      .wizard-box {
        .wizard-step-container {
          .wizard-steps {
            li {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
          .input-data {
            display: block;
            .account-content {
              + .account-content {
                margin-left: 0;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
  // submit property css
  .property-wizard {
    .wizard-box {
      .wizard-form-details {
        form {
          .form-group {
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .wizard-step-3 {
          .next-btn {
            margin-top: 25px;
          }
        }
        .complete-details {
          img {
            width: 63%;
          }
        }
        form.dropzone {
          margin-bottom: 10px;
        }
      }
      .wizard-step-container {
        .wizard-steps {
          li {
            .media {
              .step-icon {
                width: 40px;
                height: 40px;
                svg {
                  width: 24px;
                  height: 24px;
                }
              }
              .media-body {
                h5 {
                  margin-bottom: 3px;
                }
              }
            }
          }
        }
      }
    }
    &.horizontal-wizard {
      .wizard-box {
        .wizard-step-container {
          .wizard-form-details {
            .wizard-step-3 {
              .next-btn {
                margin-top: 30px;
              }
            }
          }
          .input-data {
            .account-content {
              h3 {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
  // about us css
  .user-about {
    .about-image {
      .img-box {
        &.img-abs {
          right: 8%;
        }
      }
    }
  }
  // agent profile css
  .agent-section {
    .our-agent-details {
      ul {
        li {
          .media {
            .icons-square {
              width: 25px;
              height: 25px;
              svg {
                width: 13px;
                height: 13px;
              }
            }
          }
        }
      }
    }
    .agent-grids {
      .filter-panel {
        .top-panel {
          .grid-list-filter {
            justify-content: flex-start;
            .grid-btn,
            .list-btn {
              display: inline-block !important;
            }
          }
        }
      }
      .property-grid {
        &.list-view {
          .property-box {
            display: flex;
          }
        }
      }
    }
    .our-agent {
      .ratio_landscape {
        .bg-size {
          &::before {
            padding-top: 111%;
          }
        }
      }
    }
    .agent-wrap {
      padding-left: 0;
      border-left: none;
    }
  }
  // coming soon css
  .coming-simple {
    .site-construction {
      h4 {
        line-height: 1.4;
      }
    }
    .ratio2_3 {
      &.order-lg-1 {
        margin-top: -42px;
      }
    }
    form {
      input {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  .coming-soon {
    .cloud-img {
      height: 230px;
      top: 0;
    }
  }
  // 404 page css
  .error-section {
    .not-found {
      img {
        width: 100%;
      }
    }
  }
  // faq page css
  .accordion {
    .card-header {
      .card-link {
        &::after {
          font-size: 12px;
          top: 17px;
        }
      }
    }
  }
  .faq-section {
    .faq-image {
      margin-top: -32px;
    }
    form {
      .btn {
        top: 32px;
      }
    }
  }
  // contact us css
  .contact_section {
    .contact-content {
      width: 100%;
    }
  }
  // blog element css
  .horizontal-blog {
    .title-2 {
      p {
        margin-bottom: 26px;
      }
    }
  }
  .blog-2 {
    .slick-list {
      margin: 0 -12px;
      .slick-track {
        .slick-slide {
          > div {
            margin: 0 12px;
          }
        }
      }
    }
  }
  .simple-blog {
    .title-3 {
      margin-bottom: 27px;
    }
  }
  // blog details css
  .blog-single-detail {
    .blog-title {
      .post-detail {
        li {
          &:last-child {
            padding-left: 0;
            margin-left: 0;
            border: none;
          }
        }
      }
    }
  }
  // blog creative css
  .blog-list-section {
    .blog-list {
      .blog-wrap {
        display: block;
        .blog-details {
          h3,
          p {
            -webkit-line-clamp: 3;
          }
        }
      }
    }
  }
  // portfolio css
  .portfolio-grid {
    .filter-panel {
      .top-panel {
        display: flex;
      }
    }
  }
  // home section css
  .fnc-slider {
    height: 500px;
  }
  .fnc-slide__content {
    left: 20%;
    padding: 30px;
  }
  .fnc-slide__heading-line {
    font-size: 40px;
  }
  .fnc-slide__action-btn {
    margin-left: 0;
  }
  .home-section {
    &.layout-6,
    &.layout-5 {
      .container {
        > .row {
          padding: 50px 0;
        }
      }
    }
    &.layout-5 {
      padding-top: 60px;
      .row {
        margin: 0;
      }
      .home-main {
        .home-content {
          > div {
            > img {
              width: 160px;
            }
          }
        }
        .looking-icons {
          margin-top: 40px;
        }
      }
    }
    &.layout-6 {
      .home-main {
        .container {
          > .row {
            padding-top: 100px;
          }
        }
      }
    }
    &.layout-9 {
      padding: 50px 0;
    }
  }
  .looking-icons {
    ul {
      li {
        h6 {
          margin-top: 15px !important;
        }
        .looking-icon {
          svg {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
  header {
    &.light-header {
      .menu {
        .brand-logo {
          img {
            width: 120px;
          }
        }
      }
    }
    &.header-2 {
      &::after {
        bottom: 74px;
      }
    }
  }
  .layout-home2 {
    .home-main {
      padding-top: 130px;
    }
    .arrow-light {
      .slick-arrow {
        top: 65%;
      }
      .slick-prev {
        left: 10px;
      }
      .slick-next {
        right: 10px;
      }
    }
    .feature-section {
      .feature-content {
        max-width: 450px;
        margin: 0 auto;
      }
    }
  }
  .top-bar {
    .top-content {
      .top-bar-right {
        li {
          .flat-box {
            padding: 6px 12px;
            i {
              font-size: 12px;
            }
            &.search-sm {
              i {
                vertical-align: 0;
              }
            }
          }
          + li {
            margin-left: 6px;
          }
        }
      }
    }
    &.top-bar-7 {
      .top-content {
        .top-bar-right {
          li {
            &:last-child {
              margin-left: 6px;
            }
            .flat-box {
              padding: 5px 10px 6px;
              display: block;
              i {
                font-size: 12px;
              }
            }
          }
          .input-group {
            display: none;
          }
          > li {
            > a {
              padding: 6px 8px 7px;
              display: block;
            }
          }
        }
      }
    }
  }
  .layout-home3 {
    .banner-3 {
      width: 65%;
      padding: 20px;
      box-shadow: 2px 0px 20px 20px rgba(255, 255, 255, 0.7);
      h1 {
        margin: 15px 0;
      }
      p {
        margin-bottom: 15px;
      }
    }
    .slick-slider {
      .slick-arrow {
        width: 60px;
        height: 60px;
        &::after {
          width: 35px;
          height: 35px;
          top: 12px;
          left: 12px;
        }
      }
    }
    .bg-layout-3 {
      padding: 50px 0;
    }
    .slick-arrow {
      .slick-thumb-nav {
        &::after {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .feature-3 {
    .next-image {
      top: 253px;
      width: 78px;
      height: 78px;
      right: 11px;
    }
  }
  .layout-home4 {
    .sync-slider {
      .home-slider-4 {
        &.arrow-image {
          .slick-arrow {
            &::after {
              width: 90px;
              height: 90px;
              left: -26px;
              top: -26px;
            }
          }
          .slick-prev {
            left: 30px;
          }
          .slick-next {
            right: 30px;
          }
        }
      }
      .home-nav {
        .slider-image {
          width: 80px;
          height: 50px;
        }
      }
    }
    .home-slider-4 {
      .slider-image {
        padding: 50px 0;
        .home-content {
          padding: 20px;
          text-align: center;
          .font-color4 {
            align-items: center;
            justify-content: center;
          }
          h6 {
            &.line-right::after {
              display: none;
            }
          }
          h1 {
            line-height: 1.5;
          }
        }
      }
    }
    .slider-image .home-content p {
      width: 100%;
    }
  }
  .layout-home8 {
    height: 740px;
    .home-content {
      margin-bottom: 30px;
      h1 {
        margin-top: -4px !important;
      }
    }
  }
  .search-with-tab {
    .nav-tabs {
      .nav-item {
        .nav-link {
          padding: 8px 16px;
        }
      }
    }
    .tab-content {
      .review-form {
        > div {
          &:nth-child(n + 4) {
            margin-top: 16px;
          }
        }
      }
    }
  }
  .search-withmap {
    .vertical-search {
      width: auto;
    }
  }
  // breadcrumb css
  .breadcrumb-section {
    height: 350px;
    .breadcrumb-content {
      height: 350px;
      padding-top: 60px;
    }
    &.property-main {
      height: 460px;
    }
    #block {
      height: 460px !important;
    }
    &.breadcrumb-sm {
      background-color: rgba($black, 0.25);
      background-blend-mode: overlay;
      .breadcrumb-content {
        > div {
          h2 {
            margin-bottom: 10px;
          }
          flex-direction: column;
        }
      }
      &.breadcrumb-gradient {
        background-blend-mode: unset;
      }
    }
  }
  // single property
  .single-property {
    margin-top: 60px;
    .description-section {
      .menu-top {
        li {
          a {
            padding: 0 14px 0 0;
            text-transform: capitalize;
          }
          &.active {
            a {
              border-bottom: none;
            }
          }
        }
      }
    }
    .page-section {
      iframe {
        height: 250px;
      }
    }
  }
  .gallery-nav {
    .slick-slide {
      img {
        min-height: 46px;
      }
    }
  }
  .single-property-section {
    .left-single {
      > ul {
        flex-wrap: wrap;
        > li {
          flex: 0 0 30%;
          max-width: 30%;
        }
      }
      .share-buttons {
        > div {
          .share-hover {
            bottom: -40px;
            ul {
              li {
                padding: 5px 10px;
              }
            }
          }
          &:hover {
            .share-hover {
              bottom: -35px;
            }
          }
        }
      }
    }
  }
  .single-property-images {
    &.ratio_40 {
      .bg-size {
        &:before {
          padding-top: 68%;
        }
      }
    }
    .single-property-section {
      position: relative;
      bottom: unset;
      padding: 30px 0;
    }
    ~ .single-property {
      margin-top: 0;
      padding-top: 0;
    }
  }
  // 360 view page
  .home-view {
    height: 55vh;
  }
  .line-tab {
    &.nav-tabs {
      .nav-link {
        padding: 15px 8px;
      }
    }
  }
  // button css
  .btn {
    padding: 8px 18px;
    &.btn-dashed {
      padding: 9px 18px;
    }
    &.btn-light-bg {
      &::after {
        height: 39px;
      }
    }
  }
  .theme-buttons {
    > div {
      margin-left: -6px;
      .btn {
        + .btn {
          margin-left: 6px;
        }
      }
    }
  }
  // label css
  .label {
    &.label-lg {
      padding: 8px 20px;
    }
  }
  // footer css
  footer {
    .footer {
      padding: 50px 0;
    }
    .footer-details {
      p {
        margin-bottom: 10px;
      }
      .footer-contact {
        ul {
          li {
            + li {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .sub-footer {
      text-align: center;
      .text-end {
        text-align: center !important;
        margin-top: 5px;
      }
      &.sub-footer-dark {
        padding-top: 16px;
      }
      &.footer-light {
        padding-top: 8px;
      }
    }
    .footer-links {
      .footer-title {
        span {
          display: block;
          top: 0;
          right: 0;
          i {
            font-size: 13px;
          }
        }
      }
      padding-bottom: 15px;
      h5 {
        margin-bottom: 0;
        line-height: 1.2;
        cursor: pointer;
      }
      .footer-content {
        margin-top: 15px;
        .footer-blog {
          p {
            line-height: 1.6;
          }
        }
        li {
          + li {
            margin-top: 6px;
          }
        }
      }
      &.footer-details {
        .footer-content {
          margin-top: 15px !important;
        }
      }
    }
    &.footer-dark {
      .footer-details {
        .footer-content {
          margin-top: 15px;
          p {
            margin-top: 10px;
          }
        }
      }
    }
    &.footer-light {
      .footer-links {
        .footer-content {
          margin-top: 15px;
        }
        .footer-title {
          .according-menu {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
    &.footer-res {
      .footer-contact {
        margin-bottom: 3px;
      }
      .footer {
        .row {
          > div {
            &:nth-child(4),
            &:last-child {
              margin-top: 0;
            }
            &:last-child {
              .footer-links {
                padding-bottom: 0;
                margin-bottom: -3px;
              }
            }
          }
        }
      }
      .footer-links {
        .footer-content {
          margin-top: 15px;
          margin-bottom: 0;
        }
      }
    }
  }
  .footer-bg {
    .bottom-blog {
      padding-top: 20px;
      .footer-links {
        .footer-title {
          span {
            right: 6px;
          }
        }
        .footer-content {
          margin-top: 15px;
          .row {
            > div {
              + div {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
    .footer-links {
      padding-bottom: 20px;
      .footer-content {
        margin-top: 15px;
        .input-group {
          margin-top: 20px;
        }
        li {
          + li {
            margin-top: 6px;
          }
        }
      }
    }
  }
  .footer-custom-col,
  .footer-light {
    .row {
      > div {
        &:nth-child(3),
        &:last-child,
        &:nth-child(4) {
          margin-top: 0 !important;
        }
        &:last-child {
          .footer-links {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .footer-brown,
  .footer-dark {
    .footer {
      .row {
        margin-top: -3px;
      }
    }
  }
  .footer-brown {
    .sub-footer {
      padding-top: 16px;
      .text-end {
        margin-top: 0;
      }
    }
  }
  .footer-light {
    .row {
      margin-bottom: -3px;
      margin-top: -3px;
    }
    .footer-content {
      margin-bottom: -2px;
    }
  }
  // image ratio page css
  .ratio-card {
    .card-body {
      h5 {
        margin-top: -2px;
      }
      .row {
        > div {
          + div {
            margin-top: 20px;
          }
        }
      }
    }
  }
  // thumbnail list css
  .property-grid-slider {
    .property-grid {
      .slider-for {
        .slick-list {
          height: 250px;
        }
      }
      .slider-nav {
        .slick-slide {
          img {
            min-height: unset;
            height: 84px;
            width: 100%;
          }
        }
      }
    }
    .filter-panel {
      .top-panel {
        .grid-list-filter {
          li {
            &:nth-child(2) {
              order: -1;
            }
          }
        }
      }
    }
  }
  .layout-home4 {
    .home-slider-4 {
      .slick-arrow {
        display: none !important;
      }
    }
  }
  // testimonial css
  .testimonial-1 {
    .pepole-comment {
      .client-msg {
        margin-bottom: 30px;
      }
    }
  }
  .client-slider {
    margin-top: 48px;
    width: 100%;
    .user-list {
      transform: scale(0.7);
      li {
        .heart-bg {
          width: 40px;
          height: 40px;
          bottom: -20px;
          left: 48px;
        }
        .heart-icon {
          bottom: -12px;
          left: 57px;
        }
      }
    }
  }
  .our-client {
    .client-right {
      padding: 30px;
      p {
        margin-top: -5px;
      }
      h6 {
        margin-bottom: -4px;
      }
    }
    .client-left {
      img {
        box-shadow: 0px 10px 12.5px rgba(0, 128, 255, 0.03);
      }
    }
  }
  .modern-client {
    .comment-right {
      bottom: -12px;
      .slick-prev {
        left: 40%;
      }
      .slick-next {
        left: 50%;
        &:after {
          right: 10px;
        }
      }
    }
  }

  .testimonial-style-2 {
    .title-3 {
      margin-bottom: 27px;
    }
  }
  // full banner css
  .banner-2 {
    overflow: hidden;
    .banner-right {
      position: relative;
      padding: 30px;
      z-index: 1;
      background-color: rgba($white, 0.95);
    }
    &:after {
      width: 100%;
    }
  }
  .banner-3 {
    padding: 30px;
    p {
      font-size: 14px;
      line-height: 1.8;
    }
  }
  .banner-inner {
    padding-top: 106px;
  }
  // about css
  .about-box {
    .bg-size {
      &:before {
        padding-top: 80%;
      }
    }
  }
  .arrow-white {
    margin-bottom: 75px;
    .slick-arrow {
      width: 40px;
      height: 40px;
      bottom: -70px;
    }
    .slick-prev {
      left: 40%;
    }
    .slick-next {
      right: 40%;
    }
  }
  .about-section {
    .about-1 {
      .bg-size {
        background-position: top !important;
        &:before {
          padding-top: 78%;
        }
      }
    }
    &.ratio_square {
      .title-3 {
        p {
          margin-bottom: -3px;
        }
      }
    }
    .title-3 {
      margin-top: -11px;
      &.text-start {
        p {
          margin-bottom: -3px;
        }
      }
    }
  }
  // service css
  .service-section {
    .title-2 {
      margin-bottom: -5px;
    }
    .title-3 {
      p {
        margin-bottom: -3px;
      }
      &.text-start {
        p {
          margin-bottom: -12px;
        }
      }
    }
    &.service-bg {
      &::after {
        height: 150px;
      }
    }
    &.service-2 {
      .property-service {
        > div {
          .service-box {
            .hover-line {
              transform: scale(0.9);
            }
            .btn {
              span {
                vertical-align: -1px;
              }
            }
            &:hover {
              .btn {
                span {
                  vertical-align: 0;
                }
              }
            }
            .btn {
              &:hover {
                span {
                  vertical-align: -2px;
                }
              }
            }
          }
        }
        &.about-service {
          > div {
            .service-box {
              .btn {
                span {
                  vertical-align: -1px;
                }
              }
            }
          }
        }
      }
      &.color-7 {
        .property-service {
          > div {
            .service-box {
              &:hover {
                .btn {
                  span {
                    vertical-align: -1px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .why-choose {
    .title-2 {
      margin-bottom: -3px;
    }
  }
  .about-testimonial {
    .title-2 {
      margin-bottom: -3px;
    }
  }
  // property css
  .property-section {
    .title-3 {
      margin-bottom: 27px;
      &.d-flex {
        display: block !important;
        margin-bottom: 30px;
      }
      .pagination-tab {
        margin-top: 10px;
      }
    }
    .title-2 {
      margin-bottom: -5px;
    }
    .ratio_landscape {
      .title-2 {
        margin-bottom: -3px;
      }
    }
    .property-box {
      .property-details {
        .property-btn {
          .btn {
            padding: 8px 18px 7px;
            &.btn-dashed {
              padding: 9px 18px 8px;
            }
          }
        }
      }
    }
    .list-property {
      .property-box {
        .property-image {
          .bg-size {
            &:before {
              padding-top: 60%;
            }
          }
          .overlay-property {
            .overlay-box {
              p {
                width: 100%;
              }
            }
          }
        }
        .property-details {
          .icon-property {
            li {
              + li {
                &::before {
                  left: -36px;
                }
              }
            }
          }
        }
        .text-center {
          margin-top: -19px;
        }
      }
    }
    .property-box-flat {
      .property-box {
        .property-image {
          .label-white {
            padding: 8px 20px 7px;
          }
        }
        .property-details {
          h3 {
            margin-top: 0;
          }
        }
      }
    }
  }
  .pagination-tab {
    &.nav-tabs {
      justify-content: flex-end;
      margin-top: 10px;
      .nav-item {
        margin-bottom: 0;
      }
    }
  }
  .slick-shadow {
    .arrow-white {
      .slick-arrow {
        bottom: -65px;
      }
    }
  }
  .no-slider-property {
    .property-box {
      margin: 0;
      .property-details {
        .icon-property {
          li {
            + li {
              &::before {
                left: -38px;
              }
            }
          }
        }
      }
    }
  }
  .filter-panel {
    position: relative;
    .top-panel {
      display: block;
      .grid-list-filter {
        margin: 15px -5px -4px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          margin: 5px;
        }
        .grid-btn,
        .list-btn {
          display: none !important;
        }
      }
    }
    .d-lg-none {
      .mobile-filter {
        padding: 8px 10px;
        svg {
          margin-left: 10px;
          vertical-align: -4px;
        }
      }
    }
    .grid-list-filter {
      li {
        .filter-bottom-title {
          padding: 8px 10px;
        }
      }
    }
    &.with-modal-btn {
      .top-panel {
        .grid-list-filter {
          justify-content: flex-start;
        }
      }
    }
    &.tab-top-panel {
      .grid-list-filter {
        li {
          &:last-child {
            margin-left: 5px;
          }
          &:nth-child(2) {
            order: -1;
          }
        }
      }
    }
  }
  .property-grid {
    &.list-view {
      .property-box {
        display: block;
      }
    }
  }
  .property-grid-3 {
    .property-grid {
      &.list-view {
        .property-box {
          .property-image,
          .agent-image {
            width: 100%;
          }
        }
      }
    }
  }
  .property-map {
    .property-grid {
      padding-top: 25px;
      &.list-view {
        .property-box {
          .property-image {
            width: 100%;
          }
        }
      }
    }
    .top-panel {
      .grid-list-filter {
        li {
          &:nth-child(2) {
            order: -1;
          }
        }
      }
    }
  }
  .property-grid-slider {
    .property-grid {
      .slider-for {
        .slick-slide {
          #block {
            height: 285px !important;
          }
        }
      }
      .slider-nav {
        margin-right: -3px;
      }
    }
  }
  .property-list-view {
    .property-grid {
      &.list-view {
        .property-box {
          .property-image {
            width: 100%;
          }
        }
      }
    }
  }
  // feature css
  .bg-comman-2 {
    &::before {
      height: 245px;
    }
  }
  .feature-section {
    .title-3 {
      margin-bottom: 27px;
    }
    .feature-content {
      .detail-list {
        li {
          .label-lg {
            padding: 8px 12px 6px;
          }
          .d-flex {
            .label-lg {
              padding: 6px 12px 4px;
            }
          }
        }
      }
    }
    .feature-1 {
      .feature-wrapper {
        margin: 0;
        .property-details {
          max-width: 450px;
        }
      }
    }
    .feature-3 {
      .slick-list {
        margin-left: -5px;
      }
      &.slick-initialized {
        .slick-slide {
          margin-bottom: -1px;
        }
      }
      .feature-content {
        .details,
        .feature-price {
          padding: 20px;
        }
        .detail-list {
          padding: 15px 18px;
        }
        .details {
          p {
            margin-top: 10px;
          }
        }
      }
      .feature-image {
        .feature-overlay {
          transform: translateY(-50%);
          width: 210px;
          height: 169px;
        }
      }
      .slick-next,
      .slick-prev {
        transform: scale(0.8);
      }
    }
    .modern-feature {
      .feature-wrap {
        .feature-image {
          .feature-overlay {
            transform: translateY(-50%);
            width: 210px;
            height: 169px;
          }
        }
      }
      &.arrow-gradient {
        .slick-prev {
          right: 10% !important;
        }
      }
    }
  }
  // other page css
  .my-gallery {
    .row {
      > div {
        .row {
          > div {
            &:nth-child(3),
            &:nth-child(4) {
              order: unset;
            }
          }
        }
      }
    }
    .find-cities {
      .citi-overlay {
        h6 {
          &::after {
            width: 130px;
            right: -16px;
          }
        }
      }
    }
  }
  .video-section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .offer-section {
    .offer-wrapper {
      .media {
        .offer-icon {
          width: 80px;
          height: 80px;
          img {
            height: 40px;
          }
        }
        .media-body {
          margin-left: 15px;
        }
      }
    }
  }
  .video-details {
    h2 {
      margin: 15px 0 8px;
    }
    p {
      margin-bottom: 15px;
    }
  }
  .new-property {
    .feature-wrap {
      margin: 0;
    }
    .title-2 {
      h2 {
        margin-top: -5px;
      }
    }
  }
  .subscribe-section {
    .video-details {
      .form-group {
        margin-bottom: 20px;
        input {
          width: 80%;
          padding: 10px 15px 8px;
        }
        &::after {
          width: 80%;
        }
      }
    }
  }
  // map modal css
  .arrow-define {
    font-size: 13px;
  }
  // blog page css
  .blog-section {
    &.bg-comman-2 {
      &::before {
        height: 300px;
      }
    }
  }
  .blog-1 {
    .blog-box {
      .img-box {
        img {
          min-width: 239px;
        }
      }
    }
  }
}
@media (max-width: 719px) {
  .property-section {
    .property-box-flat {
      .property-box {
        .property-details {
          h3 {
            margin-top: -2px;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) { 
  .blog-single-detail
    .comment-section
    .comment-box
    .media
    .media-body
    .comment-detail
    p {
    width: 100%;
  }
  .pricing-section {
    .pricing-box {
      .pricing-details {
        .pricing-icon {
          &::after {
            transform: translateX(9px);
          }
        }
      }
    }
  }
  .about-section .about-1 .owl-carousel .owl-nav {
    .owl-prev {
      bottom: -69px;
    }
    .owl-next {
      bottom: -69px;
    }
  }
  .parallax-home {
    .container {
      > .row {
        height: 240px;
      }
    }
  }
  // breadcrumb css
  .breadcrumb-section {
    height: 270px;
    .breadcrumb-content {
      height: 270px;
      padding-top: 56px;
    }
  }
  // header css
  .toggle-right {
    svg {
      width: 20px;
    }
  }
  header {
    &.header-4 {
      .menu {
        .brand-logo {
          img {
            margin-bottom: 0;
          }
        }
      }
    }
    &.light-header {
      .menu {
        .header-right {
          li {
            .or {
              &:after {
                color: $title-color;
              }
            }
          }
        }
      }
    }
  }
  // home section css
  .home-section {
    &.layout-5 {
      padding-top: 56px;
      padding-bottom: 40px;
    }
  }
}
@media (max-width: 575px) {
  .feature-section{
    &.bg-comman-2{
      .feature-2{
        .owl-theme{
          .owl-nav.disabled + .owl-dots{
            bottom: -32px;
          }
        }
      }
    }
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
    line-height: 1.4;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
    line-height: 1.5;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
  }
  .label {
    padding: 3px 8px 2px;
  }
  .about-section{
    .about-3{
      .owl-item > div{
        margin-right: 15px !important;
      }
    }
  }
  .form-control {
    font-size: 14px;
  }
  .layout-map.layout-home2 {
    min-height: 500px;
  } 
  .pricing-property{
    .feature-section{
      &.ratio_landscape{
        .feature-wrap{
          overflow-x: hidden;
        }
      }
    }
  }
  .home-section {
    &.layout-1 {
      .owl-carousel {
        .owl-stage-outer .owl-stage .owl-item {
          .bg-size {
            height: 460px;
          }
        }
      }
    }
  } 
  .property-section {
    .property-4 {
      .owl-theme {
        .owl-item {
          .ng-star-inserted {
            > div {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
  .review {
    .review-box {
      position: relative;
      .media {
        img.img-70{
          width: 50px !important;
        }
        .media-body {
          margin-left: 15px;
          margin-top: 6px;
          h6 {
            margin-bottom: 2px;
          }
        }
        .rating {
          position: absolute;
          left: 150px;
          top: 29px;
        }
      }
      + .review-box {
        margin-top: 15px;
      }
      &.review-child {
        margin-left: 30px;
      }
    }
  }
  .review{
    .review-box{
      &.review-child{
        margin-left: 35px;
      }
      .media{
        .media-body{
          p.mb-0{
            width: 90%;
          }
        }
      }
    }
  }
  .blog-single-detail {
    &.theme-card {
      .property-slider {
        &:hover {
          .slick-prev {
            width: 16px;
            height: 16px;
            &::before {
              font-size: 18px;
            }
          }
          .slick-next {
            width: 16px;
            height: 16px;
            &::before {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .grid-box {
    .property-text {
      padding: 16px;
    }
  }
  .owl-theme .owl-nav.disabled+.owl-dots {
    bottom: -21px;
  }
  .portfolio-grid {
    .filter-panel {
      .filters {
        ul {
          li {
            min-width: 100px;
          }
        }
      }
    }
  }
  .agent-section {
    .about-agent {
      div.row {
        margin-bottom: -8px;
      }
      p {
        margin-bottom: 8px;
      }
    }
  }
  .column-space {
    margin-bottom: -20px;
    > div {
      margin-bottom: 20px;
    }
  }
  .parallax-home.video-layout {
    height: auto;
    min-height: auto;
    .video-search {
      .dropdown {
        display: none;
      }
    }
  }
  .feature-section {
    .feature-3 {
      .feature-content {
        box-shadow: 0 0 23px rgba(0, 0, 0, 0.08);
        .details {
          padding: 20px !important;
        }
        .detail-list {
          padding: 15px 18px !important;
        }
        .feature-price {
          padding: 20px !important;
        }
      }
    }
  }
  .fnc-slide__action-btn {
    padding: 10px 10px;
    &::before {
      height: 40px;
      width: 40px;
    }
    span {
      font-size: 12px;
    }
  }
  .testimonial-client {
    .testimonial-4 {
      .modern-client {
        position: relative;
      }
    }
  }
  .tab-icon {
    .filters {
      ul {
        li {
          lord-icon {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
  section {
    padding: 40px 0;
  }
  .progress {
    height: 5px;
  }
  .grid-list-filter {
    .dropdown-menu {
      min-width: 160px;
    }
  }
  .title-2 {
    margin-bottom: -4px;
    h2 {
      margin-top: -2px;
    }
    p {
      line-height: 1.7;
    }
  }
  .title-3 {
    p {
      margin-bottom: 0;
    }
    &.text-start {
      h2 {
        margin-top: -5px;
      }
    }
  }
  .contact_right {
    margin-top: 20px;
  }
  .theme-title {
    .title-1 {
      h2 {
        margin-bottom: -7px;
      }
    }
    .theme-card {
      .card-header {
        h4 {
          margin-top: -1px;
        }
      }
    }
    .title-2 {
      h2 {
        margin-top: -3px;
      }
    }
  }
  .theme-card {
    .title-2 {
      margin-bottom: -6px;
    }
    .title-3 {
      p {
        margin-bottom: -2px;
      }
    }
  }
  .theme-pagination {
    margin-top: 20px;
  }
  .property-map {
    .theme-pagination {
      margin-bottom: 30px;
    }
  }
  .testimonial-style-2 {
    .title-3 {
      margin-top: -12px;
    }
  }
  .ratio-card {
    .card-body {
      ul {
        margin-bottom: -5px;
      }
    }
  }
  footer {
    .footer {
      padding: 40px 0;
    }
    .footer-links {
      .footer-content {
        .footer-blog {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  .footer-bg {
    .bottom-blog {
      .slick-initialized {
        .slick-slide {
          margin-bottom: -5px;
        }
      }
    }
    .footer-details {
      padding: 20px 15px;
    }
  }
  .alert-success {
    max-width: unset;
  }
  .portfolio-creative {
    .order-section {
      margin-bottom: -20px !important;
      div {
        &:nth-child(even) {
          margin-bottom: 20px;
        }
      }
    }
  }
  .theme-card {
    + .theme-card {
      margin-top: 20px;
    }
  }
  .captcha {
    margin-bottom: 15px;
  }
  .theme-label {
    .card-header {
      h5 {
        margin-top: -2px;
      }
    }
  }
  .my-gallery {
    .title-1 {
      h2 {
        margin-bottom: -2px;
      }
    }
    .row {
      .col {
        .row {
          margin-bottom: -20px;
          > div {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .offer-section {
    .title-1 {
      h2 {
        margin-bottom: -7px;
      }
    }
    .offer-wrapper {
      padding: 20px;
      .media {
        .media-body {
          h3 {
            margin: 10px 0;
          }
        }
      }
    }
  }
  .video-layout {
    padding-bottom: 40px;
    .video-details {
      width: 100%;
      padding: 0;
      p {
        width: 100%;
        overflow: unset;
        -webkit-line-clamp: unset;
      }
    }
    .icon-video {
      position: relative;
      left: unset;
      transform: unset;
      top: unset;
      margin-bottom: 20px;
    }
    .row {
      > div.video {
        &:last-child {
          order: -1;
        }
      }
    }
    .play-icon {
      position: relative;
      bottom: unset;
      left: unset;
      margin: 0;
    }
    .play-bg {
      width: auto;
      height: auto;
      border: none;
      img {
        display: none;
      }
      &::after {
        display: none;
      }
    }
    .video-search {
      width: 100%;
      .input-group {
        border-right: none;
        input {
          padding-right: 0;
        }
      }
    }
  }
  .video-details {
    h2 {
      margin: 10px 0 4px;
    }
  }
  .new-property {
    .title-2 {
      margin-bottom: -7px;
    }
  }
  .pricing-section {
    .title-3 {
      margin-bottom: 17px;
    }
    .pricing-box {
      .pricing-details {
        h3 {
          margin-bottom: 4px;
        }
        .pricing-svg {
          width: 40px;
          height: 40px;
        }
        .pricing-icon {
          &::after {
            width: 35px;
            height: 35px;
            left: -1px;
          }
        }
      }
      ul {
        margin: 20px 0 26px;
        li {
          + li {
            margin-top: 5px;
          }
        }
      }
    }
    &.pricing-inner {
      .title-2 {
        h2 {
          margin-top: -3px;
        }
      }
    }
  }
  .filter-panel {
    padding: 0 0 20px;
    .top-panel {
      .grid-list-filter {
        margin-top: 10px;
        margin-bottom: 0;
      }
    }
    .d-lg-none {
      .mobile-filter {
        padding: 6px 10px;
      }
    }
  }
  .tab-listing {
    .filter-panel {
      margin-bottom: 20px;
    }
  }
  .property-grid-2 {
    .filter-panel {
      .top-panel {
        h2 {
          margin-bottom: 5px;
        }
      }
    }
  }
  .property-map {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -3px;
        }
      }
    }
  }
  .privacy-setting {
    .common-card {
      .common-header {
        p {
          margin-top: 5px;
        }
      }
    }
  }
  .logout-modal {
    .modal-body {
      h6 {
        margin-bottom: 4px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  .signup-modal {
    .signup-tab {
      h4 {
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
  }
  .testimonial-client {
    .title-3 {
      &.text-start {
        margin-top: 2px;
      }
    }
  }

  .pricing-inner {
    .title-2 {
      p {
        margin-bottom: 16px;
      }
    }
  }
  .line-tab {
    &.nav-tabs {
      .nav-link {
        padding: 10px 8px;
      }
    }
  }
  .terms-section {
    h2 {
      margin-top: -5px;
      margin-bottom: 15px;
      line-height: 1.5;
    }
    .terms-wrapper {
      p {
        font-size: 14px;
        margin-bottom: 10px;
      }
      h4 {
        margin-bottom: 6px;
        line-height: 1.5;
      }
      ul {
        li {
          font-size: 14px;
        }
      }
    }
  }
  // testimonial css
  .our-client {
    .client-right {
      padding: 20px;
      h3 {
        margin-bottom: 5px;
      }
      h6 {
        margin-bottom: -6px;
      }
    }
  }
  // submit property css
  .property-wizard {
    .wizard-box {
      .wizard-step-container {
        .wizard-steps {
          li {
            .media {
              .media-body {
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
    .wizard-form-details {
      &.log-in {
        .title-3 {
          margin-bottom: 20px;
        }
      }
    }
    &.horizontal-wizard {
      .wizard-box {
        .wizard-step-container {
          .log-in {
            margin-top: 30px;
          }
          .wizard-form-details {
            .wizard-step-3 {
              .next-btn {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
  // blog css
  .left-sidebar,
  .blog-sidebar {
    padding: 20px;
  }
  .filter-cards {
    .advance-card {
      .category-property,
      .recent-property {
        ul {
          li {
            + li {
              margin-top: 10px;
            }
          }
        }
      }
      > h6 {
        &::before {
          bottom: -6px;
        }
      }
    }
  }
  .blog-sidebar,
  .single-sidebar {
    .filter-cards {
      .advance-card {
        .recent-property {
          ul {
            li {
              width: 100%;
              &:nth-child(2) {
                margin-top: 10px;
              }
              .media {
                .media-body {
                  h5 {
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .blog-wrap {
    .blog-details {
      padding: 20px;
    }
  }
  .blog-grid,
  .blog-list {
    margin-bottom: -20px;
    .blog-wrap {
      margin-bottom: 20px;
    }
  }
  .blog-single-detail {
    .leave-comment {
      margin-top: 20px;
    }
    .comment-section {
      .comment-box {
        .media {
          .media-body {
            .comment-title {
              .comment-date {
                margin-left: 10px;
              }
            }
          }
        }
      }
      h4 {
        margin-bottom: 15px;
      }
    }
  }
  // portfolio details  css
  .portfolio-creative {
    .content-sec {
      h3 {
        margin-top: -5px;
      }
    }
  }
  .details-right {
    padding: 20px;
    h3 {
      margin-top: -5px;
      margin-bottom: 8px;
    }
  }
  .project-details {
    margin-top: 15px;
    h3 {
      margin-top: 0;
      margin-bottom: 12px;
    }
    .detail-container {
      padding: 6px 0;
    }
  }
  .portfolio-details {
    .portfolio-section {
      margin-top: 6px;
    }
  }
  // porfoilo css
  .portfolio-grid {
    .property-text {
      h3 {
        margin-bottom: 6px;
      }
    }
  }
  .grid-box {
    .property-text {
      h3 {
        margin-top: -4px;
      }
    }
  }
  .portfolio-creative {
    .row {
      + .row {
        padding-top: 20px;
      }
    }
  }
  // login css
  .log-in {
    form {
      .form-group {
        margin-bottom: 15px;
      }
    }
    .divider {
      margin: 10px 0;
    }
  }
  .login-wrap {
    .log-in {
      .title-3 {
        &.text-start {
          h2 {
            margin-top: -5px;
          }
        }
      }
    }
  }
  // cominng soon css
  .coming-simple {
    .site-construction {
      h4 {
        font-size: 16px;
      }
      p {
        margin-bottom: 20px;
      }
    }
    .title-3 {
      h6 {
        margin-bottom: 6px;
      }
    }
    .ratio2_3 {
      > img {
        height: 400px;
      }
    }
    form {
      input {
        font-size: 14px;
        &::placeholder {
          font-size: 14px;
        }
      }
    }
  }
  .coming-soon {
    &.coming-left {
      .coming-soon-detail {
        .timer {
          li {
            margin: 0 5px;
          }
        }
      }
    }
    .cloud-img {
      height: 170px;
    }
    .coming-soon-detail {
      form {
        input {
          font-size: 14px;
        }
        .form-group {
          button {
            padding: 8px 14px;
          }
        }
      }
    }
  }
  // agent list css
  .property-grid {
    &.list-view {
      .property-box {
        .agent-image {
          border-right: none;
          border-bottom: 1px solid $light-border;
        }
      }
    }
  }
  // user dashboard css
  .user-dashboard {
    .sidebar-user {
      margin-bottom: 20px;
      .user-profile {
        display: block;
        padding: 20px 20px 0;
        .connected-social {
          margin-left: 0;
          margin-top: 15px;
          h6 {
            &::before {
              bottom: -3px;
            }
          }
        }
        .media {
          .media-body {
            h5 {
              margin-bottom: 5px;
            }
            h6 {
              margin-bottom: 2px;
            }
          }
        }
      }
    }
    .dashboard-content {
      margin-bottom: -20px;
      .common-card {
        margin-bottom: 20px;
      }
      .user-wrapper {
        .available-property {
          .radial-property {
            .apexcharts-datalabels-group {
              .apexcharts-datalabel-label {
                font-size: 32px;
              }
            }
          }
        }
      }
      .property-section {
        margin-bottom: 20px;
      }
    }
  }
  .right-line-tab {
    .nav-item {
      .nav-link {
        padding: 3px 8px;
      }
    }
  }
  .my-profile {
    .profile-info {
      .user-name {
        h5 {
          .label {
            padding: 4px 8px 3px;
          }
        }
        .rating {
          display: block;
          margin-left: 0;
        }
      }
      .user-detail {
        margin: 5px -10px 5px;
        li {
          margin: 2px 10px;
        }
      }
      .information {
        ul {
          li {
            span {
              &:first-child {
                min-width: unset;
              }
            }
          }
        }
      }
    }
  }
  .card-payment {
    > div {
      &:nth-child(n + 2) {
        .payment-card {
          margin-top: 15px;
        }
      }
    }
    .payment-card {
      .card-details {
        .card-number {
          > div {
            img {
              width: 9%;
              &:last-child {
                width: 3%;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  .create-tab {
    .create-property-form {
      .form-inputs {
        > h6 {
          margin-bottom: 15px;
          padding-bottom: 5px;
        }
        + .form-inputs {
          margin-top: 15px;
        }
      }
      .btn {
        margin-top: 15px;
      }
    }
  }
  .dropzone {
    .dz-message {
      margin: 0;
    }
    i {
      font-size: 40px;
    }
  }
  // about us
  .user-about {
    .about-image {
      margin-top: 42px;
      margin-bottom: 20px;
      .side-left {
        .side-effect {
          &::before,
          &::after {
            width: calc(100% - 350px);
          }
        }
      }
      .img-box {
        img {
          max-width: 205px;
        }
        &.img-abs {
          right: 140px;
        }
      }
    }
    .about-content {
      margin-bottom: 20px;
      h3 {
        margin-bottom: 10px;
      }
    }
    .about-listing {
      padding: 20px;
    }
    .map-image {
      margin-bottom: 15px;
    }
  }
  .why-choose,
  .blog-inner {
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
  }
  .about-testimonial {
    .title-2 {
      margin-bottom: -4px;
      h2 {
        margin-top: -3px;
      }
    }
  }
  .blog-grid,
  .blog-right,
  .blog-list {
    margin-top: 20px;
  }
  .blog-inner {
    .title-2 {
      margin-top: -4px;
    }
  }

  // contact us
  .get-in-touch {
    .contact-img {
      img {
        width: 70%;
      }
    }
  }
  .contact_section {
    .contact_wrap {
      h4 {
        margin-bottom: 10px;
        margin-top: 5px;
      }
    }
    &.contact_wrap_2 {
      .contact-detail {
        > div {
          &:last-child {
            margin-top: 20px;
          }
        }
      }
      .row {
        margin: 0;
      }
    }
  }
  .contact_bottom {
    .row {
      > div {
        + div,
        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
  .contact_right {
    .row {
      > div {
        + div {
          margin-top: 20px;
          &:last-child {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .contact-2 {
    .log-in {
      margin-bottom: 20px;
    }
  }
  // agent profile css
  .agent-section {
    .agent-wrap {
      border-top: 1px solid $light-border;
    }
    .agent-content {
      padding: 20px;
    }
    .our-agent {
      .ratio_landscape {
        .bg-size {
          &::before {
            padding-top: 60%;
          }
        }
      }
    }
    .our-agent-details {
      margin-top: 10px;
    }
    .agent-grids {
      .filter-panel {
        .top-panel {
          .grid-list-filter {
            .grid-btn,
            .list-btn {
              display: none !important;
            }
          }
        }
      }
      .property-grid {
        &.list-view {
          .property-box {
            display: block;
          }
        }
      }
    }
    .filter-panel {
      margin-top: -2px;
    }
  }
  .agent-social {
    li {
      a {
        width: 25px;
        height: 25px;
        i {
          font-size: 12px;
        }
      }
    }
  }
  // faq page css
  .accordion {
    .card {
      + .card {
        margin-top: 15px;
      }
    }
  }
  .faq-section {
    .faq-image {
      margin-top: -29px;
      h3 {
        margin-bottom: 4px;
      }
    }
    form {
      .form-group {
        input {
          padding: 14px 15px 12px;
        }
      }
    }
  }
  .faq-questions {
    margin-top: 20px;
    .title-3 {
      h2 {
        line-height: 1.3;
      }
    }
  }
  // blog details
  .play-bg-image {
    .icon-video {
      a {
        transform: scale(0.8);
      }
    }
  }
  .icon-video {
    .heart-animation {
      top: -16px;
      right: -15px;
      width: 70px;
      height: 70px;
    }
  }
  // tab layout
  .column-sm {
    margin-bottom: -20px;
    > div {
      margin-bottom: 20px;
    }
    ngx-masonry{
      >div {
          margin-bottom: 20px;
      }
    }
  }
  .filter-panel {
    .filters {
      ul {
        margin: -5px -2px;
        li {
          padding: 8px;
          margin: 5px 2px;
          i {
            display: none;
          }
        }
      }
    }
    .mobile-filter {
      position: relative;
      bottom: unset;
    }
    .d-lg-none {
      text-align: left;
    }
  }

  // map modal css

  .with-modal-btn {
    .d-lg-none {
      margin-top: 10px;
    }
    .top-panel {
      .grid-list-filter {
        display: flex;
        li {
          &:first-child {
            order: 4;
          }
        }
      }
    }
  }
  .arrow-define {
    top: 40px;
    right: -10px;
    &::after {
      width: 26px;
      height: 26px;
      bottom: 8px;
      left: -27px;
    }
  }
  // single property
  .single-property-section {
    .single-title {
      padding: 20px;
      .right-single {
        text-align: left;
      }
      .label {
        padding: 3px 6px;
      }
    }
    .left-single {
      h2 {
        margin-top: -4px;
      }
      ul {
        li {
          > div {
            img {
              width: 22px;
            }
            .ruler-tool {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }
  .single-property {
    .page-section {
      padding: 20px;
      hr {
        margin: 20px 0;
      }
    }
    #details,
    .about {
      h4 {
        display: block;
        a {
          display: block;
          margin-top: 10px;
        }
      }
    }
    .description-section {
      .desc-box {
        h4 {
          margin-top: -1px;
          margin-bottom: 15px;
          &.mt-5 {
            margin-top: 25px !important;
          }
        }
        p {
          margin-bottom: 10px;
        }
        .feature-dec,
        .ratio3_2,
        #floor_plan {
          h4 {
            margin-top: -2px;
          }
        }
        + .desc-box {
          margin-top: 20px;
        }
      }
    }
  }
  .single-property-images {
    .single-property-section {
      padding-bottom: 20px;
    }
    .slick-prev {
      left: 15px;
    }
    .slick-next {
      right: 15px;
    }
    &.ratio_40 {
      .bg-size {
        &:before {
          padding-top: 80%;
        }
      }
    }
  }
  .image_section {
    margin-bottom: 30px;
    .row {
      > div {
        margin-top: 15px;
      }
    }
  }
  .line-tab {
    &.nav-tabs {
      display: block;
      text-align: center;
      li {
        display: block;
      }
    }
  }
  // map css
  .map-section {
    iframe,
    .map {
      height: 300px;
    }
  }
  .infoBox {
    .marker-detail {
      width: 150px;
      img {
        width: 150px;
      }
      .detail-part {
        ul {
          li {
            &:last-child {
              padding-left: 0;
              display: block;
              margin-top: 4px;
            }
          }
        }
        span,
        a {
          font-size: 12px;
        }
      }
    }
  }
  .leaflet-marker-icon {
    &.leaflet-interactive {
      width: 30px !important;
      height: 30px !important;
    }
  }
  // header css
  .custom-dropdown {
    .custom-dropdown-menu {
      width: 280px;
    }
  }
  header {
    .menu {
      .header-right {
        .dropdown {
          .dropdown-menu {
            margin-top: 25px;
          }
        }
      }
    }
    &.header-1 {
      .menu {
        .header-right {
          li {
            a {
              svg {
                width: 15px;
              }
            }
          }
        }
      }
    }
    &.header-2 {
      &::after {
        bottom: 60px;
      }
      .menu {
        .header-right {
          li {
            a {
              svg {
                width: 15px;
              }
            }
          }
        }
      }
    }
    &.header-4 {
      .menu {
        .brand-logo {
          img {
            width: 110px;
          }
        }
        .header-right {
          li {
            .search-box {
              i {
                padding: 7px 8px;
                font-size: 11px;
                line-height: 1.2;
              }
              span {
                display: none;
              }
              .form-control {
                left: 40px;
              }
              .form-group {
                position: absolute;
                border: none;
                height: 100%;
                width: 185px;
                transform: translateY(-35px) scaleY(0);
                transition: 0.5s;
                top: 32px;
                right: 0;
                height: 45px;
                input {
                  border-radius: 5px;
                  opacity: 0;
                  visibility: hidden;
                  transition: unset;
                  padding: 10px 15px;
                }
                &::after {
                  left: 40px;
                }
                &::after,
                &::before {
                  border-radius: 5px;
                  transition: unset;
                }
              }
              &.open {
                i {
                  border-radius: 5px;
                }
                .form-group {
                  transform: translateY(0) scaleY(1);
                  transition: 0.5s;
                  input {
                    opacity: 1;
                    visibility: visible;
                    transition: unset;
                  }
                  &::before,
                  &::after {
                    transition: unset;
                  }
                }
                .form-control {
                  transition: unset;
                }
              }
            }
            > a {
              padding: 6px;
              svg {
                height: 14px;
                vertical-align: -2px;
              }
            }
            + li {
              margin-left: 4px;
            }
          }
        }
      }
    }
    &.light-header {
      .menu {
        .header-right {
          li {
            a {
              svg {
                width: 15px;
              }
            }
          }
        }
      }
    }
    &.header-9 {
      .menu {
        .brand-logo {
          margin-left: -16px;
        }
      }
    }
  }
  .toggle-nav {
    &.with-text {
      i {
        color: var(--theme-default7) !important;
      }
      span {
        display: block;
      }
    }
  }
  // home section css
  .fnc-slider {
    height: 460px;
  }
  .fnc-nav__control {
    width: 93px;
    font-size: 13px;
    height: 44px;
  }
  .fnc-slide__mask {
    display: none;
  }
  .fnc-slide__content {
    left: 50%;
    top: 34%;
    transform: translateX(-50%);
    padding: 20px;
    width: 80%;
  }
  .fnc-slide__heading-line {
    font-size: 32px;
  }
  .fnc-slide__action-btn {
    margin-left: 0;
    span {
      line-height: 25px;
      &:after {
        line-height: 25px;
      }
    }
  }
  .home-section {
    &.layout-5 {
      .home-main {
        padding: 40px 0;
        .home-content {
          > div {
            > img {
              width: 140px;
            }
          }
        }
      }
    }
    &.layout-6 {
      .home-slider-1 {
        margin-bottom: 30px;
      }
      .home-main {
        .home-content {
          > div {
            img {
              width: 120px;
            }
            h6 {
              margin-top: 10px;
            }
          }
        }
        .container {
          > .row {
            padding-top: 80px;
          }
        }
      }
      .container {
        > .row {
          padding: 40px 0;
        }
      }
    }
    &.layout-9 {
      padding: 40px 0;
      .home-main {
        .home-content {
          > div {
            > img {
              width: 120px;
            }
          }
          h6 {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .layout-home8 {
    height: 782px;
    .home-content {
      margin-bottom: 20px;
      h1 {
        margin-bottom: 10px;
      }
    }
  }
  .looking-icons {
    margin-top: 30px;
    h5 {
      margin-bottom: 20px;
    }
    ul {
      margin: -5px -10px;
      li {
        margin: 5px;
        .looking-icon {
          padding: 10px;
          min-width: 90px;
        }
      }
    }
  }
  .layout-home3 {
    .bg-layout-3 {
      padding: 40px 0;
    }
  }
  .layout-home2 {
    .home-main {
      padding-top: 90px;
    }
    .arrow-light {
      .slick-arrow {
        top: 61%;
      }
    }
    .feature-section {
      .feature-content {
        margin: 40px auto;
        padding: 10px;
      }
    }
  }
  .layout-map {
    .withmap-horizontal {
      .search-panel {
        .filter {
          padding: 20px 0;
        }
      }
    }
    &.header-map {
      height: 400px;
    }
  }
  .top-bar {
    .top-content {
      padding: 15px 0;
      .top-bar-right {
        li {
          .flat-box {
            padding: 5px 10px;
          }
        }
      }
    }
    &.top-bar-7 {
      .top-content {
        .top-bar-right {
          > li {
            a,
            .flat-box {
              padding: 4px 8px 6px;
            }
            a {
              svg {
                vertical-align: -4px;
                width: 14px;
                height: 14px;
              }
            }
          }
          li {
            .flat-box {
              padding: 4px 8px 5px;
              i {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
  .top-right-toggle {
    margin-left: 20px;
    svg {
      vertical-align: middle;
      color: $title-color;
      width: 20px;
      height: 20px;
    }
  }
  .sm-input {
    width: 170px;
  }
  .feature-3 {
    .next-image {
      display: none !important;
    }
  }
  .arrow-light {
    .slick-arrow {
      width: 30px;
      height: 30px;
    }
    .slick-prev {
      &:before {
        margin-left: -2px;
      }
    }
    .slick-next {
      &:before {
        margin-left: 3px;
      }
    }
  }
  .layout-home4 {
    .home-slider-4 {
      .slider-image {
        padding: 40px 0;
        .home-content {
          margin-bottom: 45px;
        }
      }
    }
    .sync-slider {
      .home-nav {
        .slick-list {
          margin-left: -5px;
          margin-right: -5px;
          .slick-track {
            .slick-slide {
              > div {
                margin-left: 5px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  .search-with-tab {
    .tab-content {
      padding: 20px;
      .review-form {
        > div {
          &:nth-child(n + 3) {
            margin-top: 16px;
          }
        }
      }
    }
    .nav-tabs {
      .nav-item {
        .nav-link {
          font-size: 14px;
        }
      }
    }
  }
  // label css
  .label-heart {
    transform: scale(0.8);
    &.ms-2 {
      margin-left: 4px !important;
    }
  }
  // title css
  .title-1 {
    padding-bottom: 20px;
    h2 {
      padding-top: 10px;
    }
  }
  .title-2 {
    h2 {
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 20px;
    }
  }
  .title-3 {
    margin-bottom: 20px;
    h2 {
      margin: 6px 0 6px;
    }
  }
  // breadcrumb css
  .breadcrumb-section {
    .breadcrumb-content {
      div {
        padding: 20px 25px;
        h2 {
          margin-bottom: 8px;
        }
      }
    }
  }
  // blog css
  .blog-section {
    &.bg-comman-2 {
      &::before {
        height: 248px;
      }
    }
  }
  .blog-1 {
    .blog-box {
      .img-box {
        img {
          min-width: 198px;
        }
      }
      .blog-content {
        padding: 15px;
        p {
          margin-bottom: 20px;
        }
      }
    }
    .slick-list {
      margin: -22px -12px;
      .slick-track {
        .slick-slide {
          > div {
            margin: 22px 12px;
          }
        }
      }
    }
  }
  .blog-2 {
    .slick-list {
      margin: 0 -12px;
      .slick-track {
        .slick-slide {
          > div {
            margin: 0 12px;
          }
        }
      }
    }
  }
  .horizontal-blog {
    .title-2 {
      p {
        margin-bottom: 13px;
      }
    }
  }
  .simple-blog {
    .title-3 {
      margin-bottom: 17px;
    }
  }
  // testimonial css
  .modern-client {
    .comment-right {
      position: relative;
      left: 0;
      bottom: 0;
      margin-top: 70px;
      padding: 35px 20px 20px;
      .media {
        display: block;
        .client-rating {
          margin-bottom: 10px;
        }
      }
      h3 {
        margin-bottom: 2px;
      }
    }
    .img-testimonial {
      z-index: 2;
      width: 30%;
      margin-left: 20px;
      position: absolute;
    }
    .label-heart {
      i {
        margin: 0 0px -2px 1px;
      }
    }
  }

  .testimonial-3 {
    .slick-list {
      margin: -30px -12px;
      .slick-track {
        .slick-slide {
          > div {
            margin: 30px 12px;
          }
        }
      }
    }
  }
  .client-slider {
    padding: 20px;
    h6 {
      font-size: 14px;
      letter-spacing: 10px;
    }
    p {
      font-size: 14px;
      margin-top: 50px;
    }
  }
  .slick-between {
    .slick-slider {
      .slick-list {
        margin: 0 -12px;
        .slick-track {
          .slick-slide {
            > div {
              margin: 0px 12px;
            }
          }
        }
      }
    }
  }
  .testimonial-style-1 {
    .title-2 {
      h2 {
        margin-top: -6px;
      }
    }
    .slick-dots {
      li {
        margin: 0 2px;
      }
    }
  }
  .testimonial-style-2 {
    .title-3 {
      margin-bottom: 17px;
    }
  }
  .testimonial-client {
    .title-3 {
      &.text-start {
        margin-bottom: 17px;
      }
    }
  }
  // full banner css
  .banner-inner {
    padding-top: 96px;
  }
  .banner-1,
  .banner-3 {
    padding: 20px;
  }
  .banner-1 {
    h2 {
      line-height: 1.5;
      margin-bottom: 20px;
      margin-top: 15px;
      letter-spacing: normal;
    }
  }
  .banner-section {
    .button-banner {
      .btn {
        + .btn {
          margin-left: 4px;
        }
      }
    }
  }
  .banner-3 {
    h2 {
      line-height: 1.35;
      margin: 15px auto 10px;
    }
    p {
      margin-bottom: 15px;
    }
  }
  .banner-2 {
    .banner-right {
      padding: 20px;
      h6 {
        margin-top: -4px;
      }
    }
  }
  // about css
  .about-section {
    .title-1 {
      h2 {
        margin-bottom: -2px;
      }
    }
    .about-wrap {
      .about-content {
        .our-details {
          width: 85% !important;
          a {
            margin-top: -3px;
            display: block;
          }
        }
      }
    }
    &.ratio_square {
      .title-3 {
        p {
          margin-bottom: -3px;
        }
      }
    }
    .title-3 {
      &.text-start {
        margin-top: -4px;
        p {
          margin-bottom: -3px;
        }
      }
    }
    .about-2 {
      &.about-wrap {
        .about-content {
          .our-details {
            padding: 20px;
          }
        }
      }
    }
    &.about-inner {
      .title-2 {
        h2 {
          margin-top: -5px;
        }
      }
    }
  }
  .arrow-white {
    margin-bottom: 65px;
    .slick-arrow {
      bottom: -60px;
    }
    .slick-prev {
      left: 37%;
    }
    .slick-next {
      right: 37%;
    }
  }
  .about-box {
    .agent-image {
      .overlay-agent {
        .agent-details {
          padding: 20px;
          h5 {
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            margin: 10px 0;
          }
          ul {
            li {
              a {
                width: 30px;
                height: 30px;
                > img {
                  height: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  .about-people {
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
  }
  // service css
  .service-section {
    .title-2 {
      margin-bottom: -7px;
    }
    .property-service {
      > div {
        .service-box {
          padding: 20px;
          .icon-round {
            width: 80px;
            height: 80px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .title-2 {
      margin-bottom: -4px;
      h2 {
        margin-top: -3px;
      }
    }
    &.service-bg {
      .title-3 {
        margin-top: -3px;
      }
    }
    &.service-2 {
      .property-service {
        > div {
          .service-box {
            padding: 20px;
            p {
              margin-bottom: 20px;
            }
            .hover-line {
              margin-bottom: 25px;
            }
          }
          .icon-line-color {
            bottom: -20px;
          }
        }
      }
    }
    .property-service {
      > div {
        .service-box {
          p {
            font-size: 13px;
            margin-bottom: -1px;
          }
        }
      }
    }
  }
  .service-slider {
    .slick-list {
      margin-top: -15px;
      margin-bottom: -24px;
      .slick-track {
        .slick-slide {
          > div {
            margin-top: 24px;
            margin-bottom: 24px;
          }
        }
      }
    }
    .service-wrapper {
      .service-details {
        padding: 40px 20px 20px;
        p {
          font-size: 13px;
        }
        h3 {
          margin-bottom: 10px;
        }
      }
      .top-img-box {
        margin-left: 20px;
      }
    }
  }
  .why-choose {
    .title-2 {
      margin-bottom: -4px;
    }
  }
  // property css
  .slick-shadow {
    .arrow-white {
      .slick-arrow {
        bottom: -53px;
      }
    }
  }
  .property-section {
    .title-1 {
      h2 {
        margin-bottom: -7px;
      }
    }
    .filter-panel {
      margin-bottom: 20px;
      .top-panel {
        h2 {
          margin-top: -3px;
        }
      }
    }
    .title-2 {
      margin-bottom: -7px;
      h2 {
        margin-top: -3px;
      }
    }
    .title-3 {
      margin-bottom: 17px;
      &.text-start {
        h2 {
          margin-top: -3px;
        }
      }
      &.d-flex {
        margin-bottom: 20px;
      }
    }
    &.slick-between {
      .title-3 {
        &.text-start {
          margin-top: -5px;
        }
      }
    }
    .ratio_landscape {
      .title-2 {
        margin-bottom: -4px;
      }
    }
    .property-box {
      .property-image {
        .overlay-plus {
          span {
            font-size: 56px;
          }
        }
      }
      .property-details {
        .icon-property {
          li {
            .d-flex {
              .property-icon {
                width: 30px;
                height: 30px;
                min-width: 30px;
                margin-right: 10px;
              }
            }
          }
        }
        .property-svg {
          width: 14px;
          height: 14px;
          display: block;
          margin-top: -1px;
          margin-left: 1px;
        }
      }
    }
    .property-box-flat {
      .property-box {
        .property-details {
          h3 {
            margin-top: -4px;
          }
        }
      }
    }
    .property-1 {
      .property-box {
        .property-image {
          .labels-left {
            .label {
              padding: 4px 10px 3px;
            }
          }
        }
      }
    }
    .property-3 {
      .slick-list {
        margin: -30px -12px;
        .slick-track {
          .slick-slide {
            > div {
              margin: 30px 12px;
            }
          }
        }
      }
    }
    .list-property {
      .property-box {
        .property-image {
          .overlay-property {
            .overlay-box {
              padding: 20px;
            }
          }
        }
      }
    }
    .listing-hover-property {
      margin-bottom: -20px;
      > div {
        margin-bottom: 20px;
      }
      .property-box {
        .bottom-property {
          bottom: -60px;
          h5 {
            margin-bottom: 4px;
          }
          > div {
            padding: 10px;
          }
        }
      }
    }
    .property-map {
      .filter-panel {
        margin-bottom: 0;
      }
    }
  }
  .property-map {
    .filter-panel {
      margin-bottom: 0;
    }
  }
  .grid-slider,
  .thumbnail-slider,
  .main-property-slider {
    .slick-arrow {
      width: 30px;
      height: 30px;
    }
  }
  .property-grid-slider {
    .property-grid {
      .slider-for {
        .slick-slide {
          #block {
            height: 305px !important;
          }
          video {
            top: 40% !important;
          }
        }
      }
    }
  }
  .property-grid-3 {
    .filter-panel {
      .top-panel {
        h2 {
          margin-top: -3px;
        }
      }
    }
    .property-grid {
      &.list-view {
        .property-box {
          .agent-image {
            &:hover {
              .overlay-content {
                left: 52%;
              }
            }
          }
        }
      }
    }
  }
  // feature css
  .arrow-gradient {
    .slick-arrow {
      width: 30px;
      height: 30px;
      &::before {
        font-size: 13px;
      }
    }
  }
  .feature-section {
    .title-3 {
      margin-bottom: 17px;
    }
    .feature-2 {
      .slick-dots {
        bottom: -15px;
      }
      .feature-box {
        .feature-bottom {
          padding: 20px;
          h3 {
            margin-bottom: 0;
          }
        }
      }
      &.dot-gradient {
        margin-bottom: 15px !important;
      }
    }
    .feature-image,
    .feature-content {
      box-shadow: none;
    }
    .feature-image {
      .label {
        padding: 5px 10px 3px;
      }
      .label-solid {
        &::before {
          top: 27px;
        }
      }
    }
    .feature-1 {
      .feature-wrapper {
        .property-details {
          max-width: 430px;
          padding: 20px;
          .round-half {
            top: -22px;
            right: -17px;
            transform: scale(0.8);
            i {
              margin-top: 18px;
              margin-left: 0;
            }
          }
          a {
            .d-flex {
              display: block !important;
            }
            h3 {
              .label {
                margin-left: 0;
              }
              span {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
    .feature-content {
      .feature-price {
        margin-bottom: 5px;
        li {
          h3 {
            margin-top: -4px;
            margin-bottom: 0;
          }
        }
      }
      .details,
      .feature-price {
        padding: 20px;
      }
      .detail-list {
        padding-left: 0 !important;
        padding-right: 0 !important;
        li {
          &:first-child {
            margin-left: 0;
          }
          .d-flex {
            h6 {
              font-size: 14px;
            }
          }
        }
      }
      .details {
        h3 {
          margin-top: -5px;
        }
        p {
          margin-bottom: -3px;
        }
      }
    }
    .feature-3 {
      .slick-list {
        margin-left: -12px;
        margin-right: -12px;
        .slick-track {
          .slick-slide {
            > div {
              margin-left: 12px;
              margin-right: 12px;
            }
          }
        }
      }
      .feature-content {
        .details,
        .feature-price {
          padding: 20px 0;
        }
        .feature-price {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
    .feature-4 {
      margin-top: 30px;
      &.arrow-gradient {
        .slick-arrow {
          top: 15px;
        }
      }
      &.slick-initialized {
        .slick-slide {
          margin-bottom: -5px;
        }
      }
    }
    .title-1 {
      h2 {
        margin-bottom: -2px;
      }
    }
    .modern-feature {
      .feature-wrap {
        .feature-content {
          margin-top: 20px;
          .details,
          .feature-price {
            padding: 20px 0;
          }
          .detail-list {
            padding: 15px 0;
          }
        }
      }
    }
  }
  .box {
    width: 220px;
    height: 105px;
    &.text-affect {
      .title {
        height: 78px;
      }
    }
  }
  .parallax-home {
    &.video-layout {
      .row {
        > div {
          &:last-child {
            order: unset;
          }
        }
      }
    }
    .parallax-right {
      width: 75%;
    }
  }
  .bg-comman-2 {
    &.feature-section {
      .title-2 {
        h2 {
          margin-top: -3px;
        }
      }
    }
  }
  .new-property {
    .feature-section {
      .feature-content {
        .feature-price {
          margin-bottom: 0;
          li {
            h3 {
              margin-top: -4px;
            }
          }
          h6 {
            margin-bottom: -1px;
          }
        }
        .detail-list {
          padding: 15px 15px !important;
          li {
            &:first-child {
              margin-left: 5px;
            }
          }
        }
        .details {
          p {
            margin-bottom: -5px;
          }
        }
      }
    }
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
  }
  .home-main {
    &.feature-section {
      &.new-property {
        .feature-content {
          .detail-list {
            padding: 15px 15px !important;
            li {
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  .layout-home2 {
    .feature-content {
      .detail-list {
        padding: 15px 20px !important;
        li {
          &:first-child {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .pricing-property {
    .feature-image,
    .feature-content {
      box-shadow: 0 0 23px rgba(0, 0, 0, 0.08);
    }
  }
  // other page css
  .video-section {
    padding-left: 0;
    padding-right: 0;
    .play-icon {
      height: auto;
    }
  }
  .icon-video {
    transform: scale(0.8);
  }
}
@media (max-width: 567px) {
  .theme-card {
    .title-3 {
      p {
        margin-bottom: -5px;
      }
    }
  }
}
@media (max-width: 544px) {
  .theme-card {
    .title-2 {
      margin-bottom: -3px;
    }
  }
  .simple-blog {
    .title-3 {
      margin-bottom: 14px;
    }
  }
  .testimonial-client {
    .title-3 {
      &.text-start {
        margin-bottom: 14px;
      }
    }
  }
  .pricing-section {
    .title-3 {
      margin-bottom: 14px;
    }
  }
  // property css
  .property-section {
    .title-3 {
      margin-bottom: 14px;
    }
  }
  // feature css
  .feature-section {
    .title-3 {
      margin-bottom: 14px;
    }
  }
}
@media (max-width: 526px) {
  .testimonial-style-2 {
    .title-3 {
      margin-bottom: 14px;
    }
  }
  // about css
  .about-section {
    &.ratio_square {
      .title-3 {
        p {
          margin-bottom: -6px;
        }
      }
    }
    .title-3 {
      &.text-start {
        p {
          margin-bottom: -6px;
        }
      }
    }
  }
  // property css
  .property-section {
    .ratio_landscape {
      .title-2 {
        margin-bottom: -7px;
      }
    }
  }
}
@media (max-width: 518px) {
  .new-property {
    .title-2 {
      margin-bottom: -4px;
    }
    .feature-section {
      .feature-content {
        .details {
          p {
            margin-bottom: -2px;
          }
        }
      }
    }
  }
  // blog css
  .horizontal-blog {
    .title-2 {
      p {
        margin-bottom: 16px;
      }
    }
  }
  // service css
  .service-section {
    .title-2 {
      margin-bottom: -4px;
    }
  }
}
@media (max-width: 502px) {
  .property-section {
    .title-2 {
      margin-bottom: -4px;
    }
  }
}
@media (max-width: 480px) {
  .blog-section{
    &.bg-comman-2{
      .blog-1{
        .owl-item > div{
          margin: 0 !important;
          .blog-box{
            padding-left: 0;
          }
        }
      }
    }
  }
  .layout-home4{
    .slider-image{
      .home-content{
        .font-color4{
          &::after{
            display: none;
          }
        }
      }
    }
  }
  .feature-section{
    .title-1{
      hr{
        margin: 14px auto 0;
      }
    }
  }
  .loader-wrapper{
    .loader-text{
      h3{
        font-size: 18px;
      }
    }
  }
  .loader-text{
    img{
      height: 100px;
    }
  }
  .layout-map.layout-home2 {
    min-height: auto;
  }
  .compare-page {
    .table-wrapper {
      .table {
        .property-name {
          min-width: 120px;
        }
      }
    }
  }
  .details-right {
    margin-top: 18px;
  }
  .horizontal-blog {
    .blog-1 {
      .slick-prev,
      .slick-next {
        display: none !important;
      }
      .blog-box {
        .blog-content {
          a.btn-lg {
            padding: 6px 15px;
          }
        }
      }
    }
  }
  .blog-section {
    &.bg-comman-2 {
      .blog-1 {
        .blog-box {
          .blog-content {
            a.btn-lg {
              padding: 6px 15px;
            }
          }
        }
      }
    }
  }
  .property-details {
    h3 {
      margin-bottom: 6px;
    }
    ul,
    p {
      margin-bottom: 12px;
    }
  }
  .property-section {
    .property-box {
      .property-image {
        .seen-data {
          top: 14px;
          right: 14px;
          svg {
            margin-right: 4px;
          }
        }
      }
    }
  }
  .home-section.layout-5 {
    .row {
      > div {
        p {
          margin-bottom: 18px;
        }
      }
    }
  }
  .service-section {
    .property-service {
      > div {
        .service-box {
          .icon-round {
            width: 55px;
            height: 55px;
            svg {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }
  .layout-home4 {
    .home-slider-4 {
      .slider-image {
        .home-content {
          padding: 20px 18px 20px 18px;
        }
      }
    }
  }
  .blog-section.bg-comman-2 {
    .blog-1 {
      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }
  }
  .layout-home4 .slider-image .home-content .font-color4 {
    font-size: 12px;
  }
  .layout-home4 .sync-slider .home-slider-4 img.img-icon {
    display: none;
  }

  .property-section {
    .property-box {
      .property-image {
        .property-slider {
          .slick-dots {
            li {
              button {
                &::before {
                  font-size: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
  .testimonial-style-2 {
    .title-3 {
      margin-top: -11px;
    }
  }
  .theme-title {
    .theme-card {
      .card-header {
        h4 {
          margin-top: -1px;
        }
      }
    }
  }
  .loader-text {
    h3 {
      margin-top: -15px;
    }
  }
  .provide-service {
    .service-img {
      width: 200px;
      margin: 0 auto;
    }
  }
  .blog-1 {
    .blog-box {
      .img-box {
        img {
          height: 300px;
          object-fit: cover;
        }
      }
    }
    &.slick-initialized {
      .slick-slide {
        margin-bottom: -6px;
      }
    }
  }
  .blog-single-detail {
    .comment-section {
      .comment-box {
        .media {
          img {
            width: 50px;
          }
          .media-body {
            .comment-title {
              .comment-date,
              .comment-user {
                i {
                  margin-right: 5px;
                  line-height: 1.2;
                }
              }
            }
          }
        }
      }
    }
  }
  header {
    &.light-header {
      .menu {
        .brand-logo {
          width: 110px;
        }
      }
    }
    &.header-9 {
      .menu {
        .brand-logo {
          width: auto;
        }
      }
    }
  }
  // service section css
  .service-section,
  .about-section {
    .title-3 {
      margin-top: -11px;
    }
  }
  // testimonial css
  .modern-client {
    .img-testimonial {
      width: 30%;
    }
    .comment-right {
      margin-top: 45px;
      padding-top: 30px;
    }
  }
  // button css
  .btn {
    &.btn-lg {
      padding: 6px 15px;
      line-height: 1.3;
    }
  }
  // login page css
  .signup-modal {
    .signup-tab {
      padding: 20px;
      form {
        .form-group {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .btn-social {
    span {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .social-connect {
    > div {
      &:nth-child(odd) {
        padding-right: 5px;
      }
      &:nth-child(even) {
        padding-left: 5px;
      }
    }
  }
  // user dashboard css
  .user-dashboard {
    .dashboard-content {
      .user-wrapper {
        .overview {
          .overview-content {
            display: block;
            li {
              + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
  // coming soon css
  .coming-soon {
    .coming-soon-detail {
      .timer {
        li {
          font-size: 13px;
          > div {
            width: 70px;
            height: 70px;
          }
          + li {
            margin-left: 10px;
            padding-left: 10px;
            &::before {
              font-size: 20px;
            }
          }
        }
      }
    }
    &.coming-left {
      .coming-soon-detail {
        .timer {
          li {
            padding: 15px 10px;
          }
        }
      }
    }
  }
  .coming-simple {
    .ratio2_3 {
      > img {
        height: 350px;
      }
      &.order-lg-1 {
        margin-top: -38px;
      }
    }
  }
  // submit property css
  .property-wizard {
    .wizard-box {
      .wizard-step-container {
        .wizard-steps {
          display: block;
          flex-wrap: unset;
          li {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
  // faq page css
  .faq-section {
    .faq-image {
      margin-top: -36px;
      img {
        width: 100%;
      }
    }
  }
  // home section css
  .home-section {
    &.layout-5 {
      .home-main {
        .looking-icons {
          display: none;
        }
      }
      .row {
        > div {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
  .layout-home3 {
    .banner-3 {
      width: 90%;
    }
  }
  .parallax-home {
    .parallax-right {
      width: 100%;
      opacity: 0.1;
    }
  }
  // about us css
  .user-about {
    .about-listing {
      ul {
        flex-wrap: wrap;
        li {
          flex: 0 0 50%;
          max-width: 50%;
          h4 {
            margin-bottom: 4px;
          }
          &:nth-child(n + 3) {
            margin-top: 15px;
          }
          + li {
            margin-left: 0;
          }
        }
      }
    }
    .about-image {
      .img-box {
        &.img-abs {
          right: 50px;
        }
      }
    }
  }
  // contact us
  .captcha {
    .text {
      font-size: 13px;
    }
  }
  .spinner {
    margin: 10px 10px 7px 10px !important;
  }
  .download-section {
    .download-wrap {
      ul {
        li {
          + li {
            margin-left: 5px;
          }
        }
      }
    }
  }
  // blog css
  .blog-1 {
    .blog-box {
      display: block;
      padding-left: 0;
      .blog-content {
        padding: 20px;
        > span {
          margin-top: -4px;
          display: block;
        }
        h3 {
          margin: 10px 0 8px;
        }
      }
      .img-box {
        img {
          border-radius: 0;
        }
        &::after {
          border-radius: 0;
        }
      }
    }
  }
  // property css
  .property-section {
    .nav-tabs {
      .nav-item {
        + .nav-item {
          margin-left: 15px;
        }
      }
    }
    .property-box {
      .property-image {
        .labels-left {
          top: 15px;
        }
        .overlay-plus {
          .like-bottom {
            bottom: 15px;
            right: 15px;
          }
          .seen-data {
            top: 15px;
            right: 15px;
          }
        }
        .overlay-property-box {
          .effect-round,
          .effect-round1 {
            width: 35px;
            height: 35px;
            i {
              font-size: 14px;
              margin-top: 3px;
              margin-left: 1px;
            }
          }
        }
      }
      .property-details {
        .icon-property {
          li {
            + li {
              &::before {
                left: -16px;
              }
            }
          }
        }
      }
    }
    .list-property {
      .property-box {
        .property-details {
          .icon-property {
            li {
              + li {
                &::before {
                  left: -18px;
                }
              }
            }
          }
        }
        .property-image {
          .bg-size {
            &:before {
              padding-top: 80%;
            }
          }
          .overlay-property {
            .overlay-box {
              p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
    .property-2,
    .property-box-flat {
      .property-box {
        .property-image {
          .labels-left {
            left: -3px;
          }
        }
      }
    }
    .property-box-flat {
      .property-box {
        .property-image {
          .label-white {
            right: 15px;
            top: 15px;
          }
          .overlay-plus {
            .seen-data {
              bottom: 15px;
              right: 50px;
            }
          }
        }
      }
    }
  }
  // single property
  .gallery-nav {
    margin: 2px 0 0 -2px;
    .slick-slide {
      margin: 0 2px;
      img {
        min-height: 37px;
      }
    }
  }
  .single-property-section {
    .left-single {
      > ul {
        > li {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
  .single-property {
    .description-section {
      .desc-box {
        .single-gallery {
          margin-bottom: -5px;
        }
      }
    }
  }
  // feature css
  .feature-section {
    .feature-4 {
      &.arrow-gradient {
        .slick-prev {
          right: 12% !important;
        }
      }
      &.slick-initialized {
        .slick-slide {
          margin-bottom: -3px;
        }
      }
    }
    .feature-1 {
      .feature-wrapper {
        .feature-image {
          .label-white {
            top: 15px;
            right: 15px;
          }
          h4 {
            font-size: 14px;
          }
        }
      }
    }
    .feature-image {
      .feature-overlay {
        width: 126px !important;
        height: 100px !important;
        span {
          font-size: 40px;
          margin-top: 0;
        }
      }
      .label {
        text-transform: capitalize;
      }
    }
    .modern-feature {
      .feature-wrap {
        .feature-image {
          .label-gradient {
            top: 15px;
            left: 15px;
          }
        }
        .feature-content {
          .details,
          .feature-price {
            padding: 20px 0;
          }
        }
      }
    }
    .feature-content {
      .feature-price {
        margin-bottom: -3px;
        li {
          + li {
            margin-left: 20px;
          }
        }
      }
      .details {
        p {
          margin-top: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .detail-list {
        margin: 0;
        li {
          margin: 5px;
          .d-flex {
            .label-lg {
              padding: 6px 12px;
            }
          }
        }
      }
    }
  }
  .new-property {
    .feature-section {
      .feature-content {
        .details {
          h3 {
            margin-top: -4px;
          }
        }
        .feature-price {
          h6 {
            margin-bottom: -6px;
          }
        }
      }
    }
  }
  // other page css
  .video-modal {
    iframe {
      height: 200px;
    }
    .modal-dialog {
      .modal-content {
        .modal-body {
          .close {
            right: 0;
            top: -30px;
          }
        }
      }
    }
  }
  .offer-section {
    .offer-wrapper {
      padding: 20px;
      text-align: center;
      .media {
        display: block;
        .offer-icon {
          margin: 0 auto 20px;
        }
        .media-body {
          margin-left: 0;
          h6 {
            display: inline-block;
          }
        }
      }
    }
  }
  .video-details {
    p,
    h2 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    h2 {
      -webkit-line-clamp: 2;
    }
  }
}
@media (max-width: 437px) {
  .portfolio-grid {
    .filter-panel {
      .filters {
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            min-width: calc(50% - 4px);
          }
        }
      }
    }
  }
  .blog-single-detail {
    .blog-title {
      .post-detail {
        li {
          &:last-child {
            padding-left: 15px;
            margin-left: 15px;
            border-left: 1px solid $light-border;
          }
          &:nth-last-child(2) {
            padding-left: 0;
            margin-left: 0;
            border: none;
          }
        }
      }
    }
  }
  .about-section .about-1 .owl-carousel .owl-nav {
    .owl-next {
      right: 32%;
    }
    .owl-prev {
      left: 33%;
    }
  }
  // service css
  .service-slider {
    .slick-list {
      margin-top: -33px;
    }
  }
  .service-section {
    .title-3 {
      &.text-start {
        p {
          margin-bottom: 23px;
        }
      }
    }
  }
}
@media (max-width: 420px) {
  .review{
    .review-box{
      .media{
        .media-body{
          p.mb-0{
            width: 100%;
          }
        }
      }
    }
  }
  .top-bar {
    .header-right {
      .right-menu {
        ul {
          li.cart {
            ul.nav-submenu {
              width: 250px;
              li{
                .media{
                  img{
                    height: 50px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .video-layout {
    height: 710px;
  }
  .property-section {
    .ratio_55 {
      .bg-size {
        &::before {
          padding-top: 60%;
        }
      }
    }
  }
  h2 {
    font-size: 22px;
  }
  .terms-section {
    h2 {
      margin-top: -3px;
    }
  }
  .new-property {
    .title-2 {
      h2 {
        margin-top: -3px;
      }
    }
  }
  .testimonial-style-1 {
    .title-2 {
      h2 {
        margin-top: -5px;
      }
    }
  }
  .my-gallery {
    .title-1 {
      h2 {
        margin-bottom: -3px;
      }
    }
  }
  .about-section {
    .title-1 {
      h2 {
        margin-bottom: -3px;
      }
    }
  }
  .custom-dropdown {
    .custom-dropdown-menu {
      &.form-dropdown {
        right: -70px;
      }
    }
  }
  // home section css
  .home-section {
    &.layout-5 {
      padding-top: 46px;
      padding-bottom: 30px;
      .home-main {
        .home-content {
          > div {
            > img {
              width: 120px;
            }
          }
        }
      }
    }
  }
  .parallax-home {
    .container {
      > .row {
        height: 200px;
      }
    }
  }
  // blog css
  .blog-section {
    .title-2 {
      h2 {
        margin-top: -3px;
      }
    }
  }
  // other css
  .new-property {
    &.pricing-property {
      .title-2 {
        h2 {
          margin-top: -3px;
        }
      }
    }
  }
  .offer-section {
    &.offer-inner {
      .title-2 {
        h2 {
          margin-top: -3px;
        }
      }
    }
  }
  // about us
  .about-main,
  .why-choose,
  .blog-inner {
    .title-2 {
      h2 {
        margin-top: -3px;
      }
    }
  }
  .about-people {
    .title-2 {
      h2 {
        margin-top: -3px;
      }
    }
  }
  .blog-inner {
    .title-2 {
      margin-top: -3px;
    }
  }
  .user-about {
    .about-image {
      .side-left {
        .side-effect {
          &::before,
          &::after {
            width: calc(100% - 280px);
          }
        }
      }
    }
  }
  // submit property css
  .property-wizard {
    .wizard-box {
      .wizard-form-details {
        h2 {
          margin-top: -3px;
        }
      }
    }
    &.horizontal-wizard {
      .wizard-box {
        .wizard-step-container {
          .wizard-form-details {
            .text-end {
              p {
                a {
                  margin-left: auto;
                  display: table;
                  text-align: right;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  // google map css
  .infoBox {
    > img {
      margin: 0px -13px -21px 0px !important;
      width: 16px;
    }
    .marker-detail {
      width: 130px;
      margin-left: -10px;
      img {
        width: 130px;
      }
    }
  }
  .leaflet-popup-content {
    .infoBox {
      .marker-detail {
        margin-left: -2px;
      }
    }
  }
  // about css
  .about-box {
    .bg-size {
      &:before {
        padding-top: 108%;
      }
    }
  }
  // contact us
  .download-section {
    .app-right {
      .input-app {
        display: block;
      }
      .form-group {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
  .contact-3 {
    .title-3 {
      margin-top: -3px;
    }
  }
  // coming soon css
  .coming-simple {
    .ratio2_3 {
      &.order-lg-1 {
        margin-top: -30px;
      }
    }
    .site-construction {
      h4 {
        font-size: 15px;
      }
    }
  }
  .coming-soon {
    .cloud-img {
      height: 130px;
      top: 100px;
    }
  }
  // home section css
  .layout-home2 {
    .feature-section {
      .feature-content {
        .label-cross {
          font-size: 13px;
        }
      }
    }
  }
  .layout-home4 {
    .sync-slider {
      .home-nav {
        left: 15px;
        .slider-image {
          width: 75px;
          height: 54px;
        }
      }
    }
    .slider-image {
      .home-content {
        padding-right: 20px;
        h1 {
          margin-bottom: 8px;
        }
        p {
          margin-bottom: 20px;
        }
        h2 {
          margin-top: 20px;
        }
      }
    }
  }
  .home-main {
    &.feature-section {
      .feature-price {
        display: block;
        li {
          + li {
            margin-left: 0;
          }
        }
      }
    }
  }
  // header css
  .top-bar {
    .top-content {
      .top-bar-right {
        li {
          .flat-box {
            .fa-home {
              margin-right: 0;
            }
            span {
              display: none;
            }
          }
        }
      }
    }
  }
  .header-layout-3 {
    .contact-number {
      i {
        padding: 6px;
        margin-right: 6px;
      }
    }
  }
  // property css
  .property-section {
    .property-box {
      .property-details {
        .icon-property {
          li {
            padding-left: 0;
            + li {
              &::before {
                display: none;
              }
            }
          }
          + .icon-property {
            margin-top: 15px;
          }
        }
      }
    }
    .listing-hover-property {
      .property-box {
        &:hover {
          &::after {
            background-image: linear-gradient(
              to bottom,
              transparent 35%,
              rgba(0, 0, 0, 0.8) 100%
            );
          }
        }
      }
    }
  }
  .property-grid-3 {
    .filter-panel {
      .top-panel {
        .grid-list-filter {
          li {
            display: block;
            .dropdown-toggle,
            .filter-bottom-title {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .property-grid-slider {
    .property-grid {
      .slider-for {
        .slick-slide {
          video {
            top: 36% !important;
          }
        }
      }
    }
  }
  // feature css
  .feature-section {
    .title-1 {
      h2 {
        margin-bottom: -3px;
      }
    }
    .feature-content {
      .feature-price {
        display: block;
        margin-bottom: -2px;
        li {
          display: block;
          .btn {
            margin-top: 15px;
          }
          + li {
            margin-left: 0;
          }
        }
      }
    }
    .feature-4 {
      &.slick-initialized {
        .slick-slide {
          margin-bottom: 2px;
        }
      }
    }
    .feature-1 {
      .feature-wrapper {
        .property-details {
          max-width: 360px;
        }
      }
    }
  }
  // login css
  .login-wrap {
    .log-in {
      .title-3 {
        &.text-start {
          h2 {
            margin-top: 0px;
          }
        }
      }
    }
  }
  .sign-up {
    .title-3 {
      &.text-start {
        h2 {
          margin-top: 2px;
        }
      }
    }
  }
}
@media (max-width: 413px) {
  .theme-card {
    .title-3 {
      p {
        margin-bottom: -3px;
      }
    }
  }
}
@media (max-width: 404px) {
  .footer-bg {
    .bottom-blog {
      .slick-initialized {
        .slick-slide {
          margin-bottom: -1px;
        }
      }
    }
  }
}
@media (max-width: 391px) {
  .my-gallery {
    .title-1 {
      h2 {
        margin-bottom: -7px;
      }
    }
  }
}
@media (max-width: 388px) {
  .service-slider {
    .slick-list {
      margin-top: -30px;
    }
  }
  .simple-blog {
    .title-3 {
      margin-bottom: 17px;
    }
  }
  .pricing-section {
    .title-3 {
      margin-bottom: 17px;
    }
  }
  // about css
  .about-section {
    &.ratio_square {
      .title-3 {
        p {
          margin-bottom: -3px;
        }
      }
    }
    .title-3 {
      &.text-start {
        p {
          margin-bottom: -3px;
        }
      }
    }
  }
  // testimonial css
  .testimonial-client {
    .title-3 {
      &.text-start {
        margin-bottom: 17px;
      }
    }
  }
  .testimonial-style-2 {
    .title-3 {
      margin-bottom: 17px;
    }
  }
  // property css
  .property-section {
    .title-3 {
      margin-bottom: 17px;
    }
    .ratio_landscape {
      .title-2 {
        margin-bottom: -4px;
      }
    }
  }
  // feature css
  .feature-section {
    .title-3 {
      margin-bottom: 17px;
    }
  }
}
@media (max-width: 375px) {
  .banner-section.parallax-image .banner-2 .banner-right .button-banner .btn-light-bg {
    margin-left: 0;
  }
  .portfolio-grid {
    .filter-panel {
      .filters {
        ul {
          li {
            min-width: 160px;
          }
        }
      }
    }
  }
  .blog-single-detail {
    .blog-title {
      .post-detail {
        li {
          display: block;
          padding-left: 0 !important;
          margin-left: 0 !important;
          border: none !important;
        }
      }
    }
  }
  .coming-soon {
    .coming-soon-detail {
      .timer {
        li {
          > div {
            width: 62px;
            height: 62px;
          }
          + li {
            margin-left: 5px;
            padding-left: 5px;
            &::before {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 360px) {
  .home-section{
    .home-slider-1{
      .owl-carousel{
        .owl-prev, .owl-next{
          width: 35px;
          height: 35px;
          .fa{
            margin-top: 7px;
          }
        }
      }
    }
  }
  .single-property-section .left-single h2{
    font-size: 21px;
  }
  .dropzone ngx-dropzone {
    padding: 30px 12px;
  }
  .new-property{
    .feature-section{
      .feature-content{
        .detail-list{
          li{
            &:first-child{
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .feature-section{
    .feature-1{
      .slick-list{
        .slick-track{
          .slick-slide{
            > div{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .title-1{
      hr{
        margin: 8px auto 0;
      }
    }
  }
  .property-section{
    .listing-hover-property{
      .property-box{
        .bottom-property{
          .overlay-option{
            ul{
              li{
                padding: 16px 10px;
              }
            }
          }
        }
      }
    }
  }
  .review{
    .review-box{
      .media{
        .media-body{
          margin-top: 0;
        }
        .rating{
          top: 46px;
          left: 63px;
        }
      }
    }
  }
  .single-property{
    .description-section{
      .desc-box{
        p{
          margin-bottom: 22px;
        }
      }
    }
  }
  .property-section {
    .property-box {
      .property-details {
        .icon-property {
          li {
            .d-flex {
              .property-icon {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }
  .portfolio-grid {
    .filter-panel {
      .filters {
        ul {
          li {
            min-width: 155px;
          }
        }
      }
    }
  }
  .log-in {
    .btn.btn-dashed {
      padding: 9px 14px;
    }
  }
  .social-connect {
    > div {
      &:nth-child(odd) {
        padding-left: 5px;
      }
      &:nth-child(even) {
        padding-right: 3px;
      }
    }
  }
  .feature-section {
    .feature-content {
      .detail-list {
        li {
          &:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  .toggle-nav {
    padding-top: 22px;
  }
  .blog-single-detail {
    .blog-title {
      .post-detail {
        li {
          display: block;
          + li {
            padding-left: 0;
            margin-left: 0;
            border: none;
          }
        }
      }
    }
  }
  .feature-section .feature-1 .feature-wrapper .property-details ul li img {
    display: none;
  }

  li .parallax-home.video-layout {
    height: 280px;
  }
  .banner-2 {
    .banner-right {
      .button-banner {
        .btn {
          + .btn {
            &:after {
              height: 50px;
            }
          }
        }
      }
    }
  }
  .property-section {
    .property-box {
      .property-details {
        > ul {
          > li {
            > img {
              margin-right: 3px;
              height: 14px;
            }
          }
        }
      }
    }
  }
  .fnc-slide__content {
    width: 88%;
  }
  .banner-2 {
    .banner-right {
      .button-banner {
        .btn-light-bg {
          &::after {
            height: 50px;
          }
        }
      }
    }
  }
  .property-details {
    h3 {
      margin-bottom: 3px;
    }
  }
  header {
    .header-right {
      .right-menu {
        ul {
          .dropdown {
            padding: 10px 2px;
          }
        }
      }
    }
  }
  header {
    &.header-9 {
      .menu {
        .brand-logo {
          margin-left: -14px;
        }
      }
    }
  }
  .property-section {
    .property-box {
      .property-image {
        .overlay-property-box {
          right: 8px;
          bottom: 2px;
        }
      }
    }
  }
  .feature-section {
    .feature-content {
      .detail-list {
        li {
          .d-flex {
            .label-lg {
              padding: 5px 12px;
              font-size: 14px;
            }
          }
        }
      }
    }
    .feature-4 {
      &.slick-initialized {
        .slick-slide {
          margin-bottom: -4px;
        }
      }
    }
    .feature-1 {
      .feature-wrapper {
        .property-details {
          max-width: 280px;
        }
      }
    }
  }
  .blog-1 {
    .blog-box {
      .img-box {
        img {
          height: 260px;
        }
      }
    }
  }
  .blog-inner {
    .title-2 {
      margin-bottom: -4px;
    }
  }
  // portfolio css
  .grid-box {
    .property-text {
      h3 {
        margin-top: -3px;
      }
    }
  }
  // login css
  .log-in {
    form {
      .d-flex {
        display: block !important;
        a {
          display: block;
          margin-top: 10px;
        }
        &.next-btn {
          display: flex !important;
        }
      }
    }
    .btn-social {
      i {
        padding: 10px 10px 10px 10px;
      }
      span {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
  // coming soon css
  .coming-simple {
    .ratio2_3 {
      > img {
        height: 310px;
      }
      &.order-lg-1 {
        margin-top: -22px;
      }
    }
    form {
      .form-group {
        button {
          position: relative;
          margin-top: 10px;
        }
      }
    }
  }
  // user dashboard css
  .tab-listing {
    .property-details {
      .my-listing {
        top: 17px;
        right: 20px;
      }
    }
  }
  .card-payment {
    .payment-card {
      .card-details {
        .card-number {
          > div {
            img {
              width: 12%;
              &:last-child {
                width: 4%;
              }
            }
          }
        }
      }
    }
  }
  // submit property css
  .property-wizard {
    .wizard-box {
      .wizard-form-details {
        .complete-details {
          img {
            width: 80%;
          }
        }
      }
    }
  }
  // faq page css
  .faq-section {
    .faq-image {
      margin-top: -24px;
    }
  }
  // footer css
  footer {
    .sub-footer {
      p {
        font-size: 13px;
      }
      .sub-footer-link {
        li {
          font-size: 13px;
          + li {
            margin-left: 10px;
          }
        }
      }
    }
    &.footer-brown {
      .sub-footer {
        .footer-social {
          ul {
            li {
              + li {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
  // label css
  .label {
    &.label-lg {
      padding: 5px 10px 3px;
      font-size: 14px;
    }
  }
  // about us css
  .user-about {
    .about-listing {
      ul {
        flex-wrap: unset;
        display: block;
        li {
          flex: 0 0 100%;
          max-width: 100%;
          display: block;
          &:nth-child(n + 2) {
            margin-top: 15px;
          }
        }
      }
    }
    .about-image {
      .side-left {
        .side-effect {
          &::before,
          &::after {
            width: calc(100% - 250px);
          }
        }
      }
      .img-box {
        img {
          max-width: 205px;
        }
        &.img-abs {
          right: 5%;
        }
      }
    }
  }
  // thumbnail list css
  .property-grid-slider {
    .property-grid {
      .slider-nav {
        .slick-slide {
          img {
            height: 65px;
          }
        }
      }
    }
  }
  // home section css
  .home-section {
    &.layout-5 {
      padding-bottom: 20px;
    }
  }
  .box {
    width: 205px;
    height: 90px;
    &.text-affect {
      .title {
        height: 58px;
      }
    }
  }
  .vertical-search {
    .left-sidebar {
      padding: 20px;
    }
  }
  .layout-home4 {
    .home-slider-4 {
      .slider-image {
        .home-content {
          h1 {
            font-size: 23px;
          }
          p {
            font-size: 13px;
          }
        }
      }
    }
    .slider-image {
      .home-content {
        p {
          width: 100%;
        }
      }
    }
  }
  // single property
  .gallery-nav {
    .slick-slide {
      img {
        min-height: 30px;
      }
    }
  }
  .single-feature {
    > div {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .single-property-section {
    .left-single {
      ul {
        li {
          > div {
            span {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  // full banner css
  .banner-1 {
    margin-top: 20px;
    .big-gradient {
      top: -56px;
      font-size: 100px;
    }
    .small-white {
      top: -37px;
      font-size: 45px;
    }
  }
  // about css
  .about-section {
    .about-wrap {
      &.about-1{
        .about-content{
          .our-details {
            padding: 20px;
            margin-top: -50px;
          }
        }
      }
      .about-content {
        .about-image {
          .overlay-content {
            ul {
              margin-bottom: 8px;
              transform: scale(0.9);
            }
          }
        }
        .our-details {
          p {
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
  .arrow-white {
    .slick-prev {
      left: 34%;
    }
    .slick-next {
      right: 34%;
    }
  }
  // property css
  .property-details {
    padding: 15px !important;
    ul {
      li {
        font-size: 12px;
      }
    }
  }
  .property-section {
    .property-box {
      .property-image {
        .overlay-property-box {
          .effect-round {
            + .effect-round {
              margin-top: 0;
            }
          }
          .effect-round,
          .effect-round1 {
            transform: scale(0.7);
            i {
              margin-left: 0;
            }
          }
        }
        .labels-left {
          .label {
            padding: 3px 8px 2px;
          }
        }
        .overlay-plus {
          span {
            font-size: 45px;
          }
        }
      }
    }
    .list-property {
      .property-box {
        .property-image {
          .overlay-property {
            .overlay-box {
              padding: 20px;
            }
          }
          .bg-size {
            &:before {
              padding-top: 90%;
            }
          }
        }
        .text-center {
          margin-top: -16px;
        }
      }
    }
    .property-1 {
      .property-details {
        padding: 20px;
      }
    }
    .property-box-flat {
      .property-box {
        .property-image {
          .label-white {
            padding: 5px 10px 4px;
          }
        }
      }
    }
  }
  .property-grid-2 {
    .property-details {
      ul {
        li {
          padding-left: 6px;
          padding-right: 4px;
        }
      }
    }
  }
  // feature css
  .feature-section {
    .feature-content {
      .feature-price {
        margin-bottom: 4px;
      }
    }
    .feature-wrap {
      .feature-content {
        .feature-price {
          display: block;
          li {
            display: block;
            + li {
              margin-left: 0;
            }
          }
        }
      }
    }
    .feature-1 {
      .feature-wrapper {
        .property-details {
          .property-btn {
            a {
              + a {
                margin-left: 3px;
              }
            }
          }
        }
        .feature-image {
          height: 290px;
          .signature,
          .box-color {
            transform: scale(0.4);
          }
          .signature {
            right: -70px;
            bottom: 8px;
          }
          .box-color {
            right: -32px;
            bottom: 32px;
          }
        }
      }
    }
    .feature-image {
      .feature-overlay {
        width: 70px !important;
        height: 60px !important;
        span {
          font-size: 30px;
        }
      }
    }
    .modern-feature {
      &.arrow-gradient {
        .slick-prev {
          right: 16% !important;
        }
      }
      .feature-wrap {
        .feature-content {
          .feature-price {
            li {
              + li {
                margin-left: 0;
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
  // other page css
  .subscribe-section {
    .video-details {
      .form-group {
        input,
        &::after {
          width: 100%;
        }
      }
    }
  }
  .video-modal {
    iframe {
      height: 160px;
    }
  }
}
@media (max-width: 339px) {
  .property-2 {
    &.list-view {
      &.no-sidebar {
        .property-box {
          .property-details {
            ul {
              li {
                padding-right: 2px;
                &:last-child {
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .portfolio-grid {
    .filter-panel {
      .filters {
        ul {
          li {
            min-width: 135px;
          }
        }
      }
    }
  }
  .theme-card {
    .title-2 {
      margin-bottom: -6px;
    }
  }
}
@media (max-width: 334px) {
  .theme-card {
    .title-3 {
      p {
        margin-bottom: -6px;
      }
    }
  }
}
@media (max-width: 327px) {
  .blog-single-detail {
    .comment-section {
      .comment-box {
        .media.inner-comment {
          margin-left: 0;
        }
      }
    }
  }
  .theme-card {
    .title-2 {
      h2 {
        margin-top: -4px;
      }
    }
  }
}
@media (max-width: 320px) {
  .modern-dot {
    &.slick-dotted {
      margin-bottom: 1px;
    }
  }
  .agent-social {
    li {
      a {
        i {
          margin-top: 2px;
        }
      }
    }
  }
  // google map css
  .infoBox {
    .marker-detail {
      width: 125px;
      img {
        width: 125px;
      }
    }
  }
  // home section css
  .layout-home4 {
    .slider-image {
      .home-content {
        p {
          width: 100%;
        }
      }
    }
  }
}
/**=====================
     Responsive css start
==========================**/
