/**=====================
     keyframes css start
==========================**/
@keyframes effect {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.4);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@-webkit-keyframes an-snow {
  100% {
    background-position: 500px 1000px, 300px 300px, -400px 400px;
  }
}
@-moz-keyframes an-snow {
  100% {
    background-position: 500px 1000px, 300px 300px, -400px 400px;
  }
}
@-o-keyframes an-snow {
  100% {
    background-position: 500px 1000px, 300px 300px, -400px 400px;
  }
}
@keyframes an-snow {
  100% {
    background-position: 500px 1000px, 300px 300px, -400px 400px;
  }
}
@keyframes rotating {
	0% {
		opacity: 0;
		transform: rotate(0deg);
	}
	25%, 75% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 0;
		transform: rotate(360deg);
	}
}
@keyframes flying {
	0% {
		transform: translate(0, 0);
	}
	10% {
		transform: translate(20px, 50px);
	}
	20% {
		transform: translate(-30px, 10px);
	}
	30% {
		transform: translate(10px, 60px);
	}
	40% {
		transform: translate(50px, 00px);
	}
	50% {
		transform: translate(-10px, -40px);
	}
	60% {
		transform: translate(-40px, 20px);
	}
	70% {
		transform: translate(30px, -30px);
	}
	80% {
		transform: translate(0px, -60px);
	}
	90% {
		transform: translate(40px, 10px);
	}
	100% {
		transform: translate(0, 0);
	}
}
@keyframes sonarEffect {
  0% {
    opacity: 0.05;
  }
  40% {
    opacity: 0.08;
    box-shadow: 0 0 0 2px rgba($white, 0.1), 0 0 10px 10px var(--theme-default3), 0 0 0 10px rgba($white, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba($white, 0.1), 0 0 10px 10px var(--theme-default3), 0 0 0 10px rgba($white, 0.5);
    transform: scale(1.3);
    opacity: 0;
  }
}
@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes bubble {
  0% {
  }
  100% {
    transform: scale(1);
  }
}

@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes search-bg-1 {
  0% {
    right: -20px;
  }
  50% {
    right: -15px;
  }
  100% {
    right: -11px;
  }
}

@keyframes search-bg-2 {
  0% {
    right: -10px;
  }
  50% {
    right: -5px;
  }
  100% {
    right: 0;
  }
}

@keyframes search-bg-3 {
  0% {
    right: 0;
  }
  50% {
    right: 5px;
  }
  100% {
    right: 10px;
  }
}

@keyframes animation-x {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1920px;
  }
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes sway {
  0% {
    transform: rotate(8deg);
  }
  50% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(8deg);
  }
}

// captcha
@keyframes fadein {
	0% {opacity:0;}
	100% {opacity:1;}
}
@keyframes bottomslide {
	0% {width:0;}
	100% {width:100%;}
}
@keyframes rightslide {
	0% {height:0;}
	100% {height:100%;}
}
@keyframes spincaptcha {
	10% {
		width:0;
		height:0;
		border-width:6px;
	}
	30% {
		width:0;
		height:0;
		border-radius:50%;
		border-width:1em;
		transform: rotate(0deg);
		border-color:rgb(199,218,245);
	}
	50% {
		width:2em;
		height:2em;
		border-radius:50%;
		border-width:4px;
		border-color:rgb(199,218,245);
		border-right-color:rgb(89,152,239);
	}
	70% {
		border-width:4px;
		border-color:rgb(199,218,245);
		border-right-color:rgb(89,152,239);
	}
	90% {
		border-width:4px;
	}
	100% {
		width:2em;
		height:2em;
		border-radius:50%;
		transform: rotate(720deg);
		border-color:transparent;
	}
}

// for rtl layout
@keyframes search-bg-4 {
  0% {
    left: -20px;
  }
  50% {
    left: -15px;
  }
  100% {
    left: -11px;
  }
}

@keyframes search-bg-5 {
  0% {
    left: -10px;
  }
  50% {
    left: -5px;
  }
  100% {
    left: 0;
  }
}

@keyframes search-bg-6 {
  0% {
    left: 0;
  }
  50% {
    left: 5px;
  }
  100% {
    left: 10px;
  }
}
/**=====================
     keyframes css end
==========================**/
