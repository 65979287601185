/**=====================
     coming soon css start
==========================**/
.coming-soon {
  height: 100vh;
  min-height: 750px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: rgba($black, 0.6);
  background-blend-mode: overlay;

  .coming-soon-detail {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    min-height: 750px;
    width: 100%;

    >div {
      width: 100%;
    }

    h2 {
      color: $white;
      font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
      margin-bottom: calc(16px + (35 - 16) * ((100vw - 320px) / (1920 - 320)));
      text-transform: inherit;
      line-height: 1.35;
    }

    .logo {
      margin-bottom: calc(14px + (25 - 14) * ((100vw - 320px) / (1920 - 320)));
    }

    form {
      .form-group {
        position: relative;
        border-radius: 25px;
        overflow: hidden;

        button {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
        }
      }

      input {
        padding: 12px 20px;
        border-radius: 0;
        font-size: 16px;
      }
      .btn-solid {
        &.color-3 {
          &:hover, &:focus {
            background-color: darken($theme-color5, 2%);
            color: $white;
          }
        }
      }
    }

    .timer {
      margin-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));

      li {
        display: inline-block;
        font-size: 14px;
        text-transform: capitalize;
        color: $white;
        text-align: center;
        font-weight: 600;

        >div {
          border-radius: 100%;
          border: 2px solid $white;
          width: 100px;
          height: 100px;
          background-color: rgba($black, 0.5);
          @extend %flex-center;
        }

        span {
          display: block;
          font-size: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
        }

        +li {
          margin-left: 20px;
          padding-left: 20px;
          position: relative;

          &::before {
            position: absolute;
            content: ":";
            left: -4px;
            top: 34%;
            font-size: 25px;
            @extend %flex-center;
            justify-content: flex-start;
          }
        }
      }
    }
  }
  &.coming-left {
    .coming-soon-detail {
      h2 {
        span {
          color: var(--theme-default5);
        }
      }

      .timer {
        margin-bottom: calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));

        ul {
          display: flex;
        }

        li {
          padding: 20px 5px;
          width: 100%;
          border: 2px solid rgba($theme-color5, 0.5);
          border-radius: 8px;
          position: relative;
          margin: 0 20px;

          span {
            font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
            color: var(--theme-default5);
          }

          +li {
            margin-left: 5px;

            &::before {
              display: none;
            }
          }
        }
      }
    }

    .social-coming {
      margin-top: 30px;

      ul {
        li {
          a {
            color: $white;

            i {
              font-size: 18px;
            }
          }

          +li {
            margin-left: 20px;
          }
        }
      }

      p {
        margin-top: 10px;
        margin-bottom: 0;
        color: $white;
      }
    }
  }
  .cloud-img {
    position: absolute;
    top: -27px;
    width: 100%;
    height: 360px;
    background-size: contain !important;
    opacity: 0.2;
    animation: slide 60s linear infinite;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-repeat: repeat !important;
  }
}

@keyframes slide { 
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -1000px 0;
  }
}

.coming-simple {
  height: calc(100vh - 89px);

  .row {
    align-items: center;
  }

  .title-3 {
    h6 {
      color: var(--theme-default3);
      margin-bottom: 4px;
    }

    h2 {
      font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .site-construction {
    margin-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    position: relative;

    h4 {
      font-weight: 500;
      color: $title-color;
      margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    }

    p {
      line-height: 1.8;
      margin-bottom: 30px;
    }

    .progress {
      background: transparent;
      margin-bottom: calc(30px + (90 - 30) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 25px;
      position: relative;

      &::after {
        @extend %common-lightafter;
        opacity: 0.2;
        background: var(--theme-default3);
      }
    }

    .bg-primary {
      background-color: var(--theme-default3);
    }
  }

  form {
    .form-group {
      position: relative;
      border-radius: 25px;
      margin-bottom: 0;

      button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
    }

    input {
      padding: 8px 20px;
      border-radius: 0;
      font-size: 16px;
    }
  }
}

/**=====================
     coming soon css end
==========================**/