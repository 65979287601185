/* You can add global styles to this file, and also import other style files */

@import "./assets/scss/app.scss";


/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
// @import '~@fortawesome/fontawesome-free/css/brands.css';
// @import '~@fortawesome/fontawesome-free/css/regular.css';
// @import '~@fortawesome/fontawesome-free/css/solid.css';
// @import '~@fortawesome/fontawesome-free/css/fontawesome.css';
// @import '~simple-line-icons/css/simple-line-icons.css';
// @import '~weather-icons/css/weather-icons.css';



// @import '~spinkit/spinkit.css';


// @import '~fullcalendar/main.css';
// @import '~angular2-toaster/toaster.css';
// @import '~ng2-dnd/bundles/style.css';

// @import '~summernote/dist/summernote.css';





// //== Bootstrap
// @import "./app/shared/styles/bootstrap.scss";
// @import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// //== Application
//  @import "./app/shared/styles/app.scss";
// // Font-Family
//  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

h1, h2, h3, h4, h5, h6, p, a {
    font-family: 'Poppins', sans-serif;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



:root {
    /* ----- THIS ARE COLORS FROM LIGHT MODE ----- */
    /* ----- WE ALSO NEED TO IMPLEMENT DARK MODE, STILL NOT IMPLEMENTED ----- */

    /* all shades of grey */
    --black: #000000;
    --black-rgb: 0, 0, 0;
    --grey1: #011317;
    --grey1-rgb: 1, 19, 23;
    --grey2: #324144;
    --grey2-rgb: 50, 65, 68;
    --grey3: #646F71;
    --grey3-rgb: 100, 111, 113;
    --grey4: #959C9E;
    --grey4-rgb: 149, 156, 158;
    --grey5: #C6CACB;
    --grey5-rgb: 198, 202, 203;
    --grey6: #F7F8F8;
    --grey6-rgb: 247, 248, 248;
    --grey7: #EEEEEE;
    --grey7-rgb: 238, 238, 238;
    --border-color: #EDEDED;
    --border-color-rgb: 237, 237, 237;
    --divider-color: #E0E0E0;
    --divider-color-rgb: 224, 224, 224;

    /* basic colors */
    --white: #FFFFFF;
    --button-ripple-color: rgba(255, 255, 255, 0.4);
    --white-rgb: 255, 255, 255;
    --green: #54B66F;
    --green-rgb: 84, 182, 111;
    --red: #DF3A4D;
    --red-rgb: 223, 58, 77;
    --red-dark: #97232c;
    --red-dark-rgb: 151, 35, 44;
    --orange: #FFA500;
    --orange-rgb: 255, 165, 0;

    /* main colors */
    --main1: #09BCE1;
    --main1-rgb: 9, 188, 225;
    --main2: #333A6E;
    --main2-rgb: 51, 58, 110;
    --shadow-color-rgb: 0, 0, 0;
    --main7: #C885F2;
        --main7-rgb: 200, 133, 242;

    /* ----- END OF COLOR COLLECTION ----- */

    /* ----- THIS ARE THE FONT SIZES ----- */
    /* h1 */
    --font-size-h1: 32px;
    --font-weight-h1: 500;
    --font-size-h1-mobile: 24px;
    --font-weight-h1-mobile: 500;
    /* h2 */
    --font-size-h2: 24px;
    --font-weight-h2: 500;
    --font-size-h2-mobile: 20px;
    --font-weight-h2-mobile: 500;
    /* h3 */
    --font-size-h3: 20px;
    --font-weight-h3: 500;
    --font-size-h3-mobile: 17px;
    --font-weight-h3-mobile: 500;
    /* h4 */
    --font-size-h4: 17px;
    --font-weight-h4: 500;
    --font-size-h4-mobile: 15px;
    --font-weight-h4-mobile: 600;
    /* h5 */
    --font-size-h5: 15px;
    --font-weight-h5: 700;
    --font-size-h5-mobile: 14px;
    --font-weight-h5-mobile: 600;
    /* h6 */
    --font-size-h6: 15px;
    --font-weight-h4: 500;
    --font-size-h4-mobile: 13px;
    --font-weight-h4-mobile: 600;

    /* size */
    --input-field-delete-button-width: 40px;
    --sidebar-width: 250px;
    --pay-profile-easy-collect-products-components-height: 48px;

}

/* disable default outlines in buttons */
*{
    outline: none!important;
}

/* ----- BASIC HELPER CLASSES TO SET COLORS TO COMPONENTS ----- */
.errorText{
    color: #f63939;
}

/* BACKGROUND COLORS */
.bg-grey1 {
    background-color: var(--grey1);
}

.bg-grey2 {
    background-color: var(--grey2);
}

.bg-grey3 {
    background-color: var(--grey3);
}

.bg-grey4 {
    background-color: var(--grey4);
}

.bg-grey5 {
    background-color: var(--grey5);
}

.bg-grey6 {
    background-color: var(--grey6);
}

.bg-white {
    background-color: var(--white);
}

.bg-green {
    background-color: var(--green);
}

.bg-red {
    background-color: var(--red);
}

.bg-main1 {
    background-color: var(--main1);
}

.bg-main2 {
    background-color: var(--main2);
}

/* TEXT COLORS */

.text-grey1 {
    color: var(--grey1);
}

.text-grey2 {
    color: var(--grey2);
}

.text-grey3 {
    color: var(--grey3);
}

.text-grey4 {
    color: var(--grey4);
}

.text-grey5 {
    color: var(--grey5);
}

.text-grey6 {
    color: var(--grey6);
}

.text-white {
    color: var(--white);
}

.text-green {
    color: var(--green);
}

.text-red {
    color: var(--red);
}

.text-main1 {
    color: var(--main1);
}

.text-main2 {
    color: var(--main2);
}

/* ----- END OF BASIC HELPER CLASSES TO SET COLORS TO COMPONENTS ----- */

/* ----- SETTING OF CUSTOM STYLE IN 'H' TAGS ----- */
h1 {
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-h1);
}

h2 {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-h2);
}

h3 {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
}

h4 {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-h4);
}

h5 {
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-h5);
}

h6 {
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-h6);
}

@media (max-width: 768px) {
    h1 {
        font-size: var(--font-size-h1-mobile);
        font-weight: var(--font-weight-h1-mobile);
    }

    h2 {
        font-size: var(--font-size-h2-mobile);
        font-weight: var(--font-weight-h2-mobile);
    }

    h3 {
        font-size: var(--font-size-h3-mobile);
        font-weight: var(--font-weight-h3-mobile);
    }

    h4 {
        font-size: var(--font-size-h4-mobile);
        font-weight: var(--font-weight-h4-mobile);
    }

    h5 {
        font-size: var(--font-size-h5-mobile);
        font-weight: var(--font-weight-h5-mobile);
    }

    h6 {
        font-size: var(--font-size-h6-mobile);
        font-weight: var(--font-weight-h6-mobile);
    }
}

/* ----- END OF SETTING OF CUSTOM STYLE IN 'H' TAGS ----- */

/* ----- GOOGLE MATERIAL ICONS SETTINGS ----- */
.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 600,
        'GRAD' 0,
        'opsz' 48
}
/* ----- END OF GOOGLE MATERIAL ICONS SETTINGS ----- */


/* ----- CUSTOMIZE SCROLLBAR OVER THE APP ----- */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--grey5);
    border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--grey3);
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field-wrapper{
    padding: 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input{
    box-shadow: none!important;
}

.main-p-container img {
  width: 100%;
  border-radius: 8px;
}

.main-p-container a{
  text-decoration: underline;
  color: var(--main1);
}

