/**=====================
     form css start
==========================**/
.form-control {
    border: 1px solid $light-border;
}
input {
    &:focus {
        outline: none;
    }
}
form {
    label,
    .form-control {
        font-size: 14px;
        font-family: $font_2, $font_3;
    }

    .form-control {
        &::placeholder {
            text-transform: capitalize;
        }

        &:focus {
            box-shadow: none;
        }
    }

    label {
        color: $title-color;
    }
}

.alert-success {
    max-width: 350px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url(../../images/icon/error.svg);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    transition: all 0.3s ease;
}

.was-validated {

    .form-control,
    .custom-select {

        &:invalid,
        &.is-invalid {
            &:focus {
                border-bottom-color: #dc3545;
                box-shadow: unset;
            }
        }

        &:valid,
        &.is-valid {
            &:focus {
                border-bottom-color: #28a745;
                box-shadow: unset;
            }
        }
    }
}

// radio css
.animate-rdi {
    margin-left: 45px;

    .col {
        align-items: center;
        justify-content: flex-end;
        display: flex;
    }

    label {
        color: $white;
        margin-bottom: 0;
        padding: 15px 20px;
        background: rgba($white, 0.1);
        border-radius: 5px;
        margin-right: 47px;
        font-weight: 600;
        cursor: pointer;
    }
    .radio_animated {
        &:after {
            background: #2b2f31;
        }
    }
}

.checkbox_animated {
    cursor: pointer;
    position: relative;
    margin: 0 1rem 0 0;

    &:before {
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: rotate(-45deg) scale(0, 0);
        content: "";
        position: absolute;
        left: 0.25rem;
        top: 0.225rem;
        z-index: 1;
        width: 0.75rem;
        height: 0.375rem;
        border: 2px solid var(--theme-default);
        border-top-style: none;
        border-right-style: none;
    }

    &:after {
        content: "";
        position: absolute;
        top: -0.125rem;
        left: 0;
        width: 1.3rem;
        height: 1.3rem;
        background: $white;
        border: 2px solid $light-gray;
        cursor: pointer;
    }

    &:checked:before {
        transform: rotate(-45deg) scale(1, 1);
    }

    &.color-2 {
        &:before {
            border: 2px solid var(--theme-default3);
            border-top-style: none;
            border-right-style: none;
        }
    }

    &.color-3 {
        &:before {
            border: 2px solid var(--theme-default5);
            border-top-style: none;
            border-right-style: none;
        }
    }

    &.color-4 {
        &:before {
            border: 2px solid var(--theme-default7);
            border-top-style: none;
            border-right-style: none;
        }
    }

    &.color-6 {
        &:before {
            border: 2px solid var(--theme-default8);
            border-top-style: none;
            border-right-style: none;
        }
    }

    &.color-7 {
        &:before {
            border: 2px solid var(--theme-default10);
            border-top-style: none;
            border-right-style: none;
        }
    }
}

.radio_animated {
    position: relative;
    margin: 0 20px 0 0;
    cursor: pointer;
    &:before {
      transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
      transform: scale(0, 0);
      content: "";
      position: absolute;
      top: -8px;
      left: 2px;
      z-index: 1;
      width: 7px;
      height: 7px;
      background: $theme-color3;
      border-radius: 50%;
    }
    &:after {
      content: "";
      position: absolute;
      top: -12px;
      left: -0.125rem;
      width: 15px;
      height: 15px;
      background: $white;
      border: 5px solid rgba($theme-color3, 0.3);
      border-radius: 50%;
    }
    &:checked:before {
      transform: scale(1, 1);
    }
}
input[type='radio'] {
    &.radio_animated  {
        -webkit-appearance:none;
    }
}

// sign up modal css
.signup-modal {
    .modal-content {
        overflow: hidden;
    }

    .modal-body {
        padding: 0;
    }

    .login-img {
        height: 100%;
    }

    .signup-tab {
        padding: 40px 50px;

        .nav-tabs {
            justify-content: flex-end;
            border-bottom: none;

            .nav-link {
                border: 1px solid $light-border;
                border-radius: 0;
                font-weight: 500;
            }

            .nav-item {
                +.nav-item {
                    margin-left: -1px;
                }
            }
        }

        h4 {
            margin-top: 30px;
            margin-bottom: 20px;
            color: $title-color;
            font-weight: 600;
        }

        form {
            a {
                color: rgba($title-color, 0.7);

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }

            .form-group {
                svg {
                    width: 22px;
                    height: 22px;
                }
                svg, i {
                    color: $title-color;
                }
            }

            span {
                color: $title-color;
                display: inline-block;
                vertical-align: -1px;
            }
        }
    }
}

.theme-tab-3 {
    .nav-tabs {
        .nav-link {
            color: var(--theme-default5);

            &.active {
                background-color: var(--theme-default5);
                color: $white;
            }
        }

        .nav-item {
            &.show {
                .nav-link {
                    background-color: var(--theme-default5);
                    color: $white;
                }
            }
        }
    }
}

.theme-tab-4 {
    .nav-tabs {
        .nav-link {
            color: var(--theme-default7);

            &.active {
                background-image: var(--theme-gradient9);
                color: $white;
            }
        }

        .nav-item {
            &.show {
                .nav-link {
                    background-image: var(--theme-gradient9);
                    color: $white;
                }
            }
        }
    }
}

.theme-tab-5 {
    .nav-tabs {
        .nav-link {
            color: var(--theme-default10);

            &.active {
                background-color: var(--theme-default10);
                color: $white;
            }
        }

        .nav-item {
            &.show {
                .nav-link {
                    background-color: var(--theme-default10);
                    color: $white;
                }
            }
        }
    }
}

// signup wizard css
.property-wizard {
    &.horizontal-wizard {
        .wizard-box {
            >div {
                margin: 0 auto;
            }

            .wizard-step-container {
                .wizard-steps {
                    border-bottom: 1px solid $light-gray;
                    padding-bottom: 20px;
                    margin: -10px;
                    li {

                        display: inline-block;
                        margin: 10px;
                    }
                }

                .log-in {
                    margin-top: 50px;

                    form {
                        .form-control {
                            text-transform: inherit;
                            border-radius: 0;
                        }
                    }

                    .next-btn {
                        margin-top: 20px;
                        margin-bottom: 0;
                    }
                }

                .wizard-form-details {
                    .text-end {
                        p {
                            margin-bottom: 0;
                            color: rgba($title-color, 0.4);

                            a {
                                margin-left: 15px;
                            }
                        }
                    }

                    form {
                        margin-bottom: 0;
                    }

                    .wizard-step-3 {
                        .next-btn {
                            margin-top: 35px;
                        }
                    }
                }

                .input-data {
                    display: flex;

                    .account-content {
                        h3 {
                            color: $title-color;
                            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                            margin-bottom: 10px;
                        }

                        ul {
                            li {
                                display: block;
                                font-family: $font_1, $font_3;
                                color: $title-color;

                                span {
                                    color: var(--theme-default3);
                                }
                            }
                        }

                        +.account-content {
                            margin-left: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
                        }
                    }
                }
            }
        }
    }
}

// log in page css
.log-in {
    &.row {
        >div {
            margin: 0 auto;
        }
    }

    .divider {
        text-align: center;
        position: relative;
        margin: 20px 0;
        z-index: 1;

        h6 {
            display: inline-block;
            padding: 5px 15px;
            margin-bottom: 0;
            text-transform: uppercase;
            background-color: $white;
        }

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $light-border;
            left: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: -1;
        }
    }

    .title-3 {
        margin-bottom: 30px;

        h2 {
            position: relative;
            font-weight: 600;

            &::before {
                position: absolute;
                content: "";
                width: 30px;
                height: 2px;
                background-color: var(--theme-default3);
                left: 0;
                bottom: -8px;
            }
        }
    }

    form {
        .form-group {
            margin-bottom: 30px;

            .input-group {
                .input-group-text {
                    padding-left: 0;
                    padding-right: 0;

                    svg {
                        height: 16px;
                    }
                }
                i {
                    font-size: 14px;
                }

                .input-group-text {
                    background-color: transparent;
                    border: none;
                }

                input {
                    border: none;
                    border-bottom: 1px solid $light-border;

                    &::placeholder {
                        color: #959595;
                    }
                    ~ .input-group-text {
                        border-bottom: 1px solid $light-border;
                    }
                }

                .input-group-apend {
                    .input-group-text {
                        border-bottom: 1px solid $light-border;
                        height: 100%;
                    }
                }
            }

            textarea {
                border: none;
                border-bottom: 1px solid $light-border;
                color: #959595;
                padding-left: 5px;
                padding-right: 5px;
            }

            .important-note {
                color: rgba($title-color, 0.4);
                font-family: $font_2, $font_3;
                padding-left: 35px;
                margin-top: 5px;
            }
        }

        .d-flex {
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;

            a {
                color: rgba($title-color, 0.7);
            }
        }
    }

    h6 {
        color: $title-color;
        margin-bottom: 20px;
        font-weight: 600;
    }

    .social-connect {
        >div {
            &:nth-child(n+3) {
                margin-top: 15px;
            }
        }
    }
}

.btn-social {
    padding: 0;
    width: 100%;
    text-align: left;
    color: $white;
    transition: 0.5s;

    i {
        padding: 11px 12px 12px 11px;
    }

    span {
        padding: 8px 15px 7px;
        display: inline-block;
    }

    &:hover,
    &:focus {
        transition: 0.5s;
        color: $white;
    }

    &.facebook {
        background-color: $facebook;

        i,
        &:hover,
        &:focus {
            background-color: darken($facebook, 5%);
        }
    }

    &.google {
        background-color: $google;

        i,
        &:hover,
        &:focus {
            background-color: darken($google, 5%);
        }
    }

    &.twitter {
        background-color: $twitter;

        i,
        &:hover,
        &:focus {
            background-color: darken($twitter, 5%);
        }
    }

    &.linkedin {
        background-color: $linkedin;

        i,
        &:hover,
        &:focus {
            background-color: darken($linkedin, 5%);
        }
    }
}

// captcha
.captcha {
    background-color: #f9f9f9;
    border: 2px solid $light-border;
    border-radius: 5px;
    color: $theme-sub-font-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .spinner {
        position: relative;
        width: 2em;
        height: 2em;
        display: flex;
        margin: 2em 1em;
        align-items: center;
        justify-content: center;
    }

    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    input[type="checkbox"]+.checkmark {
        display: inline-block;
        width: 2em;
        height: 2em;
        background-color: #fcfcfc;
        border: 2.5px solid $light-border;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    input[type="checkbox"]+.checkmark span {
        content: '';
        position: relative;
        margin-top: -3px;
        transform: rotate(45deg);
        width: .75em;
        height: 1.2em;
        opacity: 0;
    }

    input[type="checkbox"]+.checkmark>span:after {
        content: '';
        position: absolute;
        display: block;
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: #029f56;
    }

    input[type="checkbox"]+.checkmark>span:before {
        content: '';
        position: absolute;
        display: block;
        width: 3px;
        bottom: 0;
        right: 0;
        background-color: #029f56;
    }

    input[type="checkbox"]:checked+.checkmark {
        animation: 2s spincaptcha forwards;
    }

    input[type="checkbox"]:checked+.checkmark>span {
        animation: 1s fadein 1.9s forwards;
    }

    input[type="checkbox"]:checked+.checkmark>span:after {
        animation: .3s bottomslide 2s forwards;
    }

    input[type="checkbox"]:checked+.checkmark>span:before {
        animation: .5s rightslide 2.2s forwards;
    }

    .logo {
        margin-left: 50px;
        margin-right: 13px;
        text-align: center;

        img {
            height: 2em;
            width: 2em;
        }

        p {
            color: rgba($title-color, 0.4);
            margin: 0;
            font-size: 1em;
            font-weight: 700;
            margin: .4em 0 .2em 0;
        }

        small {
            color: rgba($title-color, 0.4);
            margin: 0;
            font-size: .8em;
        }
    }

    ::selection {
        background-color: transparent;
        color: teal;
    }

    ::-moz-selection {
        background-color: transparent;
        color: teal;
    }
}

/**=====================
     form css end
==========================**/