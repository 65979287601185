/**=====================
     service css start
==========================**/
.service-icon {
    width: 45px;
    height: 45px;
    fill: var(--theme-default5);
    color: var(--theme-default5);
}

.icon-line-color {
    fill: var(--theme-default5);
    color: var(--theme-default5);
    width: 0;
    height: 3px;
    bottom: -22px;
    left: 0px;
    position: absolute;
}
.service-section.service-2{
    .property-service{
        > div{
            .service-box.color-3{
                &:hover{
                    background-color: rgba($theme-color5, 0.1);
                }
            }
            .service-box.color-7{
                a{
                    &:hover{
                        span{
                            color: var(--theme-default10);
                        }
                    }
                }
                &:hover{
                    background-color: rgba($theme-color10,0.2);
                }
            }
        }
    }
}
.service-section {
    position: relative;

    .property-service {
        >div {
            .service-box {
                text-align: left;
                border-radius: 15px;
                padding: 50px;
                transition: 0.5s;
                background: $white;
                box-shadow: 3px 3px 22px rgba($black, 0.07);
                .icon-round {
                    width: 80px;
                    height: 80px;
                    margin: 0 0 20px;
                    border-radius: 100%;
                    transition: 0.5s;
                    background-color: var(--theme-default3);
                    position: relative;
                    @extend %flex-center;

                    svg{
                        height: 35px;
                        width: 35px;
                        stroke: white;
                    }
                    &::before {
                        @extend %common-lightafter;
                        border-radius: 100%;
                        background-color: var(--theme-default3);
                        opacity: 0.05;
                    }

                    &::after {
                        pointer-events: none;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        content: "";
                        box-sizing: content-box;
                        top: 0;
                        left: 0;
                        padding: 0;
                        z-index: -1;
                        box-shadow: 0 0 0 2px rgba($white, 0.1);
                        opacity: 0;
                        transform: scale(0.9);
                    }

                    img {
                        width: 40%;
                    }
                }

                h3 {
                    background: $theme-font-color;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 700;
                    line-height: 1.5;
                    color: $theme-font-color;

                    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                    filter: drop-shadow(5px 5px 12px rgba($theme-color3, 0.2));
                }

                p {
                    color: rgba($theme-sub-font-color, 0.7);
                    line-height: 1.6;
                    margin-bottom: -3px;
                }

                &:hover {
                    transform: translateY(-5px);
                    transition: 0.5s;

                    .icon-round {
                        &::after {
                            animation: sonarEffect 1.3s ease-out 75ms;
                        }
                    }
                }
            }
        }
    }

    .title-2 {
        h2 {
            margin-top: -2px;
        }
    }

    &.service-2 {
        .property-service {
            >div {
                .service-box {
                    box-shadow: 0 0 35px rgba($black, 0.08);
                    border-radius: 0;
                    padding: 60px 40px;
                    height: 100%;
                    transition: 0.5s;

                    .hover-line {
                        margin-bottom: 45px;
                        position: relative;
                        display: inline-block;

                        &::before {
                            position: absolute;
                            content: "";
                            opacity: 0;
                            background-color: var(--theme-default5);
                            width: 40px;
                            height: 40px;
                            border-radius: 100%;
                            transition: 0.5s;
                            right: -7px;
                            bottom: -7px;
                        }
                    }

                    h3 {
                        color: $theme-sub-font-color;
                        background: transparent;
                        -webkit-background-clip: unset;
                        -webkit-text-fill-color: unset;
                        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
                    }

                    p {
                        margin-bottom: 30px;
                        line-height: 1.75;
                        color: rgba($title-color, 0.7);
                    }

                    .btn {
                        box-shadow: none;
                        transition: 0.5s;
                        span {
                            vertical-align: -3px;
                        }
                    }

                    &:hover {
                        background-color: rgba($theme-color3,0.1);
                        .hover-line {
                            &::before {
                                opacity: 0.2;
                                transition: 0.5s;
                            }
                            .icon-line-color {
                                width: 68px;
                                transition: width 0.3s;
                            }
                        }

                        .btn {
                            background-color: var(--theme-default5);
                            transition: 0.5s;
                            color: $white;

                            span {
                                color: $white;
                                vertical-align: -2px;
                            }

                            &::before {
                                @extend %common-lightafter;
                                opacity: 0.35;
                                box-shadow: 0px 10px 12.5px var(--theme-default5);
                            }

                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            &.about-service {
                >div {
                    .service-box {
                        border-radius: 10px;

                        .service-icon,
                        .icon-line-color {
                            fill: var(--theme-default3);
                        }

                        .hover-line {
                            &::before {
                                background-color: var(--theme-default3);
                            }
                        }

                        .btn {

                            border: 2px solid transparent;
                            span {
                                vertical-align: -3px;
                            }
                            &:hover {
                                background: none;
                                box-shadow: none;
                            }

                            &::after {
                                border-radius: 8px;
                            }
                        }

                        &:hover {
                            .btn {
                                background: none;
                                border-color: var(--theme-default3);
                                &::before,
                                &::after {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.color-7 {
            .property-service {
                >div {
                    .service-box {
                        .hover-line {
                            &::before {
                                background-color: var(--theme-default10);
                            }
                        }

                        &:hover {
                            .btn {
                                background-color: var(--theme-default10);
                                span {
                                    vertical-align: -3px;
                                    color: $white;
                                }

                                &::before {
                                    @extend %common-lightafter;
                                    opacity: 0.35;
                                    box-shadow: 0px 10px 12.5px var(--theme-default10);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.service-bg {
        .title-3 {
            margin-top: -2px;
        }
        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 230px;
            background-color: $light-blue;
            bottom: 0;
            left: 0;
            z-index: -1;
        }
    }

    &.color-7 {

        .service-icon,
        .icon-line-color {
            fill: var(--theme-default10);
        }
    }
}

.service-slider {
    .service-wrapper {
        .top-img-box {
            margin-bottom: -20px;
            margin-left: 40px;

            >div {
                width: 67px;
                height: 67px;
                border-radius: 5px;
                background-image: var(--theme-gradient9);
                @extend %flex-center;
                position: relative;

                &::after {
                    @extend %common-lightafter;
                    opacity: 0.3;
                    box-shadow: -5.15px 8.572px 25px var(--theme-default7);
                }

                img {
                    width: 60%;
                }
            }
        }

        .service-details {
            border-radius: 5px;
            padding: 40px;
            background-color: $white;
            box-shadow: 0 0 35px rgba($black, 0.07);

            p {
                line-height: 1.75;
                margin-bottom: 10px;
                color: rgba($title-color, 0.7);
            }

            h3 {
                color: $title-color;
                font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: 15px;
                font-weight: 600;
                a {
                    color: $title-color;
                    background: unset;
                    -webkit-background-clip: unset;
                    -webkit-text-fill-color: unset;
                    &::after {
                        display: none;
                    }
                }
            }

            a {
                background: var(--theme-gradient9);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                position: relative;
                font-weight: 600;

                &::after {
                    position: absolute;
                    content: "";
                    top: 9px;
                    right: -45px;
                    width: 35px;
                    height: 2px;
                    border-radius: 0px;
                    background-color: var(--theme-default7);
                }
            }
        }
    }

    .slick-list {
        margin: -30px -12px;

        .slick-track {
            .slick-slide {
                margin-bottom: 0;

                >div {
                    margin: 30px 20px;
                }
            }
        }
    }

    &.arrow-gradient {
        &.arrow-right {
            .slick-prev {
                right: 15.5%;
            }

            .slick-next {
                right: 12.3%;
            }

            .slick-arrow {
                top: -62px;
            }
        }
    }
}

/**=====================
     service css end
==========================**/
