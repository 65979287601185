/**=====================
     map css start
==========================**/
.arrow-define {
    position: absolute;
    top: -54px;
    font-weight: 500;
    font-size: 14px;
    right: -60px;
    cursor: auto;

    &::after {
        content: "";
        background-image: url(../../images/arrow.png);
        width: 50px;
        height: 50px;
        position: absolute;
        background-size: contain;
        left: -50px;
        bottom: -37px;
        background-repeat: no-repeat;
        animation: movebounce 4s linear infinite;
    }
}

.map-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    iframe,
    .map {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }
}

.gm-style div[title] {
    opacity: 1 !important;
}

.gm-style div[title] img {
    display: block !important;
    height: 65px !important;
    width: auto !important;
}

.add-animation {
    animation: 1s linear infinite bounce-1;
}

.add-filter {
    filter: grayscale(1);
}

.infoBox {
    >img {
        background: $white;
    }

    .marker-detail {
        background: $white;
        overflow: hidden;
        position: relative;

        img {
            height: auto;
            width: 202px;
        }

        .label {
            position: absolute;
            left: 10px;
            top: 10px;
            font-size: 10px;
            padding: 4px 8px 3px;
        }

        .detail-part {
            padding: 10px;

            h6 {
                font-weight: 600;
            }

            ul {
                margin-bottom: 15px;

                li {
                    padding-left: 5px;
                    padding-right: 5px;
                    font-size: 13px;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }

            span,
            a {
                font-size: 14px;
                font-weight: 500;
            }

            a {
                float: right;
                color: var(--theme-default3);
                text-decoration: underline;
            }
        }
    }
}

.leaflet-popup-tip-container {
    width: 20px;
    height: 20px;
    margin-left: -15px;
}
.leaflet-popup-content {
    .infoBox {
        .marker-detail {
            .detail-part {
                span, a {
                    font-family: Montserrat, sans-serif;
                }
            }
        }
    }
}

.leaflet-marker-icon {
    &.leaflet-interactive {
        width: 35px !important;
        height: 38px !important;
    }
}
.leaflet-pane  { 
    z-index: 1; 
}

.leaflet-top,
.leaflet-bottom {
    z-index: 1;
}

.leaflet-control {
    z-index: 8;
}

.leaflet-shadow-pane {
    display: none;
    img {
        transform: none !important;
    }
}

.H_ib_body {
    bottom: 48px;
}

.H_ib_tail {
    margin: -53px -10px;
}

.H_ib_content {
    line-height: 0.95;
}

.MicrosoftMap {
    > div {
        z-index: 1 !important;
    }
    .Infobox {
        border-radius: 0 !important;
        border: none !important;

        &.no-title {
            .infobox-info {
                margin-right: 0 !important;
            }
        }

        .infobox-body {
            width: auto !important;
            height: 100% !important;
            max-height: 100% !important;
            padding-bottom: 0 !important;
        }

        .infobox-info {
            padding: 0 !important;
            max-height: max-content !important;
            color: unset !important;
            font-family: $font_1, $font_3 !important;
        }
    }

    .infobox-close {
        z-index: 1;
    }
}

// map modal css
.map-modal {
    .modal-dialog {
        width: 100%;
        height: 90%;
        max-width: 97%;

        .modal-content {
            border: 0 none;
            border-radius: 0;

            .btn-close {
                z-index: 2;
                font-weight: 500;
                background: var(--theme-default4);
                position: absolute;
                right: 0;
                top: 0;
                width: 30px;
                height: 30px;
                opacity: 1;
                border-bottom-right-radius: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                span {
                    font-size: 30px;
                    color: $white;
                    line-height: 0.9;
                }
            }

            .modal-body {
                display: flex;
                align-items: center;
                padding: 12px;
                padding-left: 0;
            }

            iframe,
            .map {
                width: 100%;
                height: 100%;
            }
        }
    }
}

// onclick map css
.onclick-map-wrap {
    margin-bottom: 30px;
}

.onclick-map {
    display: none;

    iframe,
    .map {
        width: 100%;
        height: 300px;
        margin-bottom: 30px;
    }
}

/**=====================
     map css end
==========================**/