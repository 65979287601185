/**=====================
     labels css start
==========================**/
.label {
    border-radius: 5px;
    padding: 4px 15px 3px;
    font-family: $font_1, $font_3;
    font-weight: 500;
    font-size: 14px;
    color: $white;
    display: inline-block;
    text-transform: capitalize;

    &.label-gradient {
        &.color-1 {
            background-image: var(--theme-gradient3);
        }

        &.color-6 {
            background-image: var(--theme-gradient12);
        }
    }

    &.label-solid {
        background: var(--theme-default2);

        &.color-3 {
            background: var(--theme-default5);
        }

        &.color-6 {
            background: var(--theme-default9);
        }

        &.color-7 {
            background: var(--theme-default10);
        }
    }

    &.label-success {
        background: $success-color;
    }

    &.label-danger {
        background: $danger-color;
    }

    &.label-shadow {
        background-color: $danger-color;
        box-shadow: 0 0 9px 3px rgba($danger-color, 0.2);
    }

    &.label-gradient {
        &.color-4 {
            background-image: var(--theme-gradient9);
        }
    }

    &.label-light {
        position: relative;

        &::after {
            @extend %common-lightafter;
            border-radius: 8px;
        }

        &.color-2 {
            color: var(--theme-default3);

            &::after {
                background-color: var(--theme-default3);
                opacity: 0.1;
            }
        }

        &.color-3 {
            color: var(--theme-default5);

            &::after {
                background-color: var(--theme-default5);
                opacity: 0.1;
            }
        }

        &.color-4 {
            color: var(--theme-default7);

            &::after {
                background-color: var(--theme-default7);
                opacity: 0.08;
            }
        }

        &.color-7 {
            color: var(--theme-default10);

            &::after {
                background-color: var(--theme-default10);
                opacity: 0.08;
            }
        }

        &.label-pill {
            &::after {
                border-radius: 9px;
            }
        }

        &.label-flat {
            &::after {
                border-radius: 0;
            }
        }
    }

    &.label-white {
        background-color: $white;

        &.color-1 {
            color: var(--theme-default2);
        }

        .gradient-1 {
            @extend %gradient-text1;

            &.color-6 {
                background: var(--theme-gradient11);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &.color-3 {
            color: var(--theme-default5);
        }

        &.color-6 {
            color: var(--theme-default9);
        }

        &.color-7 {
            color: var(--theme-default10);
        }
    }

    &.label-dark {
        background: $theme-font-color;
    }

    &.label-pill {
        border-radius: 9px;
    }

    &.label-flat {
        border-radius: 0;
    }

    &.label-lg {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        padding: 10px 30px 9px;

        &.label-pill {
            border-radius: 23px;
        }
    }
}

.theme-label {
    .card-header {
        h5 {
            margin-top: -3px;
        }
    }
    .card-body {
        margin-bottom: -15px;

        .label {
            margin-bottom: 15px;
        }
    }
}

.label-heart {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    @extend %flex-center;
    position: relative;

    i {
        font-size: 14px;
        margin: 0 0px -2px 0;
    }

    &::after {
        border-radius: 100%;
        @extend %common-lightafter;
    }

    &.color-7 {
        color: var(--theme-default10);

        &::after {
            opacity: 0.1;
            background-color: var(--theme-default10);
        }
    }

    &.color-6 {
        color: var(--theme-default9);

        &::after {
            opacity: 0.1;
            background-color: var(--theme-default9);
        }
    }

    &.color-4 {
        color: var(--theme-default7);

        &::after {
            opacity: 0.1;
            background-color: var(--theme-default7);
        }
    }

    &.color-1 {
        color: var(--theme-default2);

        &::after {
            opacity: 0.1;
            background-color: var(--theme-default);
        }
    }

    &.color-2 {
        color: var(--theme-default3);

        &::after {
            opacity: 0.12;
            background-color: var(--theme-default3);
        }
    }

    &.color-3 {
        color: var(--theme-default5);

        &::after {
            opacity: 0.12;
            background-color: var(--theme-default5);
        }
    }
}

/**=====================
     labels css end
==========================**/
