/**=====================
     buttons css start
==========================**/
.btn {
    font-weight: 600;
    text-transform: capitalize;
    padding: 8px 30px 7px;
    border-radius: 8px;
    font-size: 14px;
    position: relative;

    &.btn-sm {
        padding: 8px 20px;
        font-size: 13px;
    }

    &.btn-solid {
        background: var(--theme-default5);
        border: 2px solid transparent;
        color: $white;

        &::after {
            @extend %common-lightafter;
            transition: 0.5s;
            box-shadow: 0px 4px 20.5px 3px var(--theme-default5);
            opacity: 0.3;
        }

        &:hover {
            background: $white;
            border: 2px solid var(--theme-default5);
            color: var(--theme-default5);

            &::after {
                box-shadow: unset;
                transition: 0.5s;
            }
        }

        &.color-7 {
            background: var(--theme-default10);

            &::after {
                box-shadow: 0px 4px 20.5px 3px var(--theme-default10);
            }

            &:hover {
                background: $white;
                border: 2px solid var(--theme-default10);
                color: var(--theme-default10);

                &::after {
                    box-shadow: unset;
                    transition: 0.5s;
                }
            }
        }
    }

    &.btn-gradient {
        color: $white;
        background-size: 200% auto;
        transition: all 0.5s ease;
        border: 2px solid transparent;

        &::after {
            @extend %common-lightafter;
            opacity: 0;
            border-radius: 4px;
        }

        &.color-1 {
            background-image: var(--theme-gradient1);

            &::after {
                opacity: 0.25;
                box-shadow: 3.346px 3.716px 12.5px var(--theme-default2);
            }

            &:hover {
                background-position: right center;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                background-image: var(--theme-gradient1);
            }
        }

        &.color-2 {
            background-image: var(--theme-gradient5);

            &::after {
                opacity: 0.35;
                box-shadow: 3.346px 3.716px 12.5px var(--theme-default3);
            }

            &:hover {
                background-position: right center;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                background-image: var(--theme-gradient5);
            }
        }

        &.color-4 {
            background-image: var(--theme-gradient8);

            &::after {
                opacity: 0.3;
                box-shadow: -5.15px 8.572px 25px var(--theme-default6);
            }

            &:hover {
                background-position: right center;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                background-image: var(--theme-gradient8);
            }
        }

        &.color-6 {
            background-image: var(--theme-gradient10);

            &::after {
                opacity: 0.3;
                box-shadow: -5.15px 8.572px 25px var(--theme-default8);
            }

            &:hover {
                background-position: right center;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                background-image: var(--theme-gradient10);
            }
        }

        &.btn-pill {
            &::after {
                border-radius: 30px;
            }
        }
    }

    &.btn-light-bg {
        background-color: transparent;
        border: 2px solid transparent;
        transition: all 0.5s ease;
        height: 43px;

        &::after {
            @extend %common-lightafter;
            background-color: var(--theme-default3);
            opacity: 0.1;
            height: 43px;
        }

        span {
            background: var(--theme-gradient6);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            vertical-align: -2px;
        }

        &:hover {
            border: 2px solid var(--theme-default3);
            background: $white;
            transition: all 0.5s ease;

            &::after {
                background: $white;
            }
        }

        &.color-2 {
            border: none;
            &:hover {
                border: none;
            }
        }

        &.color-3 {
            color: var(--theme-default5);

            span {
                color: var(--theme-default5);
                background: unset;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: unset;
            }

            &::after {
                background-color: var(--theme-default5);
            }

            &:hover {
                border: 2px solid transparent;
                span {
                    color: $white;
                }
                &::after {
                    opacity: 1;
                    z-index: -1;
                }

                &::before {
                    @extend %common-lightafter;
                    box-shadow: 0px 10px 12.5px var(--theme-default5);
                    transition: 0.5s;
                    opacity: 0.35;
                }
            }
        }

        &.color-7 {
            color: var(--theme-default10);
            border: none;

            span {
                color: var(--theme-default10);
                background: unset;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: unset;
            }

            &::after {
                background-color: var(--theme-default10);
            }

            &:hover {

                span {
                    color: $white;
                }

                &::after {
                    opacity: 1;
                    z-index: -1;
                }

                &::before {
                    @extend %common-lightafter;
                    box-shadow: 0px 10px 12.5px var(--theme-default10);
                    transition: 0.5s;
                    opacity: 0.35;
                }
            }
        }
    }

    &.btn-white {
        background-color: $white;
        border: 2px solid transparent;
        transition: all 0.5s ease;

        span {
            @extend %gradient-text1;
            transition: all 0.5s ease;
        }

        &:hover {
            box-shadow: 0 4px 6px 2px rgba($black, 0.1);
            transition: all 0.5s ease;
        }

        &.color-6 {
            border: 2px solid transparent;
            span {
                background: var(--theme-gradient11);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    &.btn-dashed {
        border: 1px dashed $gray-30;
        color: $theme-font-color;
        background: transparent;
        transition: all 0.5s ease;
        position: relative;
        padding: 9px 30px 8px;

        &:hover {
            background: $gray-30;
            color: $white;
            transition: all 0.5s ease;
            background-color: transparent;

            &::after {
                opacity: 0.1;
                @extend %common-lightafter;
            }
        }

        &.color-1 {
            &:hover {
                border-color: var(--theme-default2);
                color: var(--theme-default2);

                &::after {
                    background: var(--theme-default2);
                }
            }
        }

        &.color-2 {
            &:hover {
                border-color: var(--theme-default4);
                color: var(--theme-default4);

                &::after {
                    background: var(--theme-default4);
                }
            }
        }

        &.color-4 {
            &::after {
                width: 100%;
                height: 100%;
                position: absolute;
                content: '';
                background: transparent;
                top: 0;
                left: 0;
            }

            &:hover {
                border-color: var(--theme-default7);
                color: var(--theme-default7);

                &::after {
                    background: var(--theme-default7);
                }
            }
        }

        &.color-6 {
            &:hover {
                border-color: var(--theme-default9);
                color: var(--theme-default9);

                &::after {
                    background: var(--theme-default9);
                }
            }
        }
    }

    &.btn-pill {
        border-radius: 30px;

        &:hover {
            &::after {
                border-radius: 30px;
            }
        }

        &::after {
            border-radius: 30px;
        }
    }

    &.btn-flat {
        border-radius: 0;
    }

    &.btn-lg {
        padding: 12px 30px 10px;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
}

.btns {
    margin-bottom: -10px;

    .btn {
        margin-bottom: 10px;
    }
}

.theme-buttons {
    margin-bottom: -15px;

    >div {
        margin-left: -15px;

        .btn {
            margin-bottom: 15px;

            +.btn {
                margin-left: 15px;
            }
        }
    }
}
.btn-outline-light {
    color: $theme-sub-font-color;
}

.btn-showcase {
    .theme-card {
        .card-header {
            margin-top: -3px;
        }
    }
    .card-body {
        .btn {
            margin-bottom: 10px;
        }

        margin-bottom: -10px;
    }
}

.custom-dropdown {
    position: relative;
    .custom-dropdown-menu {
        width: 350px;
        padding: 20px;
        right: 0;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        background: $white;
        border: 1px solid #eee;
        border-radius: 4px;
        display: none;
        position: absolute;
        top: 30px;
        right: 0;
        form {
            .checkbox_animated {
                ~ span {
                    vertical-align: -1px;
                }
            }
        }
        &.show {
            animation: fadeIn 0.5s;
            display: block;
        }
    }
}
.modules-buttons{
    .btn, .label{
        margin-right: 5px;
        &:last-child{
            margin-right: 0;
        }
    }
}

/**=====================
     buttons css end
==========================**/
