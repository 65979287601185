/**=====================
     portfolio css start
==========================**/
.portfolio-section {
    &.zoom-gallery {
        .grid-item {
            a {
                cursor: zoom-in;
            }
        }
    }
    .grid-item {
        .overlay {
            overflow: hidden;
        }
        .portfolio-image {
            transition: all 1s ease;
        }
        &:hover {
            .portfolio-image {
                transform: scale(1.2);
                transition: all 1s ease;
            }
        }
    }
}
.portfolio-section{
    .portfolio-image{
        .bg-size{
            display: block;
        }
    }
}

// porfolio grid page css
.details-right {
    box-shadow: 3.346px 3.716px 22.5px rgba(0,0,0,0.07);
    padding: 40px;
    h3 {
        margin-bottom: 15px;
    }
    p {
        line-height: 1.7;
    }
}
.project-details {
    margin-top: 40px;
    h3 {
        margin-bottom: 20px;
    }
    .detail-container {
        padding: 10px 0;
        border-bottom: 1px solid #eee;
        &:last-child{
            border: none;
            padding-bottom: 0;
        }
        h6 {
            margin-bottom: 0;
        }
        .portfolio-left {
            width: 25%;
            h6 {
                font-weight: 600;
                text-transform: capitalize;
            }
        }
        .portfolio-right {
            width: 75%;
        }
    }
}
.portfolio-details {
    .portfolio-section {
        margin-top: 50px;
    }
    .details-row {
        align-items: center;
    }
}
.portfolio-grid {
    .filter-panel {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 40px;
        .top-panel {
            justify-content: center;
        }
    }
    .property-text {
        margin-top: 15px;
        h3 {
            font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 10px;
            a {
                color: $theme-font-color;
            }
        }
        h6 {
            color: $light-color;
            font-size: 14px;
            margin-bottom: -3px;
            line-height: 1.7;
        }
    }
    &.creative-3 {
        .row {
            > div {
                &:nth-child(6) {
                    .overlay {
                        margin-right: -1px;
                    }
                }
            }
        }
    }
}
.grid-box {
    border-radius: 10px;
    box-shadow: 3.346px 3.716px 25px rgba($black, 0.07);
    overflow: hidden;
    .property-text {
        padding: 30px;
        margin-top: 0;
        h3 {
            margin-top: -3px;
            margin-bottom: 2px;
        }
    }
}
// parallax page css
.parallax-section {
    .full_banner {
        height: 93vh;
        background-attachment: fixed;
        background-color: rgba($black,0.5);
        background-blend-mode: overlay;
        display: flex !important;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 20px;
        .center-content {
            text-align: right;
            h2, h6 {
                color: rgba($white, 0.8);
            }
            h6 {
                padding: 10px 20px;
                background-color: $black;
                border-radius: 4px;
                text-transform: capitalize;
                @media (max-width: 360px) {
                    padding: 10px 15px;
                }
            }
        }
    }
}
// center slide css
.center-slides {
    .img-center {
        height: 93vh;
        .center-content {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($black, 0.5);
            width: 100%;
            height: 93vh;
            text-align: center;
            position: relative;
            z-index: 1;
            h3,
            h6 {
                color: $white;
            }
            h3 {
                font-weight: 600;
            }
            h6 {
                margin-bottom: 0;
            }
        }
    }
    .slick-slide {
        .img-center {
            .center-content {
                opacity: 0;
                transition: all 0.5s ease;
            }
        }
        &.slick-center {
            .img-center {
                .center-content {
                    opacity: 1;
                    transition: all 0.5s ease;
                    h3 {
                        animation: fadeInLeft 0.4s ease;
                    }
                    h6 {
                        animation: fadeInRight 0.4s ease;
                        padding: 10px 20px;
                        background-color: $black;
                        border-radius: 4px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}
// creative 1 css
.portfolio-creative {
    .content-sec {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        box-shadow: 3.346px 3.716px 22.5px rgba(0,0,0,0.07);
        h3 {
            font-weight: 600;
            margin-top: -3px;
            a {
                color: $theme-font-color;
            }
        }
        p {
            max-width: 80%;
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 20px;
        }
        &.center-con {
            p {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &.odd-even {
        .row {
            .content-sec {
                padding-left: 60px;
                text-align: left;
            }
            &:nth-child(even) {
                .content-sec {
                    padding-left: 0;
                    padding-right: 60px;
                    text-align: right;
                    p {
                        margin-right: 0;
                        margin-left: auto;
                    }
                }
            }
        }
    }
}
/**=====================
     portfolio css end
==========================**/
